<template>
  <pdl-tabs>
    <!-- SKU Tab -->
    <pdl-tab-pane>
      <!-- SKU GRID -->
      <div slot="label" qaid="sku-grid.sku-tab-button">
        {{ $t('text.account.savedLists.sku') }}
      </div>

      <span v-if="minQtyErrorPresent">{{ $t('distributor.B2B.skuGrid.errorMessage') }}</span>

      <table class="b2b-grid b2b-grid--collapse is-compact" qaid="saved-list-sku-grid">
        <!-- SKU Grid Headers -->
        <thead class="b2b-grid__header">
          <tr>
            <th
              v-if="column.ICON"
              class="b2b-grid__cell b2b-grid__head lg:hidden xl:hidden xxl:table-cell"
              qaid="sku-grid.icon"
            />

            <th v-if="column.SKU" class="b2b-grid__cell b2b-grid__head uppercase" qaid="sku-grid.sku">
              {{ $t('text.account.savedLists.sku') }} | {{ $t('text.account.savedLists.upc') }}
            </th>

            <!-- Variable variant headers -->
            <template v-if="column.VARIANT">
              <th
                v-for="(variant, variantIndex) in categorization"
                :key="`variant-${variantIndex}`"
                qaid="sku-grid.variant"
                class="b2b-grid__cell b2b-grid__head"
              >
                <span v-html="variant.name" />
              </th>
            </template>

            <th v-if="column.UPC_EAN" class="b2b-grid__cell b2b-grid__head uppercase lg:hidden" qaid="sku-grid.upc-ean">
              {{ $t('text.account.savedLists.upc') }}
            </th>

            <th v-if="column.ALLOC" class="b2b-grid__cell b2b-grid__head lg:text-right" qaid="sku-grid.allocated">
              <span
                v-tippy="{
                  html: '#abbreviation-text',
                  trigger: 'mouseenter',
                }"
              >
                {{ $t('backordersAllocated.B2B.alloc') }}
              </span>
            </th>

            <th v-if="column.BO" class="b2b-grid__cell b2b-grid__head lg:text-right" qaid="sku-grid.backordered">
              <span
                v-tippy="{
                  html: '#bo-abbreviation-text',
                  trigger: 'mouseenter',
                }"
              >
                {{ $t('backordersAllocated.B2B.bo') }}
              </span>
            </th>

            <!-- Variable warehouse headers (1 to 5) -->
            <template v-if="hasWarehouses(warehouseList) && column.WAREHOUSE">
              <th
                v-for="(warehouse, warehouseKey) in warehouseList"
                :key="`warehouse-${warehouseKey}`"
                class="b2b-grid__cell b2b-grid__head uppercase"
                data-type="warehouse"
                qaid="sku-grid.warehouse"
              >
                {{ warehouse }}
              </th>
            </template>

            <th
              v-if="hasWarehouses(warehouseList) && column.STOCK_WATCH"
              class="b2b-grid__cell b2b-grid__head"
              data-type="stock-watch"
              qaid="sku-grid.stock-watch"
            >
              {{ $t('text.account.savedLists.stockWatch') }}
            </th>

            <th
              v-if="displayPackageQuantity && column.PKG_QTY"
              class="b2b-grid__cell b2b-grid__head text-right"
              qaid="sku-grid.package-quantity"
            >
              {{ $t('skuGrid.B2B.pkgQty') }}
            </th>

            <th
              v-if="!isConsumerFriendlyMode && column.MSRP"
              class="b2b-grid__cell b2b-grid__head"
              qaid="sku-grid.msrp"
            >
              {{ $t('text.msrp') }}
            </th>
            <th
              v-if="!isConsumerFriendlyMode && column.RETAILER_COST"
              class="b2b-grid__cell b2b-grid__head"
              qaid="sku-grid.order-price"
            >
              {{ $t('text.retailerCost') }}
            </th>

            <th v-if="column.LIST" qaid="sku-grid.list" class="b2b-grid__cell b2b-grid__head lg:text-right">
              <pdl-icon size="18" name="list" :label="$t('text.account.savedLists')" />
            </th>

            <th v-if="column.CART" qaid="sku-grid.shopping_cart" class="b2b-grid__cell b2b-grid__head lg:text-right">
              <pdl-icon size="18" name="shopping_cart" :label="$t('cartPage')" />
            </th>

            <th v-if="column.QTY" class="b2b-grid__cell b2b-grid__head" qaid="sku-grid.qty">
              {{ $t('text.account.savedLists.qty') }}
            </th>
          </tr>
        </thead>
        <span id="abbreviation-text" class="hidden">{{ $t('text.allocated') }}</span>
        <span id="bo-abbreviation-text" class="hidden">{{ $t('text.backordered') }}</span>
        <!-- SKU Grid Body/Rows -->
        <tbody>
          <!-- For each Product/Row -->
          <template v-for="(product, productIndex) in productGroup.products">
            <bulk-product-grid-item
              :key="productIndex"
              :hide-unavailable="hideUnavailable"
              :product="product"
              :product-group="productGroup"
              :product-group-index="productGroupIndex"
              :row-index="productIndex"
              :min-qty-error="!!minQtyFailList[product.code]"
              :min-qty-error-message="minQtyFailList[product.code] ? minQtyFailList[product.code] : null"
              :is-plp="isPlp"
              :is-pdp="isPdp"
              :is-custom-product="isCustomProduct"
              :display-package-quantity="displayPackageQuantity"
              :warehouse-codes="warehouseList"
              :column="column"
              :has-link="hasLink"
              @update-stock-watch-state="(payload) => $emit('update-stock-watch-state', payload)"
              @register-qty-input="registerQuantityInput"
              @update-variant-qty="updateVariantQty"
            />
          </template>
        </tbody>
      </table>

      <!-- SKU Grid footer buttons (move to list/add to cart) -->
      <div v-if="hasSkuFooterButton" class="buttons buttons--right-for-md">
        <trek-button
          v-if="isB2BPurchasable"
          primary
          small
          icon="shopping_cart"
          :disabled="cartToggle === false || disableAddToCart"
          qaid="sku-grid.add-to-cart"
          @click="addSkusToCart"
        >
          {{ $t('text.addToCart') }}
        </trek-button>
        <trek-button
          :id="'sl-sku-grid-btn-' + productGroupIndex"
          ref="tippy_3"
          v-tippy="{
            html: '#saved-lists-sku-grid',
            interactive: true,
            reactive: true,
            trigger: 'click',
            flip: true,
            sticky: false,
            popperOptions: {
              positionFixed: true,
              modifiers: {
                keepTogether: true,
                preventOverflow: {
                  enabled: true,
                },
                hide: {
                  enabled: false,
                },
              },
            },
          }"
          secondary
          small
          icon="list"
          :disabled="disableAddToSavedList"
          type="button"
          qaid="saved-lists-button.move-to-list.sku-grid"
        >
          {{ $t('myCart.B2B.moveToList') }}
        </trek-button>
        <saved-lists-panel
          id="saved-lists-sku-grid"
          :text-max-chars="$t('text.buyingZone.B2B.max40Char')"
          :text-my-lists="$t('text.account.savedLists.myLists')"
          :text-other-lists="$t('text.account.savedLists.otherLists')"
          :text-undo="$t('text.undo')"
          qaid-panel="saved-lists-panel.sku-gird"
          qaid-title="saved-lists-panel.title.sku-grid"
          qaid-list="saved-lists-panel.list.sku-grid"
          tippy-id="7"
          :btn-id="'sl-sku-grid-btn-' + productGroupIndex"
          @submit="moveToListCallback"
          @undo="moveToListCallback"
          @failed-skus="updateFailedSkus"
        />
      </div>
    </pdl-tab-pane>

    <!-- Apple Labels Tab -->
    <pdl-tab-pane v-if="displayAppleLabels">
      <div slot="label" qaid="sku-grid.sku-apple-labels-button">
        {{ $t('appleLabel.title') }}
      </div>
      <pdl-callout v-if="!cartToggle" kind="error">
        <template #content>
          {{ $t('text.systemDown.cannotSubmit') }}
        </template>
      </pdl-callout>
      <apple-label-grid
        :items="appleLabelData"
        :base-product="baseProduct"
        :text-save-button="$t('text.saveChanges')"
        :text-clear-button="$t('search.nav.clear')"
        :text-cannot-save="$t('text.systemDown.cannotSubmit')"
      />
    </pdl-tab-pane>
  </pdl-tabs>
</template>
<script>
import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import find from 'lodash/find';
import get from 'lodash/get';
import TrekButton from '@/components/TrekButton';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import BulkProductGridItem from '@/components/containers/bulk-product/BulkProductGridItem';
import SavedListsPanel from '@/components/saved-lists/SavedListsPanel';
import AppleLabelGrid from '@/components/checkout/AppleLabelGrid';
import {PdlCallout} from '@pedal/pdl-callout';
import warehouseMixin from '@/mixins/warehouse';
import {formatAppleData} from '@/utils/product-info';
import {PdlIcon} from '@pedal/pdl-icon';

const ALL_COLUMNS = Object.freeze({
  ICON: true,
  SKU: true,
  VARIANT: true,
  UPC_EAN: true,
  ALLOC: true,
  BO: true,
  WAREHOUSE: true,
  STOCK_WATCH: true,
  PKG_QTY: true,
  MSRP: true,
  RETAILER_COST: true,
  LIST: true,
  CART: true,
  QTY: true,
});

const BUNDLE_COLUMNS = Object.freeze({
  SKU: true,
  VARIANT: true,
  UPC_EAN: true,
  WAREHOUSE: true,
  MSRP: true,
  RETAILER_COST: true,
  QTY: true,
});

export default {
  name: 'BulkProductGridTabs',

  components: {
    PdlTabs,
    PdlTabPane,
    BulkProductGridItem,
    SavedListsPanel,
    AppleLabelGrid,
    TrekButton,
    PdlCallout,
    PdlIcon,
  },

  mixins: [warehouseMixin],

  props: {
    hideUnavailable: Boolean,
    productGroup: {
      type: Object,
      default: () => null,
    },
    productGroupIndex: {
      type: Number,
      default: 0,
    },
    baseProduct: {
      type: String,
      default: null,
    },
    isPlp: {
      type: Boolean,
      default: false,
    },
    isPdp: {
      type: Boolean,
      default: false,
    },
    hasSkuFooterButton: {
      type: Boolean,
      default: true,
    },
    hasLink: {
      type: Boolean,
      default: true,
    },
    productType: {
      type: String,
      validator: function (value) {
        // 'am' stands for 'after market' and represents parts/accessories
        return ['bike', 'am'].indexOf(value) !== -1;
      },
      default: null,
    },
    isCustomProduct: {
      type: Boolean,
      default: false,
    },
    columnConfig: {
      type: String,
      validator: function (value) {
        // 'ALL' for all columns, 'BUNDLE' for column to be shown in bundle dialog
        return ['ALL', 'BUNDLE'].indexOf(value) !== -1;
      },
      default: 'ALL',
    },
    productGroups: {
      type: Array,
      default: () => [],
    },
    products: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      itemQuantityInputs: [],
      minQtyFailList: {},
      allBundleProducts: [],
    };
  },

  computed: {
    minQtyErrorPresent() {
      return this.minQtyFailList && Object.keys(this.minQtyFailList).length;
    },

    column() {
      switch (this.columnConfig) {
        case 'BUNDLE':
          return BUNDLE_COLUMNS;
        case 'ALL':
        default:
          return ALL_COLUMNS;
      }
    },

    // Returns whether the Apple Labels tab/grid should be displayed.
    // Assumes that either all products have them or they all don't
    displayAppleLabels() {
      if (this.productType === 'bike') {
        return this.appleLabelAccess.bikes;
      }
      if (this.productType === 'am' && !(this.isSpectrumEnabled || this.isCustomProduct)) {
        return this.appleLabelAccess.parts;
      }

      return false;
    },

    appleLabelData() {
      if (!this.displayAppleLabels) return [];
      if (!this.productGroup.products) return [];

      return formatAppleData(this.productGroup.products, {uidProperty: 'code', isPdp: this.isPdp});
    },

    categorization() {
      return get(this.productGroup, 'products[0].categorization');
    },

    // Returns a list of Warehouse Header/Label names based on the products returned
    // Assumes that all products have the same warehouses in the same order
    warehouseList() {
      const products = get(this.productGroup, 'products');
      return this.getUniqueWarehouseCodesFromAllProductVariants(products);
    },

    displayPackageQuantity() {
      const products = this.productGroup.products;
      const isDisplayable = products.some((product) => parseInt(product.packageQty) >= 2);
      return isDisplayable;
    },

    // Enable add to cart button if at least one of the rows has purchasable products
    disableAddToCart() {
      let purchasableProduct = find(this.productGroup.products, (product) => this.isPurchasable(product));
      return isEmpty(purchasableProduct);
    },

    // disable Add to Saved List button if it's a distrubutor account, and not any variant of the base product is "isPurchasableB2B"
    disableAddToSavedList() {
      let isPurchasableB2BVariantExists = find(this.productGroup.products, (product) => product.isPurchasableB2B);

      return this.isDistributor && !isPurchasableB2BVariantExists;
    },

    ...mapState('backend', [
      'isB2BPurchasable',
      'isConsumerFriendlyMode',
      'cartToggle',
      'maxCartItems',
      'appleLabelAccess',
      'isDistributor',
      'isSpectrumEnabled',
    ]),
    ...mapState('pdp', ['isBike']),
    ...mapGetters('miniCart', ['totalItems']),
    ...mapState('productBundle', ['skusWithBundle', 'bundleMainProducts', 'bundleIndex', 'plpBundleProducts']),
    ...mapState('skuGrid', ['skuEntriesWithBundle']),
  },

  methods: {
    isPurchasable(product) {
      const priceData = product.prices || product;
      return (
        priceData?.retailerPrice?.wasPrice.low.value &&
        !isEmpty(product.code) &&
        product.isPurchasableB2B &&
        !this.isSpectrumEnabled &&
        !this.isCustomProduct
      );
    },

    registerQuantityInput(input) {
      this.itemQuantityInputs.push(input);
    },

    updateFailedSkus(skus, messages) {
      this.minQtyFailList = {};
      for (let i = 0; i < skus.length; i++) {
        Vue.set(this.minQtyFailList, skus[i], messages[i]);
      }
    },

    addSkusToCart() {
      // get information related to all bundle products in sku-grid
      this.getSkuBundleProducts();
      this.$emit('add-to-cart');
      this.addBaseSkuToCart();
    },

    updateVariantQty(payload) {
      this.updateVariantQuantity({
        sku: payload.sku || payload.code,
        qty: payload.qty,
        baseSku: this.baseProduct,
        callback: this.addToCartCallback,
        input: payload.input,
      });

      if (payload.qty > 0 && this.skusWithBundle.includes(payload.sku)) {
        this.setSkuEntriesWithBundle({qty: payload.qty, sku: payload.sku});
      }
    },

    addToCartCallback(response) {
      // min qty fail
      this.updateFailedSkus(
        (get(response, 'data.data.failedSKUs') || []).map((item) => item.sku),
        (get(response, 'data.data.failedSKUs') || []).map((item) => item.message.basePropertyValue)
      );

      // Reset Inputs to their empty state

      //Update cart column values in grid
      this.$emit('update-cart-or-list-column', {response: response.data.data.cartEntries, colName: 'skuQtyInCart'});
    },

    moveToListCallback(responseEntries) {
      this.$emit('update-cart-or-list-column', {response: responseEntries, colName: 'skuQtyInSavedList'});
    },

    getSkuBundleProducts() {
      this.clearMainBundleProducts();
      if (this.isPlp) {
        this.plpBundleProducts.forEach((plpBundle) => {
          plpBundle.productGroups.forEach((group) => {
            this.allBundleProducts.push(group);
          });
        });
      } else if (this.isPdp) {
        this.allBundleProducts = this.productGroups;
      }

      this.skuEntriesWithBundle.forEach((variant) => {
        this.getBundleMainProductInfo(variant);
        // There could be multiple products that has bundle template id associated when we click add-to-cart from sku grid. Increment the bundle index by 1 for storing next main bundle product information from skuEntriesWithBundle Array
        this.setBundleIndex(this.bundleIndex + 1);
      });
    },

    getBundleMainProductInfo(skuVariant) {
      this.allBundleProducts.forEach((productGroup) => {
        productGroup.products.forEach((product) => {
          if (product.code == skuVariant.sku) {
            this.bundleVariants = [];
            this.bundleMainProducts[this.bundleIndex] = {};
            this.setBundleMainProductName(productGroup.name);
            this.setBundleMainProductAssetId(productGroup.group.image);
            product.categorization.forEach((categorization) => {
              this.bundleVariants.push({key: categorization.name, value: categorization.value});
            });
            this.setBundleMainProductMetaData(this.bundleVariants);
            this.setIsVariantCode(skuVariant.sku);
            this.setBundleMainProductQuantity(skuVariant.qty);
          }
        });
      });
    },

    ...mapActions('miniCart', ['fetchMiniCart']),
    ...mapActions('skuGrid', ['addBaseSkuToCart', 'updateCartOrListColumn']),
    ...mapMutations('advancedOrdering', ['setNonAOItems', 'setDisplayAddErrorModal']),
    ...mapMutations('savedLists', ['clearEntries']),
    ...mapMutations('skuGrid', ['updateVariantQuantity', 'setSkuEntriesWithBundle']),
    ...mapMutations('productBundle', [
      'setBundleChildProducts',
      'setBundleMainProductMetaData',
      'setBundleMainProductName',
      'setBundleMainProductUrl',
      'setBundleIndex',
      'setBundleMainProductAssetId',
      'setIsVariantCode',
      'setBundleMainProductQuantity',
    ]),
    ...mapActions('productBundle', ['clearMainBundleProducts']),
  },
};
</script>

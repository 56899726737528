<template>
  <div
    v-if="value && value.length"
    ref="listItems"
    class="locator-store-list flex-auto m-0"
    :class="{'opacity-75': isLoading, 'b2b-scroll-list': isScrollList}"
  >
    <div
      v-for="(store, index) in value"
      :key="`store-${store.id}`"
      :qaid="`store-locator-list__store${index}`"
      @mouseenter="hoverIndex = index"
      @mouseleave="hoverIndex = -1"
    >
      <locator-store-card :pin-hovered="pinHoverId == index" :value="store" @store-clicked="storeClicked" />
    </div>
  </div>

  <div v-else class="flex flex-auto align-center items-center">
    <div v-if="displayEmptyMessage && !isLoading" class="w-1/2" qaid="store-locator-list__empty">
      <div class="text-center my-4" qaid="store-locator__store-list__no-records">
        <img v-if="hasImgForNoRecords" :src="noRecordsImageUrl" class="mb-2" alt="" />
        <p>{{ $t('storeFinder.v2.noRestults') }}</p>
      </div>
    </div>
    <pdl-loading v-else-if="isLoading" :is-loading="isLoading" />
  </div>
</template>

<script>
import LocatorStoreCard from '@/components/locator/components/LocatorStoreCard';
import {scrollToElement} from '@/utils/scroll-to';
import {PdlLoading} from '@pedal/pdl-loading';
import {mapState} from 'vuex';

export default {
  name: 'LocatorStoreList',
  components: {LocatorStoreCard, PdlLoading},
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    displayEmptyMessage: {
      type: Boolean,
      default: true,
    },
    pinHoverId: {
      type: Number,
      default: -1,
    },
    page: {
      type: Number,
      default: 1,
    },
    isScrollList: {
      type: Boolean,
      default: true,
    },
    hasImgForNoRecords: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      hoverIndex: -1,
    };
  },
  computed: {
    noRecordsImageUrl() {
      return `${ACC.config.azureCdnImagesUrl}/svg/empty_state_clouds.svg`;
    },
    ...mapState('global', ['headerHeight']),
  },

  watch: {
    hoverIndex(index) {
      this.$emit('card-hover', index);
    },
    value() {
      if (this.$refs.listItems) {
        const offset = this.headerHeight + 80;
        scrollToElement(this.$refs.listItems, offset);
      }
    },
  },

  methods: {
    storeClicked(storeId, distance) {
      this.$emit('store-clicked', storeId, distance);
    },
  },
};
</script>

// Pulling in the store ahead of time to prevent a circular dependency between auth-tokens and stores
// Importing the store ahead of time puts the store into the webpack cache so that it can be used in auth-tokens and any where else that needs it
// eslint-disable-next-line no-unused-vars
import store from '@/store';
import explodingAccordion from './modules/explodingAccordion';
import menuSelector from './modules/MenuSelector';
import heroCarousel from './modules/HeroCarousel';
import searchReveal from './modules/SearchReveal';
import toggler from './modules/Toggler';
import slider from './modules/slider';
import login from './pages/login';
import productDetailPage from './pages/productDetail';
import prodCarousel from './modules/ProductCarousel';
import collections from './modules/Collections';
import facets from './modules/facets';
import deliveryDetails from './pages/deliveryDetails';
import orderConfirmation from './pages/orderConfirmation';
import player from './modules/Players';
import selectBox from './modules/SelectBox';
import loadingIndicator from './modules/loadingIndicator';
import loginAccordion from './modules/LoginAccordionToggler';
import popup from './modules/Popup';
import requestReturnLabel from './pages/requestReturnLabel';
import emailSignup from './modules/EmailSignup';
import accountProfile from './modules/accountProfile';
import storeNewsletterSignup from './modules/storeNewsletterSignup';
import videoBackground from './modules/VideoBackground';
import servicePackage from './modules/servicePackage';
import storeDetails from './modules/storeDetails';
import storeResults from './modules/storeresults';
import searchBoxComponent from './modules/search-box-component';
import storeSearch from './modules/store-search';
import previewJs from './modules/previewjs';
import tagManagerJs from './modules/tagmanagerjs';
import geoPrompt from './modules/geoPrompt';
import newsletterPage from './modules/acc.newsletter';
import searchResultHandler from './modules/searchResultHandler';
import 'jquery-colorbox';
import {updateASMHeader} from './utils/asmAdjustments';
import vueConfig from './vueConfig';

//Misc
import editorialHighlightComponent from '@/modules/editorialHighlightComponent';

// Import styling
import '@sassRoot/screen.scss';
import '@sassRoot/b2b.scss';

// Initialize Vue
vueConfig();

window.addEventListener('init-vue-dynamic-content', () => {
  const domElements = document.querySelectorAll('[data-vue-dynamic-content]');
  if (!domElements || !domElements.length) {
    return;
  }

  domElements.forEach((el) => {
    vueConfig(el);
  });
});

window.loadNonCriticalCSS = (nonCriticalCSSPath) => {
  // Create CSS link tag to be inserted in head
  let nonCriticalCSSLink = document.createElement('link');
  nonCriticalCSSLink.rel = 'stylesheet';
  nonCriticalCSSLink.href = nonCriticalCSSPath;
  nonCriticalCSSLink.type = 'text/css';

  nonCriticalCSSLink.onload = () => {
    // Remove critical CSS after link has loaded
    let criticalCSSElement = document.getElementById('criticalCSS');
    if (criticalCSSElement) {
      criticalCSSElement.remove();
    }

    // Used by automation to determine when to start testing after page has stabilized
    window.isCSSLoaded = true;
  };

  // Find the critical css (located at the head) and insert the link tag right next to it
  let criticalCSSElement = document.getElementById('criticalCSS');
  criticalCSSElement.insertAdjacentElement('afterend', nonCriticalCSSLink);

  // Ensures the owl carousel displays correctly after noncritical css loads.
  if (window.sliderRefs && window.sliderRefs.length) {
    window.sliderRefs.forEach(function (slider) {
      $(slider).trigger('to.owl.carousel', 0);
    });
  }
};

$(document).ready(() => {
  $(document).foundation();
  $(window).on('load', () => {
    $(window).trigger('resize');

    //Check if editorialHighLightComponet is present in page
    if ($('.js-editorial-highlight-component').length) {
      editorialHighlightComponent();
    }
  });

  const element = $(document);

  // adding a global loading indicator
  window.loadingIndicator = loadingIndicator();

  const sliders = element.find('[data-owl-slider]');
  window.sliderRefs = [];
  sliders.each((i, s) => {
    window.sliderRefs.push(slider($(s)));
  });

  menuSelector(element);

  const carousel = $('[data-hero-carousel]');
  if (carousel.length) {
    heroCarousel(carousel);
  }

  searchReveal(element);

  const togglers = element.find('[data-toggler]');
  togglers.each((i, t) => {
    toggler($(t));
  });

  const explodingAccordions = element.find('[data-accordion-explode]');
  explodingAccordions.each((i, t) => {
    explodingAccordion($(t));
  });

  selectBox();
  player();

  $('[data-popup-reveal]').each((i, p) => {
    popup($(p));
  });

  $('#goToTop').click(() => {
    $('html, body').animate(
      {
        scrollTop: $($('#page')).offset().top,
      },
      1000
    );
  });

  if ($('.page-login').length || $('.template-pages-MultiStepCheckoutSummaryPageTemplate').length) {
    login();
  }

  if ($('.page-login').length || $('.page-checkout-login').length) {
    const loginAccordions = element.find('[data-login-accordion-toggler]');
    loginAccordions.each((i, t) => {
      loginAccordion($(t));
    });
  }

  if ($('.page-productDetails').length || $('.page-nonBikeProductDetails').length) {
    productDetailPage(element);
  }

  const productCarousel = $('[data-product-carousel]');
  if (productCarousel.length) {
    prodCarousel(productCarousel);
  }

  const collectionsHighlights = $('[data-collection-highlights]');
  if (collectionsHighlights.length) {
    collections(element);
  }

  const productList = $('[data-product-list]');
  if (productList.length) {
    facets(productList);
    searchResultHandler(productList);
  }

  const articleList = $('[data-article-list]');
  if ($('.page-articlesB2B').length) {
    facets(articleList);
    searchResultHandler(articleList);
  }

  if ($('.page-multiStepCheckoutSummaryPage').length) {
    deliveryDetails();
  }

  if ($('.page-orderConfirmation').length) {
    orderConfirmation();
  }

  if ($('.page-requestReturnLabel').length) {
    requestReturnLabel();
  }

  $('[data-loading-mask]').addClass('hide');
  $('.loading-masked').fadeTo(1, 1);

  emailSignup(element);
  storeNewsletterSignup(element);
  accountProfile(element);

  if ($('[data-bg-video]').length) {
    videoBackground($('[data-bg-video]'));
  }

  if ($('.page-dotsDetailsPage').length || $('.page-storefinderDetailsPage').length) {
    storeDetails(element);
  }

  if ($('#currentLocale').val() !== 'zh_CN') {
    searchBoxComponent(element);
  }

  if ($('#storesearch_js_id').length) {
    storeSearch(element);
  }

  if ($('#preview_id_js').length) {
    previewJs(element);
  }

  if ($('#isTagManagercode_js').length) {
    tagManagerJs(element);
  }

  if ($('#storeresults_js_id').length) {
    storeResults(element);
  }

  //smooth scroll
  $('a[href*="#"]:not([href="#"]).smooth-scroll').click(function () {
    if (
      location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
      location.hostname === this.hostname
    ) {
      let target = $(this.hash);
      target = target.length ? target : $(`[name=${this.hash.slice(1)}]`);
      if (target.length) {
        $('html, body').animate(
          {
            scrollTop: target.offset().top - 30 - 60, // reserve 30px for spacing and 60 for nav
          },
          1000
        );
        return false;
      }
    }
  });

  if ($('.ServicePackage__tile').length) {
    servicePackage();
  }

  window.Tippy('.tippy');

  if ($('.geo-prompt-modal').length) {
    geoPrompt(element);
  }

  if ($('#newsletter_submit').length) {
    newsletterPage(element);
  }

  // Footer hide shop links in kiosk mode
  if ($('#isKioskMode').length) {
    $('#footerRetailerLink').parent().hide();
    $('#retailersFooterLinkElectra').parent().hide();
  }

  updateASMHeader();
});

import { render, staticRenderFns } from "./BundlingDialog.vue?vue&type=template&id=063257a2&"
import script from "./BundlingDialog.vue?vue&type=script&lang=js&"
export * from "./BundlingDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div>
    <div class="form-group">
      <label class="form-label">{{ variants.label }}</label>
      <div class="select" :qaid="`tech__variant-${variantIndex}-dropdown`">
        <select :id="`tech-info-${variants.id}`" v-model="selectedValue" class="form-control" required>
          <option v-for="(option, index) in variants.options" :key="index" :value="option.value">
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>

    <tech-info-variant-legacy
      v-if="selectedVariants"
      :key="`${Math.random()}-${selectedVariants.id}`"
      :variant-index="childVariantIndex"
      :variants="selectedVariants"
      @sku-selected="$emit('sku-selected', $event)"
    />
  </div>
</template>

<script>
import find from 'lodash/find';
import get from 'lodash/get';
const TechInfoVariantLegacy = () => import('@/components/containers/tech-info/TechInfoVariantLegacy');

export default {
  name: 'TechInfoVariantLegacy',
  components: {TechInfoVariantLegacy},

  props: {
    variantIndex: {
      type: Number,
      default: 0,
    },
    variants: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      selectedValue: null,
    };
  },

  computed: {
    selectedVariants() {
      if (!this.selectedValue && this.variants.options) {
        this.updateSelectedValue();
      }

      const selectedOption = find(this.variants.options, (option) => option.value === this.selectedValue);

      return selectedOption ? selectedOption.variants : null;
    },

    childVariantIndex() {
      return this.variantIndex + 1;
    },
  },

  watch: {
    selectedValue() {
      if (this.variants.isLeaf) {
        this.$emit('sku-selected', this.selectedValue);
      }
    },
  },

  methods: {
    updateSelectedValue() {
      const preSelectedOption = find(this.variants.options, (option) => option.selected);
      this.selectedValue = preSelectedOption ? preSelectedOption.value : get(this.variants, 'options[0].value', null);
    },
  },
};
</script>

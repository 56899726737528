<template>
  <section class="mb-3">
    <pdl-section-header size="sm" class="mb-3">
      <template slot="content">
        <pdl-heading :level="4" qaid="warranty-claim-line-attachments">{{ $t('text.attachments') }}</pdl-heading>
      </template>
    </pdl-section-header>
    <div class="grid-x grid-margin-x mb-3">
      <div
        v-for="(n, index) in totalProofImages"
        :key="`proofImage${index}-${getProofImage(index).name}`"
        class="cell large-4"
        :class="{'has-error': !!getErrorMessage('proofPurchaseValidator') && getProofImage(index).isRequired}"
      >
        <div class="text-small flex" :qaid="`warranty-claim-line-attachments-proof-label-${index}`">
          <span>{{ setProofImageLabel(index) }}</span>
          <span v-if="requireProofImage && getProofImage(index).isRequired" class="text-red ml-25">
            *
            <span class="show-for-sr">{{ $t('form.field.required') }}</span>
          </span>
        </div>
        <pdl-file-upload
          :qaid="`warranty-claim-line-attachments-proof-${index}`"
          :click-to-upload-msg="$t('text.uploadImage')"
          :help="null"
          :in-file-name="getProofImage(index).name"
          :in-preset-image-file-url="getProofImage(index).inPresetImageFileUrl"
          :input-id="`${claimIndex}_warrantyPurchaseProofImage`"
          accept="image/png, image/gif, image/jpeg, application/pdf"
          extensions="gif, jpg, jpeg, png, pdf"
          :upload-on-input="true"
          :upload-button="false"
          :has-error="!!getErrorMessage('proofPurchaseValidator') && getProofImage(index).isRequired"
          :file-too-large-msg="$t('myCompany.imageUpload.fileTooLarge')"
          :wrong-file-type-msg="$t('orderUpload.B2B.fileTypeError')"
          :is-filename-visible="getProofImage(index).showFileName"
          @file-uploaded="setImage({property: 'warrantyPurchaseProofImages', index: index}, $event)"
          @clear-file="clearImage('warrantyPurchaseProofImages', index)"
        />
        <div
          v-if="getProofImage(index).isRequired"
          v-show="!!getErrorMessage('proofPurchaseValidator')"
          class="form-feedback is-invalid"
          v-html="getErrorMessage('proofPurchaseValidator')"
        />
      </div>
      <div class="cell large-4" :class="{'has-error': !!getErrorMessage('damageImageValidator')}">
        <div class="text-small flex" qaid="warranty-claim-line-attachments-damage-label">
          <span>{{ $t('warrantyClaims.B2B.damage') }}</span>
          <span v-if="requireDamageImage" class="text-red ml-25">
            *
            <span class="show-for-sr">{{ $t('form.field.required') }}</span>
          </span>
        </div>
        <pdl-file-upload
          qaid="warranty-claim-line-attachments-damage"
          :click-to-upload-msg="$t('text.uploadImage')"
          :has-error="showDamageImageWarning || !!getErrorMessage('damageImageValidator')"
          :help="null"
          :in-file-name="hasDamageImage ? claimLine.warrantyDamageImage.name : null"
          :in-preset-image-file-url="hasDamageImage ? claimLine.warrantyDamageImage.url : null"
          :input-id="`${claimIndex}_warrantyDamageImage`"
          :upload-on-input="true"
          :upload-button="false"
          :file-too-large-msg="$t('myCompany.imageUpload.fileTooLarge')"
          :wrong-file-type-msg="$t('orderUpload.B2B.fileTypeError')"
          :is-filename-visible="showFileName(claimLine.warrantyDamageImage)"
          @file-uploaded="setImage({property: 'warrantyDamageImage'}, $event)"
          @clear-file="clearImage('warrantyDamageImage')"
        />
        <div
          v-show="!!getErrorMessage('damageImageValidator')"
          class="form-feedback is-invalid"
          v-html="getErrorMessage('damageImageValidator')"
        />
      </div>
    </div>
    <div class="grid-x grid-margin-x mb-5">
      <div
        v-for="(n, index) in totalAttachments"
        :key="`${index}-${getAttachmentByIndex(index).name}`"
        class="cell large-4"
      >
        <div class="text-small" :qaid="`warranty-claim-line-attachments-image-${index}-label`">
          {{ $t('warrantyClaims.B2B.imageNumber', [index + 1]) }}
        </div>
        <pdl-file-upload
          :click-to-upload-msg="$t('text.uploadImage')"
          :help="null"
          :has-error="showAttachmentImageWarning && index === 0"
          :in-file-name="getAttachmentByIndex(index).name"
          :in-preset-image-file-url="getAttachmentByIndex(index).inPresetImageFileUrl"
          :input-id="`${claimIndex}_warrantyOptionalAttachments_${index}`"
          :qaid="`warranty-claim-line-attachments-image-${index + 1}`"
          :upload-on-input="true"
          :upload-button="false"
          :file-too-large-msg="$t('myCompany.imageUpload.fileTooLarge')"
          :wrong-file-type-msg="$t('orderUpload.B2B.fileTypeError')"
          :is-filename-visible="getAttachmentByIndex(index).showFileName"
          @file-uploaded="setImage({property: 'warrantyOptionalAttachments', index: index}, $event)"
          @clear-file="clearImage('warrantyOptionalAttachments', index)"
        />
      </div>
    </div>
    <div v-if="allowToAddAttachments" class="mt-3">
      <trek-button
        secondary
        icon="add_circle_outline"
        qaid="add-more-photos-button"
        :disabled="exceededLimitOfAttachments"
        @click.prevent="addMoreAttachments"
      >
        <span>{{ $t('warrantyClaims.B2B.morePhotos') }}</span>
      </trek-button>
      <p v-if="exceededLimitOfAttachments">
        {{ $t('warrantyClaims.B2B.maxPhotos') }}
      </p>
    </div>
  </section>
</template>

<script>
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import get from 'lodash/get';
import {mapActions, mapMutations, mapGetters} from 'vuex';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
import {PdlFileUpload} from '@pedal/pdl-file-upload';

export default {
  components: {PdlFileUpload, PdlSectionHeader, PdlHeading},
  mixins: [TrekValidationMixin],

  props: {
    claimLine: {
      type: Object,
      required: true,
    },
    claimIndex: {
      type: Number,
      required: true,
    },
    maxAttachments: {
      type: Number,
      default: 18,
    },
    minAttachments: {
      type: Number,
      default: 3,
    },
    attachmentsToAddAtTime: {
      type: Number,
      default: 3,
    },
    allowToAddAttachments: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      totalAttachments: 0,
      totalProofImages: 2,
      proofPurchaseValidator: '',
      damageImageValidator: '',
    };
  },

  validations() {
    return {
      proofPurchaseValidator: {
        requiredIf: this.trekValidators.requiredIf('warrantyClaims.B2B.proofPurchase', this.requireProofImage),
      },
      damageImageValidator: {
        requiredIf: this.trekValidators.requiredIf('warrantyClaims.B2B.damage', this.requireDamageImage),
      },
    };
  },

  computed: {
    ...mapGetters('warrantyClaims', ['isActionRequiredStatus']),

    exceededLimitOfAttachments() {
      return this.totalAttachments >= this.maxAttachments;
    },

    hasDamageImage() {
      return get(this, 'claimLine.warrantyDamageImage.url.length', 0) > 0;
    },

    showDamageImageWarning() {
      const file = this.claimLine?.warrantyDamageImage;
      return this.claimLine?.waitingCodeList?.includes('D') && !(file.url || file.encodedFile);
    },

    showAttachmentImageWarning() {
      return this.claimLine?.waitingCodeList?.includes('I') && !this.claimLine.warrantyOptionalAttachments[0]?.name;
    },

    requireProofImage() {
      const requiredByIssueType = !!(
        this.claimLine.warrantyRequiredImagesList &&
        this.claimLine.warrantyRequiredImagesList.includes('EnumWarrantyRequiredImages001')
      );

      return this.claimLine.selectedDamagedOccurred === 'enumDamageOccurredAfterPurchase' || requiredByIssueType;
    },

    requireDamageImage() {
      const requiredByIssueType = !!(
        this.claimLine.warrantyRequiredImagesList &&
        this.claimLine.warrantyRequiredImagesList.includes('EnumWarrantyRequiredImages002')
      );

      return requiredByIssueType;
    },

    getProofImageLabel() {
      return [
        {
          id: 0,
          title: this.$t('warrantyClaims.B2B.proofPurchase'),
        },
        {
          id: 1,
          title: this.$t('warrantyClaims.B2B.eBikeDiagnostic'),
        },
      ];
    },
  },

  mounted() {
    const currentAttachmentsCount = !this.claimLine.warrantyOptionalAttachments
      ? 0
      : this.claimLine.warrantyOptionalAttachments.length;

    this.totalAttachments = Math.max(currentAttachmentsCount, this.minAttachments);

    this.proofPurchaseValidator = this.getProofImage(0).name;
    this.damageImageValidator = this.claimLine.warrantyDamageImage.name;
  },

  methods: {
    ...mapActions('warrantyClaims', ['saveImageInput']),
    ...mapMutations('warrantyClaims', ['updateClaimLine']),

    addMoreAttachments() {
      this.totalAttachments += this.attachmentsToAddAtTime;
      if (this.totalAttachments > this.maxAttachments) {
        this.totalAttachments = this.maxAttachments;
      }
    },

    inputName(propertyName) {
      return `cl${this.claimIndex}_${propertyName}`;
    },

    async clearImage(property, index) {
      const newImage = {
        encodedImage: null,
        name: null,
        url: null,
      };

      const updatedClaimLine = {...this.claimLine};

      if (property === 'warrantyOptionalAttachments' || property === 'warrantyPurchaseProofImages') {
        updatedClaimLine[property][index] = newImage;
      } else {
        updatedClaimLine[property] = newImage;
      }

      this.updateClaimLine({index: this.claimIndex, value: updatedClaimLine});

      if (property === 'warrantyPurchaseProofImages' && index === 0) {
        this.proofPurchaseValidator = newImage.name;
        this.v$.proofPurchaseValidator.$touch();
      }
      if (property === 'warrantyDamageImage') {
        this.damageImageValidator = newImage.name;
        this.v$.damageImageValidator.$touch();
      }

      await this.$nextTick();
      if (!this.isActionRequiredStatus) {
        await this.saveImageInput();
      }
      await this.cleanupCells();
    },

    cleanupCells() {
      const emptyCells = this.getEmptyCells();
      if (this.totalAttachments - this.attachmentsToAddAtTime >= emptyCells) {
        const evenRows = !(this.totalAttachments % this.attachmentsToAddAtTime);
        let removeCount = 0;

        if (evenRows) {
          removeCount = emptyCells >= this.attachmentsToAddAtTime ? this.attachmentsToAddAtTime : 0;
        } else {
          removeCount =
            emptyCells > this.attachmentsToAddAtTime ? emptyCells - this.attachmentsToAddAtTime : emptyCells;
        }

        this.totalAttachments -= removeCount;
      }
    },

    getEmptyCells() {
      if (!this.claimLine || !this.claimLine.warrantyOptionalAttachments) {
        return 0;
      }

      let notEmpty = 0;
      this.claimLine.warrantyOptionalAttachments.forEach((attachment) => {
        notEmpty += !attachment.url && !attachment.attachment ? 0 : 1;
      });

      return this.totalAttachments - notEmpty;
    },

    getAttachmentByIndex(index) {
      const file = get(this.claimLine, `warrantyOptionalAttachments[${index}]`, {});
      if (file) {
        file.showFileName = this.showFileName(file);
        file.inPresetImageFileUrl = file.url || file.encodedFile;
      }
      return file;
    },

    getProofImage(index) {
      const file = this.claimLine.warrantyPurchaseProofImages[index];
      return {
        ...file,
        isRequired: index === 0,
        showFileName: this.showFileName(file),
        inPresetImageFileUrl: file?.url || file?.encodedFile,
      };
    },

    showFileName(file) {
      return !!(file && file.name && (!this.isActionRequiredStatus || file.url));
    },

    setImage({property, index = null}, {name, encodedFile}) {
      const image = {encodedFile, name, url: null};
      const updatedClaimLine = {...this.claimLine};

      if (property === 'warrantyOptionalAttachments' || property === 'warrantyPurchaseProofImages') {
        updatedClaimLine[property][index] = image;
      } else {
        updatedClaimLine[property] = image;
      }

      this.updateClaimLine({index: this.claimIndex, value: updatedClaimLine});

      if (property === 'warrantyPurchaseProofImages' && index === 0) {
        this.proofPurchaseValidator = image.name;
        this.$nextTick(() => this.v$.proofPurchaseValidator.$touch());
      }

      if (property === 'warrantyDamageImage') {
        this.damageImageValidator = image.name;
        this.$nextTick(() => this.v$.damageImageValidator.$touch());
      }

      if (!this.isActionRequiredStatus) {
        this.saveImageInput();
      }
    },
    setProofImageLabel(index) {
      return this.getProofImageLabel.find((item) => item.id === index).title;
    },
  },
};
</script>

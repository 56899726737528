<template>
  <span
    class="b2b-badge"
    :class="[badgeClass, dismissClass, logoClass, toggleClass]"
    :qaid="qaid"
    @click.prevent="$emit('click')"
  >
    <div v-if="hasLogo" class="logo-box">
      <slot name="logo"></slot>
    </div>
    <slot v-else>{{ badgeText }}</slot>
    <i v-if="isDismissible" class="icon-close md-16" @click.prevent="$emit('close')">close</i>
    <i v-if="isToggled" class="icon-check md-16">check</i>
  </span>
</template>

<script>
export default {
  name: 'TkBadge',
  props: {
    badgeText: {
      type: String,
      required: false,
      default: '',
    },
    badgeClass: {
      type: String,
      required: false,
      default: '',
    },
    isDismissible: {
      type: Boolean,
      default: false,
    },
    hasLogo: {
      type: Boolean,
      default: false,
    },
    isToggled: {
      type: Boolean,
      default: false,
    },
    qaid: {
      type: String,
      default: '',
    },
  },
  computed: {
    dismissClass() {
      if (this.isDismissible) {
        return `b2b-badge--dismiss`;
      }
      return '';
    },
    logoClass() {
      if (this.hasLogo) {
        return `b2b-badge--logo`;
      }
      return '';
    },
    toggleClass() {
      if (this.isToggled) {
        return `toggled`;
      }
      return '';
    },
  },
};
</script>

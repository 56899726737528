<template>
  <div class="inline-flex items-center flex-grow">
    <product-label-group :product="product" class="mr-1" />
    <span v-if="year" class="text-sm text-gray-80" :qaid="`${qaid}modelYear`">{{ year }}</span>
    <trek-link
      v-if="product.spectrumUrl && product.customProductStartingPoint"
      button
      text
      small
      underline
      :href="productUrlCustomizerOpen"
    >
      <span>{{ $t('projectOne.button.customize') }}</span>
    </trek-link>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import TrekLink from '@/components/TrekLink';
import {qaidMixin} from '@/utils/qaid-helper';
import ProductLabelGroup from '@/components/ProductLabelGroup';

export default {
  name: 'ProductLabelYear',
  components: {ProductLabelGroup, TrekLink},
  mixins: [qaidMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    productUrlCustomizerOpen: {
      type: String,
      default: '',
      required: false,
    },
  },
  computed: {
    ...mapState('backend', ['b2cHideBikeModelYearToggle', 'b2b']),

    year() {
      if (!this.b2b) {
        if (!this.b2cHideBikeModelYearToggle) {
          if (this.product.marketingModelYear) {
            return this.product.marketingModelYear;
          } else if (this.product.isBike && this.product.modelYearRange) {
            return this.product.modelYearRange;
          }
        }

        if (this.product.isArchived && this.product.modelYearRange) {
          return this.product.modelYearRange;
        }
      } else {
        if (this.product.marketingModelYear) {
          return this.product.marketingModelYear;
        } else if (this.product.bike && this.product.modelYearRange) {
          return this.product.modelYearRange;
        }
      }
      return '';
    },
  },
};
</script>

import { render, staticRenderFns } from "./BulkProductGrid.vue?vue&type=template&id=4add3660&scoped=true&"
import script from "./BulkProductGrid.vue?vue&type=script&lang=js&"
export * from "./BulkProductGrid.vue?vue&type=script&lang=js&"
import style0 from "./BulkProductGrid.vue?vue&type=style&index=0&id=4add3660&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4add3660",
  null
  
)

export default component.exports
<template>
  <div class="my-4" qaid="fulfillment-option-container">
    <postal-code-drawer :is-drawer-open="isPostalCodeDrawerOpen" @close="setIsPostalCodeDrawerOpen(false)" />
    <retailer-drawer :is-drawer-open="isRetailerDrawerOpen" @close="setIsRetailerDrawerOpen(false)" />
    <div class="space-y-6">
      <placeholder-loader v-if="isLoadingOptions" :rows="2" />
      <template v-else>
        <component
          :is="option.component"
          v-for="(option, i) of fulfillmentOptions.filter((fulfillmentOption) => fulfillmentOption.component)"
          :key="`fulfillment-block-${i}`"
          :option="option"
          :set-is-drawer-open="getDrawerSetter(option.code)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import ShipToHome from '@/components/containers/pdp/b2c/fulfillment-options/home-shipping/ShipToHome.vue';
import PickUpInStore from '@/components/containers/pdp/b2c/fulfillment-options/store-pick-up/PickUpInStore.vue';
import PlaceholderLoader from '@/components/containers/pdp/b2c/fulfillment-options/PlaceholderLoader.vue';
import {FulfillmentOptionTypes} from '@/constants/fulfillment-options.js';
import RetailerDrawer from '@/components/drawer/retailer/RetailerDrawer.vue';
import PostalCodeDrawer from '@/components/drawer/address/PostalCodeDrawer.vue';
import {mapState} from 'vuex';
import {ProductFulfillmentConfig} from '@/utils/fulfillment-options-config.js';

export default {
  components: {ShipToHome, PickUpInStore, PlaceholderLoader, RetailerDrawer, PostalCodeDrawer},
  props: {
    variantCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoadingOptions: false,
      fulfillmentOptions: [],
      isRetailerDrawerOpen: false,
      isPostalCodeDrawerOpen: false,
    };
  },
  computed: {
    ...mapState('backend', ['geolocation', 'language', 'currencyIso']),
    ...mapState('user', ['selectedLocation', 'selectedRetailer']),
    ...mapState('pdp', ['isECommAvailable', 'isLocallyActive']),
    fulfillmentConfigPayload() {
      return {
        productCode: this.variantCode,
        isLocallyActive: this.isLocallyActive,
        isECommAvailable: this.isECommAvailable,
        storeId: this.selectedRetailer?.name,
        postalCode: this.selectedLocation?.postcode,
        regionCode: this.selectedLocation?.regionCode,
        lang: this.language,
        curr: this.currencyIso,
      };
    },
  },
  watch: {
    fulfillmentConfigPayload: {
      async handler(updatedPayload) {
        if (!updatedPayload) return;
        this.fulfillmentOptions = await this.fetchFulfillmentOptions(updatedPayload);
      },
      immediate: true,
    },
  },
  methods: {
    getDrawerSetter(name) {
      const config = {
        [FulfillmentOptionTypes.PICK_UP_IN_STORE]: this.setIsRetailerDrawerOpen,
        [FulfillmentOptionTypes.SHIP_TO_HOME]: this.setIsPostalCodeDrawerOpen,
      };
      return config[name];
    },
    setIsRetailerDrawerOpen(value = false) {
      this.isRetailerDrawerOpen = value;
    },
    setIsPostalCodeDrawerOpen(value = false) {
      this.isPostalCodeDrawerOpen = value;
    },
    async fetchFulfillmentOptions(options) {
      this.isLoadingOptions = true;
      try {
        const fulfillmentConfig = await ProductFulfillmentConfig.getFulfillmentConfig(options);
        return fulfillmentConfig;
      } finally {
        this.isLoadingOptions = false;
      }
    },
  },
};
</script>

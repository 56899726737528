<template>
  <div class="flex items-stretch">
    <button
      id="vendorSelectorToggle"
      ref="tippy_${tooltipId}"
      v-tippy="{
        html: '#vendor-selector-popover',
        interactive: true,
        reactive: true,
        trigger: 'click',
        placement: 'bottom',
        flip: false,
        sticky: true,
        popperOptions: {
          positionFixed: true,
          modifiers: {
            keepTogether: true,
            preventOverflow: {
              enabled: false,
            },
            hide: {
              enabled: false,
            },
          },
        },
      }"
      class="topnav__button text-white cursor-pointer flex items-center text-md font-medium"
    >
      <span id="vendorSelectorToggle_unitName">{{ currentVendorName }}</span>
      <pdl-icon id="vendorSelectorToggle_dropDown" class="ml-25" size="18" name="arrow_drop_down" />
    </button>

    <!-- Dropdown Content -->
    <div v-cloak id="vendor-selector-popover" class="text-left p-2">
      <!-- Header -->
      <pdl-section-header size="sm">
        <template slot="content">
          <pdl-heading>{{ $t('distributor.B2B.vendor.select') }}</pdl-heading>
        </template>
      </pdl-section-header>

      <!-- Search Box (If needed) -->
      <div v-if="vendorList && vendorList.length >= searchDisplayThreshold">
        <label for="vendor-search" class="form-label text-md">{{ $t('distributor.B2B.vendor.search') }}</label>
        <div class="form-group">
          <input id="vendor-search" v-model="search" type="text" class="form-control" />
          <span class="form-help">{{ $t('distributor.B2B.vendor.enter') }}</span>
        </div>
      </div>

      <!-- Scrollable vendor list -->
      <div v-if="filteredVendorList && filteredVendorList.length" class="b2b-scroll-list-container min-w-xs">
        <div class="b2b-scroll-list">
          <form ref="form" :action="`${encodedContextPath}/my-account/setVendor`" method="post">
            <input id="currentVendor" v-model="currentVendorId" class="hide" type="text" name="vendorId" />

            <input
              id="currentVendorMarketplaceCode"
              v-model="currentVendorMarketplaceCode"
              class="hide"
              type="text"
              name="marketplaceCode"
            />

            <input
              id="currentVendorProductGroupCode"
              v-model="currentVendorProductGroupCode"
              class="hide"
              type="text"
              name="productGroupCode"
            />

            <div
              v-for="(vendor, index) in filteredVendorList"
              :key="index"
              class="b2b-scroll-list__item"
              @click="selectVendor(vendor.id, vendor.marketplaceCode, vendor.productGroupCode)"
            >
              <p class="font-bold">{{ vendor.name }} - {{ vendor.id }}</p>
              <p v-if="vendor.notes" class="italic mt-1">{{ vendor.notes }}</p>
            </div>
          </form>
        </div>
      </div>
      <pdl-callout v-else id="vendorSelectorDropdown_emptyState">
        <template #content>
          <span>{{ $t('distributor.B2B.vendor.noneFound') }}</span>
        </template>
      </pdl-callout>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {PdlSectionHeader, PdlHeading, PdlCallout, PdlIcon},

  props: {
    initialCurrentVendorId: {
      type: String,
      default: null,
    },
    initialCurrentVendorMarketplaceCode: {
      type: String,
      default: null,
    },
    initialCurrentVendorProductGroupCode: {
      type: String,
      default: null,
    },
    vendorList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      currentVendorId: this.initialCurrentVendorId,
      currentVendorMarketplaceCode: this.initialCurrentVendorMarketplaceCode,
      currentVendorProductGroupCode: this.initialCurrentVendorProductGroupCode,
      search: '',
      // This can be used to control when the Search Box appears on the modal depending
      // on how many vendors there are
      searchDisplayThreshold: 2,
    };
  },

  computed: {
    currentVendorName() {
      if (
        Array.isArray(this.vendorList) &&
        this.currentVendorId &&
        this.currentVendorMarketplaceCode &&
        this.currentVendorProductGroupCode
      ) {
        const vendorFound = this.vendorList.find(
          (vendor) =>
            vendor.id === this.currentVendorId &&
            vendor.marketplaceCode === this.currentVendorMarketplaceCode &&
            vendor.productGroupCode === this.currentVendorProductGroupCode
        );
        return vendorFound ? vendorFound.name : vendorFound.id;
      }

      return null;
    },

    filteredVendorList() {
      if (typeof this.search === 'string' && this.search.length && Array.isArray(this.vendorList)) {
        return this.vendorList.filter((vendor) =>
          `${vendor.name} - ${vendor.id}`.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return this.vendorList;
      }
    },

    ...mapState('backend', ['encodedContextPath']),
  },

  methods: {
    selectVendor(id, marketplaceCode, productGroupCode) {
      if (
        this.currentVendorId === id &&
        this.currentVendorMarketplaceCode === marketplaceCode &&
        this.currentVendorProductGroupCode === productGroupCode
      )
        return;

      this.currentVendorId = id;
      this.currentVendorMarketplaceCode = marketplaceCode;
      this.currentVendorProductGroupCode = productGroupCode;
      this.$nextTick(() => this.$refs.form.submit());
    },
  },
};
</script>

<template>
  <tr class="table-row">
    <template v-for="(column, index) in columns">
      <!-- Action Cell -->
      <td
        v-if="column.key === 'actions'"
        :key="index"
        class="b2b-grid__cell lg:table-cell"
        :qaid="`warranty-grid-cell-${column.key}`"
      >
        <div class="b2b-grid__label">
          <slot name="label">
            <span>{{ column.label }}</span>
          </slot>
        </div>
        <div class="b2b-grid__content">
          <slot>
            <div class="flex content-center justify-start">
              <a
                v-if="showEditAction"
                class="underline hover:no-underline mr-1"
                :href="warrantyClaimEditUrl"
                qaid="`warranty-grid-cell-edit"
              >
                <span>{{ $t('text.edit') }}</span>
              </a>
              <a
                v-if="showViewAction"
                class="underline hover:no-underline mr-1"
                :href="warrantyClaimUrl"
                qaid="`warranty-grid-cell-view"
              >
                <span>{{ $t('text.view') }}</span>
              </a>
              <div v-if="isSavedClaim" class="flex" @click="$emit('delete-claim', item.id)">
                <pdl-icon
                  qaid="`warranty-grid-cell-delete"
                  name="delete"
                  size="18"
                  :label="$t('text.delete')"
                  class="cursor-pointer"
                />
              </div>
            </div>
          </slot>
        </div>
      </td>

      <!-- ID Cell -->
      <td
        v-else-if="column.key === 'id'"
        :key="index"
        class="b2b-grid__cell lg:table-cell"
        :qaid="`warranty-grid-cell-${column.key}`"
      >
        <div class="b2b-grid__label">
          <slot name="label">
            <span>{{ column.label }}</span>
          </slot>
        </div>
        <div class="b2b-grid__content">
          <slot>
            <a class="underline hover:no-underline" :href="warrantyClaimUrl">
              <span>{{ item[column.key] }}</span>
            </a>
          </slot>
        </div>
      </td>

      <!--Details Cell -->
      <template v-else-if="column.key === 'details'">
        <td :key="index" class="b2b-grid__cell lg:table-cell" :qaid="`warranty-grid-cell-${column.key}`">
          <div class="b2b-grid__label">
            <slot name="label">
              <span>{{ column.label }}</span>
            </slot>
          </div>
          <div class="b2b-grid__content">
            <slot>
              <div>
                <a
                  v-if="item.warrantyClaimDetailsLink"
                  :href="item.warrantyClaimDetailsLink"
                  class="underline hover:no-underline"
                  qaid="warranty-grid-cell-details-link"
                >
                  <span>{{ $t('text.view') }}</span>
                </a>
              </div>
            </slot>
          </div>
        </td>
      </template>

      <!-- Plain Text Cell -->
      <grid-cell
        v-else
        :key="index"
        :label="column.label"
        :value="item[column.key]"
        :qaid="`warranty-grid-cell-${column.key}`"
      ></grid-cell>
    </template>
  </tr>
</template>

<script>
import GridCell from '@/components/grid/GridCell';
import {PdlIcon} from '@pedal/pdl-icon';
import {ClaimStatuses} from '@/constants/warranty-claims';

export default {
  components: {
    GridCell,
    PdlIcon,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },

    item: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      contextPath: ACC.config.encodedContextPath || '',
    };
  },

  computed: {
    warrantyClaimUrl() {
      return `${this.contextPath}/warranty-claim/${this.item.id}`;
    },

    warrantyClaimEditUrl() {
      return `${this.contextPath}/warranty-claim/edit/${this.item.id}`;
    },

    showEditAction() {
      return (
        this.item.statusCode === ClaimStatuses.ENUMCLAIMSTATUSSAVED ||
        this.item.statusCode === ClaimStatuses.ENUMCLAIMSTATUSACTIONREQUIRED
      );
    },

    showViewAction() {
      return (
        this.item.statusCode === ClaimStatuses.ENUMCLAIMSTATUSSUBMITTED ||
        this.item.statusCode === ClaimStatuses.ENUMCLAIMSTATUSCOMPLETED ||
        this.item.statusCode === ClaimStatuses.ENUMCLAIMSTATUSCANCELLED
      );
    },

    isSavedClaim() {
      return this.item.statusCode === ClaimStatuses.ENUMCLAIMSTATUSSAVED;
    },
  },
};
</script>

<template>
  <div class="b2b-grid__input" :class="[{'has-warning': data.hasError}, data.cssClass]">
    <input
      v-tippy="{placement: 'top-end', arrow: true}"
      :type="getInputType(data.type)"
      class="b2b-grid__input-field text-md form-control"
      :disabled="!isEnabled"
      :value="data.value"
      :placeholder="data.placeholder"
      :size="data.inputSize"
      :maxlength="data.maxLength"
      :title="data.errorLabel"
    />
    <span v-if="data.text" class="text-secondary text-sm ml-1">
      {{ data.text }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => null,
    },
    initialIsEnabled: {type: Boolean, default: true},
  },
  data: function () {
    return {
      isEnabled: this.initialIsEnabled,
    };
  },
  methods: {
    getInputType(type) {
      return type.substring(type.indexOf('-') + 1);
    },

    changeState(state) {
      this.isEnabled = state;
    },
  },
};
</script>

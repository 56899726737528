<template>
  <li class="b2b-scroll-list__item flex" @click="onClick">
    <div class="notification__button">
      <button
        v-show="!notification.readStatus"
        v-tippy="{
          theme: 'light',
          interactive: true,
          trigger: 'mouseenter focus',
          reactive: true,
          popperOptions: {
            positionFixed: true,
            modifiers: {
              keepTogether: true,
              preventOverflow: {
                enabled: false,
              },
              hide: {
                enabled: false,
              },
            },
          },
        }"
        class="notification__hitbox"
        type="button"
        title="Mark as read"
      >
        <span class="notification__indicator"></span>
      </button>
    </div>

    <div class="notification__body">
      <h6 class="notification__subject" v-html="notification.category.localizedName"></h6>
      <p class="notification__text" @click="messageClickAction" v-html="notification.message"></p>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      default: () => null,
    },
  },

  methods: {
    onClick() {
      this.$emit('update:notification-read', this.notification.id);
    },
    messageClickAction(event) {
      if (event.target.tagName.toLowerCase() === 'a') {
        this.$emit('update:notification-read', this.notification.id);
      }
    },
  },
};
</script>

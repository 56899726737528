<template>
  <pdl-dialog
    :visible.sync="isShowingFindRetailerDialog"
    :z-index="16000011"
    :close-dialog-tool-tip="$t('popup.close')"
    qaid="find-retailer-dialog"
    :title="title"
    @close="closeFindRetailerDialog"
  >
    <form class="find-retailer-dialog-search">
      <label class="text-md">{{ $t('storelocator.query') }}</label>
      <div class="find-retailer-dialog-search-inner">
        <pdl-icon name="location_on" />
        <mapbox-address-suggestions-input
          input-classes="find-retailer-dialog-search-input"
          class="flex-1"
          :input-placeholder="$t('text.searchForALocation')"
          :input-max-length="inputMaxLength"
          :is-mapbox-enabled="isMapboxEnabled"
          @input-updated="updateInputValue($event)"
        ></mapbox-address-suggestions-input>
        <div>
          <pdl-button
            button
            primary
            qaid="find-retailer-dialog-find-retailers"
            class="find-retailer-dialog-search-button"
            @click="findRetailers()"
          >
            <span>{{ $t('text.search') }}</span>
          </pdl-button>
        </div>
      </div>
    </form>
    <div id="find-retailer-dialog-inner" class="find-retailer-dialog-inner p-0 mt-2">
      <pdl-loading v-if="isLoadingRetailers" :is-loading="isLoadingRetailers"></pdl-loading>

      <p
        v-else-if="!isLoadingRetailers && !retailerList.length > 0"
        class="flex justify-center items-center text-center"
      >
        {{ $t('text.search.noResults') }}
      </p>

      <div
        v-for="retailer in retailerList"
        v-else-if="!isLoadingRetailers && retailerList.length > 0"
        :key="retailer.name + Math.random()"
        class="sku-modal-status--ibd--info"
        :class="{'bg-gray-05 preferred': retailer.isPreferredPinnedResult}"
        :data-store-id="retailer.name"
      >
        <p v-if="retailer.isPreferredPinnedResult" class="font-bold text-green">
          {{ $t('storeFinder.product.my.pref') }}
        </p>

        <p>
          <pdl-link
            v-if="retailer.url"
            qaid="find-retailer-store-link"
            :href="retailer.url"
            standalone
            target="_blank"
            :title="retailer"
            >{{ retailer.displayName }}</pdl-link
          >
          <span v-else>{{ retailer.displayName }}</span>
        </p>

        <p v-if="retailer.address.formattedAddress" class="shop-address">
          {{ retailer.address.formattedAddress }}
        </p>

        <p v-if="retailer.formattedDistance" class="shop-distance">
          {{ `${$t('storeFinder.label.distance')}: ${retailer.formattedDistance} ${retailerData.distanceUnit}` }}
        </p>
        <p v-if="retailer.address.phone">
          <pdl-link :href="`tel:${retailer.address.phone}`" qaid="find-retailer-store-phone" standalone>
            {{ retailer.address.phone }}
          </pdl-link>
        </p>
        <div v-if="!retailer.isPreferredPinnedResult" class="pt-1">
          <div v-if="retailer.name === preferredRetailerName">
            <pdl-icon name="done" size="14" /><span class="pl-1 text-xs">{{ $t('storeFinder.product.my.pref') }}</span>
          </div>
          <pdl-button
            v-else
            secondary
            xsmall
            qaid="find-retailer-dialog-set-preferred"
            @click="setPreferredRetailer(retailer)"
            >{{ $t('storeFinder.product.set.as.pref') }}</pdl-button
          >
        </div>
      </div>
    </div>
  </pdl-dialog>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlIcon} from '@pedal/pdl-icon';
import MapboxAddressSuggestionsInput from '@/components/mapbox/components/MapboxAddressSuggestionsInput.vue';
import {mapState} from 'vuex';

const INPUT_MAX_LENGTH = 256;

export default {
  components: {
    PdlLoading,
    PdlDialog,
    PdlButton,
    PdlIcon,
    MapboxAddressSuggestionsInput,
  },
  props: {
    isMapboxEnabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    hasFindRetailerDialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowingFindRetailerDialog: false,
      isLoadingRetailers: false,
      retailerData: {},
      retailerList: [],
      preferredRetailerName: '',
      inputValue: '',
      inputData: {},
      isSearching: false,
      preferredRetailer: null,
      inputMaxLength: INPUT_MAX_LENGTH,
    };
  },
  watch: {
    hasFindRetailerDialogVisible() {
      this.isShowingFindRetailerDialog = this.hasFindRetailerDialogVisible;
    },
    ...mapState('backend', ['mapboxApiKey']),
  },
  async mounted() {
    await this.initPreferredRetailer();
    if (this.inputValue) {
      this.findRetailers();
    }
  },
  methods: {
    closeFindRetailerDialog() {
      this.$emit('close-find-retailer-dialog');
      this.$emit('preferred-retailer', this.preferredRetailer);
    },
    updateInputValue({inputValue = '', data = {}, isSearching = false}) {
      this.inputValue = inputValue;
      this.inputData = data;
      this.isSearching = isSearching;
      if (isSearching) {
        this.findRetailers();
      }
    },
    async findRetailers() {
      if (!this.inputValue) {
        return this.handleEmptyInput();
      }
      try {
        this.isLoadingRetailers = true;
        let searchParams = {};
        searchParams.q = this.inputValue;
        if (this.isMapboxEnabled && this.inputData.center) {
          searchParams.longitude = this.inputData.center[0];
          searchParams.latitude = this.inputData.center[1];
        }
        let retailerData = await storefrontInstance.get(
          `/electra-store-finder/json?${new URLSearchParams(searchParams).toString()}`
        );
        this.retailerData = retailerData?.data;
        this.retailerList = this.retailerData?.searchPageData?.results;
        if (this.preferredRetailer && this.retailerList.length > 0) {
          this.preferredRetailer.isPreferredPinnedResult = true;
          this.retailerList.unshift(this.preferredRetailer);
          this.preferredRetailerName = this.preferredRetailer?.name;
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          type: 'error',
          message: this.$t('errorMessage.unableToProcess'),
        });
      } finally {
        this.isLoadingRetailers = false;
      }
    },
    async initPreferredRetailer() {
      try {
        const preferredRetailerData = await storefrontInstance.get('/store-finder/get-preferred-retailer/');
        this.preferredRetailer = preferredRetailerData.data?.data;
        this.$emit('preferred-retailer', this.preferredRetailer);
      } catch (error) {
        console.log(error);
      }
    },
    async setPreferredRetailer(retailer) {
      try {
        this.isLoadingRetailers = true;
        await storefrontInstance.post(`/store-finder/save-preferred-retailer/json/?storeName=${retailer.name}`);
        this.preferredRetailer = retailer;
        this.$notify({
          type: 'success',
          message: this.$t('text.account.profile.confirmationUpdated'),
        });
        await this.findRetailers();
      } catch (error) {
        console.error(error);
        this.$notify({
          type: 'error',
          message: this.$t('errorMessage.unableToProcess'),
        });
      } finally {
        this.isLoadingRetailers = false;
      }
    },
    handleEmptyInput() {
      this.retailerList = [];
      this.$notify({
        type: 'error',
        message: this.$t('storefinder.searchterm.invalid'),
      });
    },
  },
};
</script>

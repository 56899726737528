<template>
  <fieldset class="form-fieldset" qaid="shipping-info-ship-method">
    <legend class="form-label">
      {{ $t('text.shippingOptions') }}
    </legend>
    <div class="select">
      <select v-model="selectedShippingOptions" class="form-control" required>
        <option
          v-for="shippingMethod in shipmentsInfo.shipments[type].shippingOptions.options"
          :key="shippingMethod.code"
          :value="shippingMethod.code"
        >
          {{ shippingMethod.name }}
        </option>
      </select>
    </div>
  </fieldset>
</template>

<script>
import {mapMutations} from 'vuex';
export default {
  props: {
    shipmentsInfo: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    selectedShippingOptions: {
      get() {
        return this.shipmentsInfo.shipments[this.type].shippingOptions.selected;
      },
      set(value) {
        this.updateShippingOptions({type: this.type, value});
      },
    },
  },
  methods: {
    ...mapMutations('shipments', ['updateShippingOptions']),
  },
};
</script>

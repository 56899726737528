import { render, staticRenderFns } from "./CheckoutSummaryItem.vue?vue&type=template&id=0edb96c1&scoped=true&"
import script from "./CheckoutSummaryItem.vue?vue&type=script&lang=js&"
export * from "./CheckoutSummaryItem.vue?vue&type=script&lang=js&"
import style0 from "./CheckoutSummaryItem.vue?vue&type=style&index=0&id=0edb96c1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0edb96c1",
  null
  
)

export default component.exports
<template>
  <div class="rcp-builder-route-info-tab text-lg font-bold mr-3">
    <div class="rcp-builder-route-info-tab__distance">
      <span>{{ roundedTotalDistance }} {{ userPreferredUnit.distanceUnit }}</span>
    </div>
    <div class="rcp-builder-route-info-tab__elevation">
      <pdl-icon name="add" size="14" />
      <span>{{ roundedTotalElevationGain }}{{ userPreferredUnit.elevationUnit }}</span>
      <pdl-icon class="ml-25" name="moving" size="18" />
    </div>
  </div>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';
import {mapState, mapGetters} from 'vuex';
import {UnitTypes} from '@/constants/unit-types';
import {convertMetersToDistanceUnit, convertMetersToElevationUnit} from '@/utils/unit-converter';

export default {
  components: {
    PdlIcon,
  },
  props: {
    totalElevationGain: {
      type: Number,
      default: 0,
    },
    totalDistance: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('backend', ['preferredUnit']),
    ...mapGetters('backend', ['unitAbbreviation']),
    userPreferredUnit() {
      let elevationUnit, distanceUnit;
      if (this.preferredUnit === UnitTypes.IMPERIAL.milesString) {
        elevationUnit = this.$t('rcp.routeBuilder.distance.unit.ft');
        distanceUnit = this.$t('storeFinder.distance.unit.mi');
      } else if (this.preferredUnit === UnitTypes.METRIC.kilometersAbbreviation) {
        elevationUnit = this.$t('rcp.routeBuilder.distance.unit.m');
        distanceUnit = this.$t('storeFinder.distance.unit.km');
      }
      return {elevationUnit, distanceUnit};
    },
    roundedTotalDistance() {
      if (!this.totalDistance) return '0.0';
      return convertMetersToDistanceUnit(this.totalDistance, this.preferredUnit).toFixed(1);
    },
    roundedTotalElevationGain() {
      const totalElevationGainInUnits = convertMetersToElevationUnit(this.totalElevationGain, this.preferredUnit);

      return Math.round(totalElevationGainInUnits);
    },
  },
};
</script>

<style lang="scss" scoped>
.rcp-builder-route-info-tab {
  display: flex;
  background-color: var(--white);
  min-width: 12rem;
  min-height: 2rem;
  color: var(--gray-90);
  position: absolute;
  z-index: 1001;
  right: 0;
  bottom: 0; // Default required in order for transition on button to work
  transition: bottom var(--drawer-transition-duration) var(--drawer-transition-timing-function),
    right var(--drawer-transition-duration) var(--drawer-transition-timing-function);

  &.button-expanded {
    bottom: var(--elevation-drawer-height);
  }

  &.right-rail-open {
    right: var(--builder-rail-drawer-width);
  }

  &.rcp-details-page {
    position: relative;
    margin-right: 0;
  }

  .rcp-builder-route-info-tab__distance,
  .rcp-builder-route-info-tab__elevation {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0.5rem 1rem;
  }

  .rcp-builder-route-info-tab__distance {
    &::after {
      content: '';
      position: absolute;
      top: 10%;
      right: -1px;
      height: 80%;
      width: 1px;
      background-color: var(--gray-30);
    }
  }
}
</style>

<template>
  <div>
    <span ref="locallyButtonIcon" class="hide">
      <pdl-icon name="pin_drop" />
      {{ $t('retailerProductAvailability.findInStore') }}
    </span>
    <div
      v-if="showChooseOptionMessage"
      qaid="locally-choose-option-message"
      class="text-md p-3 mt-2 bg-gray-10 text-center"
      v-text="$t('text.buyingZone.locally.select')"
    />
    <div
      v-if="showEmptyStoreDataMessage"
      qaid="locally-empty-store-message"
      class="text-md p-3 mt-2 bg-gray-10 text-center"
    >
      {{ $t('storeFinder.locally.stockStatus.notAvailable') }}
    </div>
    <div
      v-show="!showChooseOptionMessage"
      id="lcly-button-0"
      data-switchlive="true"
      data-switchlive-impression="true"
      data-switchlive-impression-id-PL="1"
      :class="{hide: hideWidget}"
    >
      <a
        id="lcly-link-0"
        data-switchlive="true"
        data-switchlive-mode="auto"
        data-switchlive-id-PL="6"
        href="https://brands.locally.com"
        target="_blank"
        rel="noopener"
      ></a>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import headTag from '@/utils/head-tag';
import {mapState} from 'vuex';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {
    PdlIcon,
  },
  props: {
    options: {
      type: Object,
      default: () => null,
    },
    product: {
      type: Object,
      default: () => null,
    },
    variant: {
      type: Object,
      default: () => null,
      required: true,
    },
    variantCode: {
      type: String,
      default: '',
    },
    showLocallyWidget: {
      type: Boolean,
      default: true,
    },
    radius: {
      type: Object,
      default: () => ({
        miles: '200',
        km: '200',
      }),
    },
  },
  data() {
    return {
      storeData: {},
      showLink: true,
      hideWidget: true,
      showChooseOptionMessage: false,
      isLoading: false,
      configurationQueue: [],
    };
  },
  computed: {
    ...mapState(['locale']),
    ...mapState('backend', ['geolocation']),
    locallyConfig() {
      const config = {
        company_name: this.options.companyName,
        button_text: '&nbsp;',
        button_id: 'HTML',
        company_id: this.options.companyId,
        css: '6',
        upc: this.selectedVariantUPC,
        n_related_styles: '3',
        show_location_switcher: '0',
        lang: this.$store.state.locale.toLowerCase().replace(/_/g, '-'),
        show_dealers: '0',
        no_link: '1',
        always_hide_button: '0',
        hide_all_when_no_stock: '0',
        ...(this.countryCode && {only_show_country: this.countryCode}),
      };
      return config;
    },
    countryCode() {
      if (this.locale.includes('IN_TL')) return this.geolocation?.countryCode;
      return this.locale?.split('_')?.[1]?.toUpperCase();
    },
    isVariantSelected() {
      return !isEmpty(this.variant);
    },
    selectedVariantUPC() {
      let upc = '';

      if (this.isVariantSelected) {
        upc = this.variant?.upc ?? '';
      } else if (this.product?.variants?.length === 1) {
        upc = this.product.variants[0]?.upc ?? '';
      }

      return upc;
    },
    locallyEndpoint() {
      const obj = this.locallyConfig;
      const locallyQuery = Object.keys(obj)
        .reduce((a, k) => {
          a.push(`${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`);
          return a;
        }, [])
        .join('&');
      return `${this.options.baseUrl}/stores/map.js?${locallyQuery}`;
    },
    showEmptyStoreDataMessage() {
      return (
        this.isVariantSelected &&
        this.storeData?.n_items_stocking_product === 0 &&
        this.storeData?.product_id > 0 &&
        this.hideWidget
      );
    },
    productVariants() {
      return this.product.variants.map((variant) => variant.upc).toString();
    },
  },
  watch: {
    selectedVariantUPC: function () {
      this.reloadLocally({
        hide_all_when_no_stock: this.locallyConfig.hide_all_when_no_stock,
        no_link: this.locallyConfig.no_link,
        upc: this.variant.upc ?? '',
      });
    },
    variantCode: function (code) {
      this.showChooseOptionMessage = !code?.length;
    },
    variant: function () {
      if (this.isVariantSelected) {
        this.locallyConfig.upc = this.variant.upc;
        this.reloadLocally({
          upc: this.variant.upc ?? '',
        });
      }
    },
    showLink: function () {
      this.reloadLocally({
        hide_all_when_no_stock: this.locallyConfig.hide_all_when_no_stock,
        no_link: this.locallyConfig.no_link,
        upc: this.variant.upc ?? '',
      });
    },
    showLocallyWidget: function () {
      this.reloadLocally({
        hide_all_when_no_stock: this.locallyConfig.hide_all_when_no_stock,
        no_link: this.locallyConfig.no_link,
        upc: this.variant.upc ?? '',
      });
    },
  },
  mounted() {
    this.setUpLocallyRadius();
    this.appendLocallyScript();
  },
  destroyed: function () {
    window.removeEventListener('LOCALLY_data_update', this.storeDataLoad);
  },
  methods: {
    async reloadLocally(props) {
      if (!isFunction(window.lcly_reload_0)) {
        //add this configuration to the queue of staged configuration changes
        this.configurationQueue = [...this.configurationQueue, props];
        return;
      }

      if (props?.upc && !this.isLoading) {
        this.isLoading = true;
        return window.lcly_reload_0(props);
      }
    },
    locallyData() {
      window.addEventListener(
        'LOCALLY_data_update',
        () => {
          this.storeDataLoad();
          const locallyButtonIcon = this.$refs.locallyButtonIcon;
          const locallyButton = document.querySelector('.lcly-primary-trigger span');
          if (locallyButton) {
            locallyButton.appendChild(locallyButtonIcon);
            locallyButtonIcon.classList.remove('hide');
          }
        },
        false
      );
    },
    async appendLocallyScript() {
      this.locallyData();

      await headTag.appendAsyncScript('lcly-script-0', this.locallyEndpoint);
      await headTag.appendAsyncScript(
        'lcly-script-switchlive-0',
        `${this.options.baseUrl}/js/switchlive.js?company_id=${this.options.companyId}`
      );
      await headTag.appendAsyncScript(
        'lcly-script-switchlive-events-0',
        `${this.options.baseUrl}/switchlive/switchlive-events.js?company_id=${this.options.companyId}`
      );

      if (this.configurationQueue.length > 0) {
        this.reloadLocally(this.processQueue(this.configurationQueue));
        this.configurationQueue = [];
      }
    },
    storeDataLoad(event) {
      this.storeData = event?.detail ?? {};
      this.hideWidget = !this.isVariantSelected;
      this.showChooseOptionMessage = !this.isVariantSelected;

      this.locallyConfig.hide_all_when_no_stock = '0';

      let hasChanged = true;
      if (!this.isVariantSelected) {
        this.showLink = true;
      } else {
        if (isEmpty(this.storeData) || (this.storeData.n_items_stocking_product === 0 && this.storeData.product_id)) {
          this.hideWidget = false;
          this.showLink = false;
        } else if (!this.storeData.product_id) {
          this.locallyConfig.hide_all_when_no_stock = '1';
          hasChanged = false;
        } else {
          this.hideWidget = false;
          this.showLink = true;
        }
      }
      this.$emit('change-locally-widget', hasChanged);
      this.isLoading = false;

      //If there are items in the config queue, trigger a reload
      if (this.configurationQueue.length > 0) {
        this.reloadLocally(this.processQueue(this.configurationQueue));
        this.configurationQueue = [];
      }
    },
    setUpLocallyRadius() {
      const kmLocales = ['en_ca', 'fr_ca'];
      if (kmLocales.includes(this.$store.state.locale.toLowerCase())) {
        this.locallyConfig.radius_in_km = this.radius.km;
      } else {
        this.locallyConfig.radius_in_miles = this.radius.miles;
      }
    },
    processQueue(queue) {
      const aggregatedConfig = queue.reduce((prevConfig, currentConfig) => {
        return {...prevConfig, ...currentConfig};
      }, {});
      return aggregatedConfig;
    },
  },
};
</script>

<style lang="scss">
.pdp-product-details {
  div#lcly-button-0 {
    margin: 0 0 0.5rem;
    float: none;
  }

  #lcly-button-buy {
    font-size: 1rem;
    float: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 0.875em 1.5em;
    background-color: transparent;
    border-color: var(--gray-100);
    border: 1px solid var(--gray-100);
    transition: all 0.25s ease-out;

    &:hover,
    &:focus {
      background-color: var(--gray-10);
      color: var(--gray-100);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0.125rem 0.0625rem var(--white), 0 0 0.125rem 0.125rem var(--blue-100);
    }

    span {
      font-size: 1rem;
      line-height: 1;
      color: var(--gray-100);
      display: inline-flex;
      align-items: center;

      .pdl-icon {
        margin-right: 0.2em;
      }
    }
  }
}
</style>

<template>
  <div v-if="tabData.length > 0">
    <div class="form-group pt-25">
      <label class="checkbox">
        <input v-model="selectAllCheckbox" type="checkbox" @change="toggleSelectAll" />
        <span class="control-indicator"></span>
        <span v-html="selectAllText" />
      </label>
    </div>

    <ul class="mb-4 border-t border-gray-10">
      <li v-for="(item, itemIndex) in paginatedData" :key="item.id" class="border-b border-gray-10 my-2 pb-2">
        <div class="flex">
          <label class="checkbox">
            <input :id="`item-${itemIndex}`" v-model="item.active" type="checkbox" />
            <span class="control-indicator"></span>
          </label>

          <div class="notification__button mr-1 mt-25">
            <button
              v-show="!item.readStatus"
              v-tippy="{
                theme: 'light',
                interactive: true,
                trigger: 'mouseenter focus',
                reactive: true,
                popperOptions: {
                  positionFixed: true,
                  modifiers: {
                    keepTogether: true,
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  },
                },
              }"
              class="notification__hitbox"
              title="Mark as read"
              @click="markItemAsRead(item)"
            >
              <span class="notification__indicator"></span>
            </button>
          </div>

          <div v-if="item.image" class="flex w-full">
            <div class="sm:hidden md:block flex-none md:w-1/5 lg:w-1/6 mr-2">
              <img :src="imageUrl(item.image)" class="w-full" alt="" />
            </div>
            <div
              class="flex-auto notification__message"
              :class="{'font-bold': !item.readStatus}"
              @click="messageClickAction(item)"
              v-html="item.message"
            ></div>
          </div>
          <div
            v-else
            class="notification__message"
            :class="{'font-bold': !item.readStatus}"
            @click="messageClickAction(item)"
            v-html="item.message"
          ></div>
        </div>
      </li>
    </ul>

    <div class="md:flex md:justify-end">
      <div class="md:flex md:flex-grow text-center">
        <pagination-controls
          v-if="pageCount > 1"
          ref="pagination"
          :page-number="pageNumber"
          :page-count="pageCount"
          @page-change="pageChange"
          @prev-page="prevPage"
          @next-page="nextPage"
        ></pagination-controls>
      </div>

      <div class="buttons buttons--right-for-md">
        <trek-button primary @click="markSelectedAsRead">
          <span v-html="markAsReadMsg"></span>
        </trek-button>
      </div>
    </div>
  </div>
  <div v-else>
    <span v-html="noItem" />
  </div>
</template>

<script>
import pagination from '../../../mixins/pagination';
import TrekButton from '@/components/TrekButton';

export default {
  name: 'NotificationTab',
  components: {TrekButton},
  mixins: [pagination],
  props: {
    noItem: {
      type: String,
      default: 'You have no notifications',
    },
    markAsReadMsg: {
      type: String,
      default: 'Mark As Read',
    },
    selectAllText: {
      type: String,
      default: 'Select All',
    },
    size: {
      type: Number,
      required: false,
      default: 15,
    },
    tabData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectAllCheckbox: false,
    };
  },
  computed: {
    listData() {
      return this.tabData;
    },
  },
  methods: {
    toggleSelectAll(event) {
      this.tabData.map((item) => {
        item.active = event.target.checked;
        return item;
      });
    },
    markSelectedAsRead() {
      this.$emit('update:notification-read-all-marked');
    },
    markItemAsRead(item) {
      this.$emit('update:notification-read', item.id);
    },
    messageClickAction(item) {
      if (event.target.tagName.toLowerCase() === 'a') {
        this.markItemAsRead(item);
      }
    },
    imageUrl(url) {
      let assetId = url.split('/');
      assetId = assetId[assetId.length - 1];
      return `${ACC.imageBaseUrl}f_auto,q_auto,c_fill,w_400,h_300/${assetId}`;
    },
  },
};
</script>

<template>
  <div
    v-if="productCode && productCode.length && (!isPdp || hasRefLinks || hasSpecItems)"
    qaid="tech-info-container"
    class="grid-container text-md"
  >
    <section v-if="b2b">
      <pdl-section-header size="xl" :is-dividing="true" class="my-4">
        <template slot="content">
          <pdl-heading :level="1" qaid="tech__parts-header">{{ techPartsHeader }}</pdl-heading>
        </template>
      </pdl-section-header>
    </section>
    <!-- Product section -->
    <section>
      <pdl-section-header size="md">
        <template slot="content">
          <pdl-heading :level="2" qaid="tech__product-name-header">
            {{ productModelYear }} {{ productName }}
          </pdl-heading>
        </template>
      </pdl-section-header>
      <div class="grid-x grid-margin-x my-3">
        <div class="cell medium-6 large-4 mb-3">
          <div qaid="tech__product-code" class="font-medium text-gray-60 mb-1">
            {{ $t('garage.bike.type') }} {{ productCode }}
          </div>
          <img :src="productImage" alt="" />
        </div>

        <div class="cell medium-6 large-8">
          <div class="grid-x grid-margin-x">
            <div v-if="references && references.length" qaid="tech__references-link" class="cell large-6">
              <div class="font-medium text-gray-60 mb-1">{{ $t('text.references') }}</div>

              <ul class="mb-2">
                <li v-for="(reference, index) in references" :key="index" class="icon-item mb-1">
                  <pdl-icon :class="{invisible: !reference.assetLocation}" name="description" qaid="ref-link-icon" />
                  <ref-link
                    class="underline flex-1"
                    :title="reference.title"
                    :link="reference.link"
                    :asset-type="reference.assetType"
                    :asset-location="reference.assetLocation"
                    :target="reference.target"
                  />
                </li>
              </ul>
            </div>

            <div class="cell large-6">
              <div v-if="videos && videos.length">
                <div class="font-medium text-gray-60 mb-1">
                  {{ $t('text.videos') }}
                </div>
                <ul class="mb-2">
                  <li v-for="(video, index) in videos" :key="index" class="icon-item mb-1">
                    <pdl-icon name="play_circle_filled" />
                    <ref-link
                      class="underline flex-1"
                      :title="video.title"
                      :link="video.link"
                      :asset-type="video.assetType"
                      :asset-location="video.assetLocation"
                      :target="reference.target"
                    />
                  </li>
                </ul>
              </div>

              <div v-if="!isPdp">
                <div class="font-medium text-gray-60 mb-1">
                  {{ $t('text.url') }}
                </div>
                <ul class="mb-2">
                  <li qaid="tech__product-page-link" class="icon-item mb-1">
                    <pdl-icon name="arrow_forward" />
                    <a :href="`${linkBasePath}/p/${productCode}/`" class="underline flex-1">{{
                      $t('techProdSupport.B2B.visitProductPage')
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Replacement parts section -->
    <section v-if="hasSpecItems" qaid="tech__related-parts-section">
      <div class="grid-x grid-margin-x my-5">
        <div class="cell large-3">
          <pdl-section-header size="md">
            <template slot="content">
              <pdl-heading :level="2">{{ $t('techProdSupport.B2B.findParts') }}</pdl-heading>
            </template>
          </pdl-section-header>
          <div class="mb-1">
            {{ $t('techProdSupport.B2B.findParts.selectCategory') }}
          </div>
          <div class="mb-2">
            <fieldset>
              <tech-info-variant v-if="!variants" :product="product" @sku-selected="skuSelected = $event" />
              <tech-info-variant-legacy
                v-if="variants"
                :variant-index="variantIndex"
                :variants="variants"
                @sku-selected="skuSelected = $event"
              />
              <div class="buttons">
                <trek-button primary block @click="findRelatedParts">
                  {{ $t('techProdSupport.B2B.findParts') }}
                </trek-button>
                <trek-button
                  v-show="showResults"
                  secondary
                  block
                  icon="list"
                  class="hide-for-large"
                  data-toggle="b2b-off-canvas-facet"
                >
                  {{ $t('text.filterParts') }} {{ filterCount }}
                </trek-button>
              </div>
            </fieldset>
          </div>

          <div
            v-show="showResults"
            id="b2b-off-canvas-facet"
            class="b2b-techinfo-filters off-canvas position-top reveal-for-large lg:mt-4"
            data-off-canvas
            data-transition="overlap"
          >
            <pdl-section-header size="md" :is-dividing="true" class="show-for-large">
              <template slot="content">
                <pdl-heading>{{ $t('search.nav.refinements') }} {{ filterCount }}</pdl-heading>
              </template>
            </pdl-section-header>
            <button
              type="button"
              class="button button--primary button--hollow button--skinny button--expanded hide-for-large mt-2"
              data-close
            >
              <div class="flex content-center justify-between w-full">
                <div class="flex items-center justify-center w-full">
                  <pdl-icon class="mr-1" name="list" />
                  {{ $t('search.nav.refinements') }} {{ filterCount }}
                </div>
                <div class="flex-grow-0">
                  <pdl-icon name="close" />
                </div>
              </div>
            </button>
            <trek-button v-show="filterCount" primary block class="hide-for-large" data-close>
              {{ $t('search.nav.done.button') }}
            </trek-button>
            <pdl-collapse v-if="filter" :value="['filter-Parts']" qaid="tech-info-filter-collapse">
              <filter-group
                ref="filter-parts"
                :items="filter.items"
                :group-name="filter.label"
                @change="filterUpdate($event)"
              />
            </pdl-collapse>
          </div>
        </div>

        <div class="cell large-9">
          <div v-if="!showResults">
            <pdl-callout>
              <template #content>
                {{ $t('techProdSupport.B2B.findParts.selectCategory') }}
              </template>
            </pdl-callout>
          </div>

          <div v-if="showResults">
            <div class="flex justify-between mb-1 font-medium">
              <span>{{ filteredItems.length }} {{ $t('text.results') }}</span>
              <span class="text-gray-60">{{ $t('text.account.savedLists.sku') }} {{ skuSelected }}</span>
            </div>

            <div v-for="(item, index) in displayedItems" :key="index" class="bg-gray-05 p-3 md:pb-2 mb-1">
              <dl class="description-list grid-x grid-margin-x">
                <dt qaid="tech-info-item-type" class="cell medium-3">{{ $t('text.itemType') }}</dt>
                <dd class="cell medium-9">{{ item.partName }}</dd>

                <template v-if="item.description">
                  <dt class="cell medium-3">
                    {{ $t('productCompare.description') }}
                  </dt>
                  <dd class="cell medium-9">
                    {{ item.description }}
                  </dd>
                </template>

                <template v-if="item.originalItem.length > 0 || item.serviceMessage">
                  <dt class="cell medium-3">
                    {{ $t('text.originalItem') }}
                  </dt>

                  <dd class="cell medium-9">
                    <a
                      v-if="item.originalItem.length > 0"
                      :href="`${contextPath}${item.originalItem[0].url}`"
                      class="underline"
                      >{{ item.originalItem[0].sku }}</a
                    >
                    <span v-else>{{ item.serviceMessage }}</span>
                  </dd>
                </template>

                <template v-if="showMfrPartNumber(item)">
                  <dt class="cell medium-3">
                    {{ $t('text.mfgPartNumber') }}
                  </dt>

                  <dd class="cell medium-9">
                    {{ item.originalItem[0].mfrPartNumber }}
                  </dd>
                </template>

                <template v-if="item.alternativeItems.length > 0 || item.serviceMessage">
                  <template v-if="item.alternativeItems.length > 0">
                    <template v-for="(altItem, altItemIndex) in item.alternativeItems">
                      <dt :key="`label-${altItemIndex}`" class="cell medium-3">
                        <span v-if="altItemIndex === 0">{{ $t('text.alternativeItems') }}</span>
                      </dt>
                      <dd :key="`url-${altItemIndex}`" class="cell medium-9">
                        <a :href="`${contextPath}${altItem.url}`" class="underline">{{ altItem.sku }}</a>
                      </dd>
                    </template>
                  </template>

                  <template v-else>
                    <dt class="cell medium-3">
                      {{ $t('text.alternativeItems') }}
                    </dt>
                    <dd class="cell medium-9">
                      {{ item.serviceMessage }}
                    </dd>
                  </template>
                </template>
              </dl>
            </div>

            <div v-if="filteredItems.length > resultsSize" class="buttons">
              <trek-button primary block @click="resultsSize += showMoreIncrement">
                {{ $t('review.show.more') }}
              </trek-button>
            </div>

            <div v-else-if="filteredItems.length <= resultsSize && showMoreIncrement < resultsSize" class="buttons">
              <trek-button primary block @click="resultsSize = showMoreIncrement">
                {{ $t('text.showFewer') }}
              </trek-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import filter from 'lodash/filter';
import find from 'lodash/find';
import some from 'lodash/some';
import {mapMutations, mapState} from 'vuex';
import {ProductApi} from '@/api/product';
import {PdlIcon} from '@pedal/pdl-icon';
import TechInfoVariant from '@/components/containers/tech-info/TechInfoVariant';
import TechInfoVariantLegacy from '@/components/containers/tech-info/TechInfoVariantLegacy';
import {PdlCollapse} from '@pedal/pdl-collapse';
import FilterGroup from '@/components/FilterGroup';
import {PdlCallout} from '@pedal/pdl-callout';
import TrekButton from '@/components/TrekButton';
import RefLink from '@/components/ref-link/RefLink';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {
    PdlIcon,
    TechInfoVariant,
    TechInfoVariantLegacy,
    PdlCollapse,
    FilterGroup,
    PdlCallout,
    TrekButton,
    RefLink,
    PdlSectionHeader,
    PdlHeading,
  },

  props: {
    linkBasePath: {
      type: String,
      default: null,
    },
    isPdp: Boolean,
    baseProductCode: {
      type: String,
      default: null,
    },
    productIsBike: Boolean,
    baseProductImage: {
      type: String,
      default: null,
    },
    productModelYear: {
      type: String,
      default: null,
    },
    productName: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      product: null,
      filter: null,
      specItems: [],
      filteredItems: [],
      variantIndex: 0,
      variants: null,
      skuSelected: '',
      productImage: this.baseProductImage,
      productCode: this.baseProductCode,
      contextPath: ACC.config.encodedContextPath || '',
      filterCount: '',
      resultsSize: 15,
      showMoreIncrement: 15,
      hasRefLinks: false,
      hasSpecItems: false,
    };
  },

  computed: {
    displayedItems() {
      return this.filteredItems.slice(0, this.resultsSize);
    },
    matrix() {
      return this.getObjectFromElement('matrix-json');
    },
    showResults() {
      return this.specItems && this.specItems.length;
    },
    techPartsHeader() {
      if (this.productIsBike) {
        return this.$t('techProdSupport.B2B.bike');
      } else {
        return this.$t('techProdSupport.B2B.partsAccessories');
      }
    },

    ...mapState('storage', ['bikeTechSKU']),
    ...mapState('backend', ['b2b']),
  },

  watch: {
    skuSelected() {
      this.findParts();
    },
  },
  created() {
    if (this.productCode && this.productCode.length) {
      storefrontInstance
        .get(`/product/spec/technicalData/${this.productCode}/`)
        .then(({data}) => {
          this.hasSpecItems = data.data.specItemsPresent;
          this.hasRefLinks = data.data.refLinkPresent;
          this.references = data.data.references;
          this.videos = data.data.videos;
        })
        .catch(() => {
          this.clearData();
        });
    }
  },

  mounted() {
    this.processMatrixVariants();
  },

  updated() {
    // Needed since the DOM updates with a v-if.
    // Tech Debt: Remove and replace with a Vue solution
    $(this.$el).foundation();
  },

  destroyed() {
    // Needed since foundation is re-initialized on update()
    // Tech Debt: Remove and replace with a Vue solution
    $(this.$el).foundation.destroy();
  },

  methods: {
    showMfrPartNumber(displayedItem) {
      return displayedItem.originalItem?.length > 0 && displayedItem.originalItem[0]?.mfrPartNumber;
    },
    filterUpdate(filters) {
      if (filters && filters.length) {
        this.filteredItems = filter(this.specItems, (item) => {
          let found = false;

          filters.forEach((filter) => {
            if (item.partId === filter) {
              found = true;
            }
          });

          return found;
        });

        this.filterCount = `(${this.filteredItems.length})`;
      } else {
        this.filteredItems = this.specItems;
        this.filterCount = '';
      }
    },

    processMatrixVariants() {
      if (this.matrix && this.matrix.length) {
        this.variants = this.getVariantsLegacy(this.matrix);
      } else {
        ProductApi.loadFilteredProduct(this.baseProductCode).then((data) => {
          this.product = data;
        });
      }
    },

    // Recursive function to create a full tree of variants used in dropdowns
    getVariantsLegacy(variants) {
      if (variants && variants.length) {
        let newVariant = {
          id: variants[0].parentVariantCategory.code,
          label: variants[0].parentVariantCategory.name,
          isLeaf: variants[0].isLeaf,
          options: variants.map((variant) => {
            return {
              label: variant.variantValueCategory.name,
              value: variant.isLeaf ? variant.variantOption.code : variant.variantValueCategory.name,
              variants: variant.elements && variant.elements.length ? this.getVariantsLegacy(variant.elements) : null,
            };
          }),
        };

        this.processPreSelectedSku(newVariant);
        return newVariant;
      }
      return null;
    },

    // If there is a SKU preselected from the tech selector screen. Pre-select all the options
    // needed to show that sku
    processPreSelectedSku(newVariant) {
      if (this.bikeTechSKU && this.bikeTechSKU.length) {
        if (newVariant.isLeaf) {
          let optionFound = find(newVariant.options, (option) => option.value === this.bikeTechSKU);

          if (optionFound) {
            optionFound.selected = true;
          }
        } else {
          newVariant.options.forEach((option) => {
            option.selected = some(option.variants.options, (subOption) => subOption.selected);
          });
        }
      }
    },

    findParts() {
      this.retrieveSpecInfo(this.skuSelected);
    },

    findRelatedParts() {
      this.findParts();
      this.$refs['filter-parts'].clearFilters();
    },

    getObjectFromElement(elementId) {
      const el = document.getElementById(elementId);

      if (el) {
        const elString = el.value;

        if (elString) {
          return JSON.parse(elString);
        }
      }

      return {};
    },

    async retrieveSpecInfo(variantSku) {
      if (variantSku?.length) {
        try {
          const {data} = await storefrontInstance.get(`/product/spec/${variantSku}/`);
          if (data?.data) {
            this.filter = {
              label: this.$t('text.partsAccessories'),
              items: data.data.filters[0].filters.map((filterItem) => ({
                id: filterItem.id,
                text: filterItem.specItemName,
              })),
            };
            this.specItems = data.data.specItems;
            this.productImage = data.data.productImage;
            this.productCode = data.data.sku;
            this.filteredItems = this.specItems;
          } else {
            this.clearData();
          }
        } catch (error) {
          this.clearData();
        }
      }
    },

    clearData() {
      this.filter = null;
      this.specItems = [];
      this.productImage = this.baseProductImage;
      this.productCode = this.baseProductCode;
    },

    ...mapMutations('storage', ['setBikeTechSKU']),
  },
};
</script>

<style lang="scss" scoped>
#b2b-off-canvas-facet {
  z-index: 16000020;
}
</style>

<template>
  <div class="grid-container">
    <section>
      <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="1" qaid="warranty-header">{{ $t('warrantyClaims.B2B.serviceClaims') }}</pdl-heading>
          <pdl-subheading v-if="!isFeedbackActive" class="mt-1" qaid="warranty-sub-header-text">
            {{ $t('warrantyClaims.B2B.provideImage') }}
          </pdl-subheading>
        </template>
        <template slot="actions">
          <trek-link
            v-if="!isFeedbackActive"
            button
            secondary
            small
            qaid="warranty-start-new-claim"
            :href="`${contextPath}/warranty-claim`"
          >
            <span>{{ $t('warrantyClaims.B2B.startNewClaim') }}</span>
          </trek-link>
        </template>
      </pdl-section-header>
    </section>

    <section v-if="isFeedbackActive">
      <div class="grid-x grid-margin-x">
        <div
          class="cell large-6 flex flex-col border-solid border border-gray-10 items-center align-center text-center shadow py-5 mb-2 lg:mb-0"
        >
          <pdl-icon class="mb-1 text-blue" size="48" name="sync_problem" />
          <span class="mb-2">{{ $t('text.warrantyClaim') }}</span>
          <trek-link button primary qaid="warranty-start-new-claim" :href="`${contextPath}/warranty-claim`">
            <span>{{ $t('warrantyClaims.B2B.startNewClaim') }}</span>
          </trek-link>
        </div>
        <div
          class="cell large-6 flex flex-col border-solid border border-gray-10 items-center align-center text-center shadow py-5"
        >
          <pdl-icon class="mb-1 text-orange" size="48" name="feedback" />
          <span>{{ $t('warrantyClaims.B2B.feedback.alert.message1') }}</span>
          <span class="mb-2">{{ $t('warrantyClaims.B2B.feedback.alert.message2') }}</span>
          <trek-link button primary qaid="start-product__feedback" :href="`${contextPath}/product-feedback`">
            <span>{{ $t('warrantyClaims.B2B.feedback.submitProduct') }}</span>
          </trek-link>
        </div>
      </div>
    </section>

    <section>
      <pdl-section-header size="md" :is-dividing="true" class="mt-3">
        <template slot="content">
          <pdl-heading :level="3" qaid="warranty-search-header">
            {{ $t('warrantyClaims.B2B.searchClaims') }}
          </pdl-heading>
        </template>
      </pdl-section-header>

      <div class="grid lg:grid-cols-3 gap-x-8">
        <form-group name="warrantySearchDate" qaid="warranty-date-range-label" :label="$t('text.dateRange')">
          <template #input>
            <pdl-date-picker
              ref="dateRangePicker"
              mode="range"
              :default-date="urlParamDefaultDate"
              :allow-input="true"
              qaid="warranty-search-date"
              class="mb-0"
              @change="dateFilterInput"
            />
          </template>
        </form-group>
        <div class="form-group">
          <label class="form-label">{{ $t('warrantyClaims.B2B.claimNumber') }}</label>
          <input
            v-model="options.externalClaimNumber"
            type="text"
            class="form-control"
            :maxlength="15"
            qaid="warranty-search-claim-number"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('text.poNumber') }}</label>
          <input
            v-model="options.poNumber"
            type="text"
            class="form-control"
            :maxlength="30"
            qaid="warranty-search-po-number"
          />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('product.variants.sku') }}</label>
          <input v-model="options.sku" type="text" class="form-control" :maxlength="30" qaid="warranty-search-sku" />
        </div>
        <div class="form-group">
          <label class="form-label">{{ $t('productRegistration.serialNumber') }}</label>
          <input
            v-model="options.serialNumber"
            type="text"
            class="form-control"
            :maxlength="30"
            qaid="warranty-search-serial-number"
          />
        </div>
        <div class="form-group lg:col-span-3">
          <label class="checkbox">
            <input v-model="options.createdByMe" type="checkbox" qaid="warranty-view-only-checkbox" />
            <span class="control-indicator"></span>
            {{ $t('warrantyClaims.B2B.viewOnly') }}
          </label>
        </div>
      </div>

      <div class="buttons">
        <trek-button primary qaid="warranty-search-button" @click="fetchAllClaims">
          <span>{{ $t('text.search') }}</span>
        </trek-button>
        <trek-button secondary qaid="warranty-search-clear-filters" @click="clearFilters">
          <span>{{ $t('text.clearFilters') }}</span>
        </trek-button>
      </div>
    </section>

    <section class="mt-4 relative">
      <pdl-tabs v-model="tabSelected">
        <pdl-tab-pane v-for="tab in tabList" :key="tab.label" :label="tab.label" :qaid="tab.qaid" :name="tab.type">
          <warranty-grid
            :caption="tab.label"
            :size="pageSize"
            :loading="isLoadingClaims"
            :grid-data="tab.grid"
            :grid-type="tab.type"
            @delete-claim="openConfirmDeleteDialog"
            @page-change="fetchSingleTypeClaims(tabSelected, $event.pageNumber)"
          />
        </pdl-tab-pane>
      </pdl-tabs>
    </section>

    <pdl-dialog
      :visible.sync="dialogVisible"
      :title="$t('warrantyClaims.B2B.deleteConfirmCheck')"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      :z-index="16000011"
      qaid="delete-claim-dialog"
      @close="resetDeleteData"
    >
      <div qaid="warranty-confirm-delete-description">
        {{ $t('warrantyClaims.B2B.deleteConfirmCheck.description') }}
      </div>

      <template slot="footer">
        <div class="buttons buttons--right">
          <trek-button alert qaid="warranty-confirm-delete-button" @click="confirmDelete">
            <span>{{ $t('warrantyClaims.B2B.deleteConfirm') }}</span>
          </trek-button>
          <trek-button secondary qaid="warranty-cancel-delete-button" @click="dialogVisible = false">
            <span>{{ $t('text.cancel') }}</span>
          </trek-button>
        </div>
      </template>
    </pdl-dialog>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlTabs, PdlTabPane} from '@pedal/pdl-tabs';
import WarrantyGrid from '@/components/containers/retailer/WarrantyGrid';
import {PdlDatePicker} from '@pedal/pdl-date-picker';
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';
import {mapState, mapActions} from 'vuex';
import {Url} from '@/utils/url';
import FormGroup from '@/components/FormGroup';
import {PdlToastType} from '@/constants/pdl-toast-type';
import {DefaultGridData} from '@/constants/warranty-claims';
import {PdlSectionHeader, PdlHeading, PdlSubheading} from '@pedal/pdl-section-header';
import dayjs from 'dayjs';
import {DateFormats} from '@/constants/date-formats';
import {fetchClaims, deleteClaims} from '@/api/warranty-claims.js';
import {PdlIcon} from '@pedal/pdl-icon';

const DEFAULT_OPTIONS = {
  createdByMe: false,
  startDate: null,
  endDate: null,
  externalClaimNumber: null,
  poNumber: null,
  sku: null,
  serialNumber: null,
};

const GRID_TYPES = Object.freeze({
  SAVED: 'enumClaimStatusSaved',
  ACTION_REQUIRED: 'enumClaimStatusActionRequired',
  SUBMITTED: 'enumClaimStatusSubmitted',
  COMPLETED: 'enumClaimStatusCompleted',
});

export default {
  components: {
    PdlDialog,
    PdlTabs,
    PdlTabPane,
    WarrantyGrid,
    PdlDatePicker,
    TrekButton,
    TrekLink,
    FormGroup,
    PdlSectionHeader,
    PdlHeading,
    PdlSubheading,
    PdlIcon,
  },
  props: {
    isFeedbackActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialogVisible: false,
      enumClaimStatusSaved: DefaultGridData,
      enumClaimStatusActionRequired: DefaultGridData,
      enumClaimStatusSubmitted: DefaultGridData,
      enumClaimStatusCompleted: DefaultGridData,
      claimToBeDeleted: DefaultGridData,
      pageSize: 25,
      contextPath: ACC.config.encodedContextPath || '',
      isLoadingClaims: false,
      options: {...DEFAULT_OPTIONS},
      tabSelected: GRID_TYPES.SAVED,
    };
  },

  computed: {
    urlParamDefaultDate() {
      let urlParamDate;
      if (this.options.startDate && this.options.endDate) {
        urlParamDate = [new Date(this.options.startDate), new Date(this.options.endDate)];
      } else {
        urlParamDate = null;
      }
      return urlParamDate;
    },

    tabList() {
      return [
        {
          label: this.$t('warrantyClaims.B2B.temporaryClaims'),
          type: GRID_TYPES.SAVED,
          grid: this.enumClaimStatusSaved,
          qaid: 'warranty-tab-temporary',
        },
        {
          label: this.$t('text.actionRequired'),
          type: GRID_TYPES.ACTION_REQUIRED,
          grid: this.enumClaimStatusActionRequired,
        },
        {
          label: this.$t('warrantyClaims.B2B.activeClaims'),
          type: GRID_TYPES.SUBMITTED,
          grid: this.enumClaimStatusSubmitted,
        },
        {
          label: this.$t('warrantyClaims.B2B.resolvedClaims'),
          type: GRID_TYPES.COMPLETED,
          grid: this.enumClaimStatusCompleted,
        },
      ];
    },

    ...mapState('storage', ['feedbackMsg']),
  },

  mounted() {
    if (this.feedbackMsg) {
      this.$notify({
        type: PdlToastType.SUCCESS,
        message: this.feedbackMsg,
      });
      this.setFeedbackMsg('');
    }
  },

  created() {
    this.setFiltersFromUrlParams();
    this.fetchAllClaims();
  },

  methods: {
    ...mapActions('storage', ['setFeedbackMsg']),
    fetchClaims(gridType, page = 0) {
      const params = {
        pageIndex: page,
        pageSize: this.pageSize,
        startDate: isEmpty(this.options.startDate) ? null : this.options.startDate,
        endDate: isEmpty(this.options.endDate) ? null : this.options.endDate,
        externalClaimNumber: isEmpty(this.options.externalClaimNumber) ? null : this.options.externalClaimNumber,
        poNumber: isEmpty(this.options.poNumber) ? null : this.options.poNumber,
        createdByMe: this.options.createdByMe ? true : null,
        sku: isEmpty(this.options.sku) ? null : this.options.sku,
        serialNumber: isEmpty(this.options.serialNumber) ? null : this.options.serialNumber,
      };

      return fetchClaims(gridType, params).then((data) => {
        this[gridType] = data;
      });
    },

    async fetchSingleTypeClaims(gridType, page = 0) {
      this.isLoadingClaims = true;
      try {
        await this.fetchClaims(gridType, page);
      } catch (error) {
        console.log(error);
      }
      this.isLoadingClaims = false;
    },

    async fetchAllClaims() {
      Url.removeUrlParameterObjectFromUrl(this.options);
      Url.appendObjectToUrlParameters(this.options);
      this.isLoadingClaims = true;
      try {
        await Promise.all(this.tabList.map((tab) => this.fetchClaims(tab.type)));
        const isTabEmpty = this[this.tabSelected]?.entries.length === 0;
        if (isTabEmpty) {
          this.tabSelected = this.tabList.find((tab) => tab.grid.entries.length)?.type || this.tabSelected;
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoadingClaims = false;
    },

    dateFilterInput({startDate, endDate}) {
      if (startDate) {
        this.options.startDate = dayjs(startDate).format(DateFormats.MM_DD_YYYY);
      } else {
        this.options.startDate = null;
      }
      if (endDate) {
        this.options.endDate = dayjs(endDate).format(DateFormats.MM_DD_YYYY);
      } else {
        this.options.endDate = null;
      }
    },

    clearFilters() {
      Url.removeUrlParameterObjectFromUrl(this.options);

      this.options = {};
      this.$refs.dateRangePicker.fp.clear();
      this.fetchAllClaims();
    },

    openConfirmDeleteDialog(payload) {
      this.dialogVisible = true;
      this.claimToBeDeleted = payload;
    },

    // This is called when the confirmation dialog closes
    resetDeleteData() {
      this.dialogVisible = false;
      this.claimToBeDeleted = null;
    },

    confirmDelete() {
      this.dialogVisible = false;

      deleteClaims(this.claimToBeDeleted).then(() => {
        this.fetchSingleTypeClaims(this.tabSelected);
      });
    },
    setFiltersFromUrlParams() {
      this.urlParams = Url.parse(window.location.search);
      for (const urlParameter in this.urlParams) {
        for (const filterOption in this.options) {
          if (urlParameter === filterOption && this.urlParams[urlParameter] !== null) {
            //url only uses strings, so we need to convert string booleans back to booleans
            if (this.urlParams[urlParameter] === 'true') {
              this.urlParams[urlParameter] = true;
            } else if (this.urlParams[urlParameter] === 'false') {
              this.urlParams[urlParameter] = false;
            }
            this.options[urlParameter] = this.urlParams[urlParameter];
          }
        }
      }
    },
  },
};
</script>

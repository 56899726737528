<template>
  <div v-if="listData.length > 0">
    <table class="b2b-grid b2b-grid--collapse is-compact" qaid="backorder-allocated-saved-list-sku-grid">
      <thead class="b2b-grid__header">
        <!-- Each Header -->
        <tr>
          <th class="b2b-grid__cell b2b-grid__head" scope="col" qaid="backorder-allocated-eta-grid-header.checkbox">
            <label class="checkbox">
              <input
                id="backorder-allocated-eta-settings-select-all"
                v-model="selectAllCheckboxes"
                type="checkbox"
                @change="toggleSelectAll"
              />
              <span class="control-indicator"></span>
            </label>
          </th>
          <th class="b2b-grid__cell b2b-grid__head" scope="col" qaid="backorder-allocated-eta-grid-header.sku">
            {{ $t('text.account.savedLists.sku') }}
          </th>
          <th
            class="b2b-grid__cell b2b-grid__head"
            scope="col"
            qaid="backorder-allocated-eta-grid-header.line-note"
          ></th>
          <th class="b2b-grid__cell b2b-grid__head" scope="col" qaid="backorder-allocated-eta-grid-header.item">
            {{ $t('productCompare.description') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head" scope="col" qaid="backorder-allocated-eta-grid-header.order-number">
            {{ $t('text.account.orderHistory.mobile.page.sort.byOrderNumber') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head" scope="col" qaid="backorder-allocated-eta-grid-header.customer-po">
            {{ $t('text.poNumber') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head" scope="col" qaid="backorder-allocated-eta-grid-header.upc">
            {{ $t('text.account.savedLists.upc') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head" scope="col" qaid="backorder-allocated-eta-grid-header.warehouse">
            {{ $t('text.warehouse') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head" scope="col" qaid="backorder-allocated-eta-grid-header.original-eta">
            {{ $t('text.originalETA') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head" scope="col" qaid="backorder-allocated-eta-grid-header.current-eta">
            {{ $t('text.currentETA') }}
          </th>
          <th
            class="b2b-grid__cell b2b-grid__head whitespace-nowrap"
            scope="col"
            qaid="backorder-allocated-eta-grid-header.stockwatch"
          >
            {{ $t('text.etaWatch') }}
          </th>

          <th class="b2b-grid__cell b2b-grid__head" scope="col" qaid="backorder-allocated-eta-grid-header.price">
            {{ $t('basket.page.price') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(item, itemIndex) in paginatedData">
          <tr :key="item.id">
            <td class="b2b-grid__cell lg:table-cell">
              <label class="checkbox">
                <input
                  :id="`item-${itemIndex}`"
                  v-model="item.checkboxActive"
                  type="checkbox"
                  :disabled="!item.etaWatchPk"
                />
                <span class="control-indicator"></span>
              </label>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="backorder-allocated-eta-grid.sku">
              <div class="b2b-grid__label">{{ $t('product.variants.sku') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.product.code}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="backorder-allocated-eta-grid.line-note">
              <div class="b2b-grid__label"></div>
              <div class="b2b-grid__content">
                <grid-note
                  v-if="!item.isConsumer"
                  :data="{
                    value: 'note_add',
                    editable: true,
                    content: item.lineComment ? item.lineComment : '',
                    hasNotification: item.lineComment != null && item.lineComment.length,
                  }"
                  :item-id="`item-${itemIndex}`"
                  :max-length="120"
                  @note-added="addNote(item, $event)"
                />
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="backorder-allocated-eta-grid.itemname">
              <div class="b2b-grid__label">{{ $t('productCompare.description') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.product.itemName}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="backorder-allocated-eta-grid.order-number">
              <div class="b2b-grid__label">{{ $t('text.account.orderHistory.orderNumber') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.orderNumber}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="backorder-allocated-eta-grid.customer-po">
              <div class="b2b-grid__label">{{ $t('text.poNumber') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.customerPo}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="backorder-allocated-eta-grid.upc">
              <div class="b2b-grid__label">{{ $t('text.account.savedLists.upc') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.product.upc}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="backorder-allocated-eta-grid.warehouse">
              <div class="b2b-grid__label">{{ $t('text.warehouse') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.warehouse}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="backorder-allocated-eta-grid.original-eta">
              <div class="b2b-grid__label">{{ $t('text.originalETA') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.etaDate}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="backorder-allocated-eta-grid.estimated-ship-date">
              <div class="b2b-grid__label">{{ $t('text.etaDate') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.estimatedShipDate}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="backorder-allocated-eta-grid.stockwatch">
              <div class="b2b-grid__label">{{ $t('text.account.savedLists.stockWatch') }}</div>
              <div class="b2b-grid__content">
                <grid-eta-watch :eta-item="item" />
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="backorder-allocated-eta-grid.price">
              <div class="b2b-grid__label">{{ $t('basket.page.price') }}</div>
              <div class="b2b-grid__content">
                <grid-price :data="{dealerCost: item.basePrice.formattedValue}"></grid-price>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <div class="md:flex md:justify-end">
      <div class="md:flex md:flex-grow text-center">
        <pagination-controls
          v-if="pageCount > 1"
          ref="pagination"
          qaid="backorder-allocated-eta-grid.pagination"
          :page-number="pageNumber"
          :page-count="pageCount"
          @page-change="pageChange"
          @prev-page="prevPage"
          @next-page="nextPage"
        ></pagination-controls>
      </div>

      <div class="buttons buttons--right">
        <trek-link id="manage-allocated-items-link" button primary :href="`${encodedContextPath}/checkout/`">
          <span>{{ $t('text.manageAllocatedItems') }}</span>
        </trek-link>
        <trek-button
          primary
          qaid="backorder-allocated-eta-grid.removebutton"
          :disabled="selectedSkus.length === 0"
          @click="openDialog"
        >
          <span>{{ $t('notifications.B2B.removeWatch') }}</span>
        </trek-button>
      </div>
    </div>

    <pdl-dialog
      :visible.sync="dialogVisible"
      :title="$t('notifications.B2B.delete.check')"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      :z-index="16000011"
      qaid="backorder-allocated-remove-eta-watch-dialog"
      @close="closeDialog"
    >
      <template slot="footer">
        <div class="buttons buttons--right">
          <trek-button alert qaid="backorder-allocated-eta-grid.delete.yes" @click="confirmDelete">
            <span>{{ $t('notifications.B2B.delete.yesDelete') }}</span>
          </trek-button>
          <trek-button secondary qaid="backorder-allocated-eta-grid.delete.no" @click="closeDialog">
            <span>{{ $t('notifications.B2B.delete.noSave') }}</span>
          </trek-button>
        </div>
      </template>
    </pdl-dialog>
  </div>
  <div v-else>
    <pdl-callout>
      <template #content>
        <span v-html="noItem" />
      </template>
    </pdl-callout>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import pagination from '../../../mixins/pagination';
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlCallout} from '@pedal/pdl-callout';
import GridText from '@/components/grid/GridText';
import GridPrice from '@/components/grid/GridPrice';
import PaginationControls from '@/components/PaginationControls';
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';
import GridNote from '@/components/grid/GridNote';
import GridEtaWatch from '@/components/grid/GridEtaWatch';
import {MetaFeedbackType} from '@/constants/meta-feedback-type';
import {PdlToastType} from '@/constants/pdl-toast-type';
import OrderItemApi from '@/api/order-item-api.js';

export default {
  components: {
    PdlDialog,
    GridText,
    PdlCallout,
    GridPrice,
    PaginationControls,
    TrekButton,
    TrekLink,
    GridNote,
    GridEtaWatch,
  },
  mixins: [pagination],
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    size: {
      type: Number,
      required: false,
      default: 15,
    },
    noItem: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selectAllCheckboxes: false,
      dialogVisible: false,
    };
  },
  computed: {
    listData() {
      return this.items;
    },
    selectedSkus() {
      return this.items.filter((item) => item.checkboxActive === true && item.etaWatchPk);
    },
    ...mapState('backend', ['encodedContextPath']),
  },
  methods: {
    async addNote(item, note) {
      const payload = {
        orderLineNumber: item.orderLineNumber,
        orderNumber: item.orderNumber,
        orderType: item.orderType,
        orderCompany: item.orderCompany,
        lineComment: note.content,
      };

      try {
        const newNoteContent = await OrderItemApi.updateComment(payload);
        this.$set(item, 'lineComment', newNoteContent);
      } catch (error) {
        console.log(error);
      }
    },
    toggleSelectAll(event) {
      this.items.map((item) => {
        item.checkboxActive = event.target.checked;
        return item;
      });
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    confirmDelete() {
      this.dialogVisible = false;
      let successCallCounter = 0,
        failureCallCounter = 0,
        totalCallsToMake = this.selectedSkus.length;

      this.selectedSkus.map((mappedItem) => {
        OrderItemApi.deleteEtaWatch(mappedItem.etaWatchPk)
          .then(
            ({
              data: {
                meta: {feedback},
              },
            }) => {
              if (feedback.type === MetaFeedbackType.SUCCESS) {
                successCallCounter++;
              } else {
                failureCallCounter++;
              }

              /* NOTE: Show success message when all selected items have successfully updated */
              if (successCallCounter === totalCallsToMake) {
                this.$emit('update-data-list');
                this.$notify({
                  type: PdlToastType.SUCCESS,
                  message: this.$t('notifications.B2B.delete.confirmation'),
                });
                this.selectAllCheckboxes = false;
              } else if (successCallCounter + failureCallCounter === totalCallsToMake) {
                /* NOTE: Just update the table when at least one item failed to be updated */

                this.$emit('update-data-list');
                this.selectAllCheckboxes = false;
              }
            }
          )
          .catch((error) => console.log(error));
      });
    },
  },
};
</script>

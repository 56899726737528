<script>
import {PdlDialog} from '@pedal/pdl-dialog';
import {PdlButton} from '@pedal/pdl-button';
import FindRetailerDialog from '@/components/containers/my-account/FindRetailerDialog.vue';

export default {
  /* eslint-disable vue/no-unused-components */
  components: {
    PdlDialog,
    PdlButton,
    FindRetailerDialog,
  },
  data() {
    return {
      hasFindRetailerDialogVisible: false,
      customerPreferredRetailer: null,
    };
  },
  mounted() {
    this.setPreferredRetailer();
  },
  methods: {
    openFindRetailerDialog() {
      this.hasFindRetailerDialogVisible = true;
    },
    closeFindRetailerDialog() {
      this.hasFindRetailerDialogVisible = false;
      this.setPreferredRetailer();
    },
    setPreferredRetailer(preferredRetailer) {
      this.customerPreferredRetailer = preferredRetailer;
    },
  },
};
</script>

<template>
  <div :class="`w-full relative ${containerClasses}`">
    <input
      :id="inputId"
      ref="autocompleteInput"
      v-model="inputValue"
      data-input-autocomplete
      :qaid="`input-autocomplete ${inputQaid}`"
      :placeholder="inputPlaceholder"
      type="text"
      :class="inputClasses"
      :name="inputName"
      :required="inputRequired"
      :maxlength="inputMaxLength"
      class="mb-0"
      autocomplete="off"
      :disabled="disabled"
      @keyup.enter="emitEnterSearchEvent"
      @blur="hideDropdown"
      @focus="showDropdown"
    />
    <ul v-show="isShowingSuggestionList" class="suggestion-list" qaid="input-autocomplete-list">
      <li
        v-for="(suggestion, index) in suggestionList"
        :key="suggestion.id"
        :qaid="`${suggestion.qaid ? suggestion.qaid : 'suggestion'}-${index}`"
        @click="selectSuggestion(suggestion)"
      >
        {{ suggestion.value }}
      </li>
      <!-- For Use with MapboxAddressSuggestionInput.vue. -->
      <li
        v-if="isAutoLocationSupported && !isGeolocationQueryScheduled"
        class="auto-location flex justify-between"
        qaid="store-locator__search__auto-location"
        @click="setNearValue"
      >
        <span>{{ $t('storeFinder.v2.useLocation') }}</span>
        <pdl-icon :class="{processing: isAutoDetectingLocation}" name="near_me" />
      </li>
    </ul>
  </div>
</template>
<script>
import {PdlIcon} from '@pedal/pdl-icon';
export default {
  components: {PdlIcon},

  props: {
    inputClasses: {
      type: String,
      default: '',
    },
    containerClasses: {
      type: String,
      default: '',
    },
    inputId: {
      type: String,
      default: '',
    },
    inputQaid: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      default: 'address',
    },
    inputRequired: {
      type: String,
      default: null,
    },
    inputPlaceholder: {
      type: String,
      default: '',
    },
    inputMaxLength: {
      type: Number,
      default: 150,
    },
    suggestionList: {
      type: Array,
      default: () => [],
    },
    initialValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    forceSuggestionSelection: {
      type: Boolean,
      default: false,
    },
    nearLocationValue: {
      type: String,
      default: '',
    },
    isAutoLocationSupported: {
      type: Boolean,
      default: false,
    },
    isAutoDetectingLocation: {
      type: Boolean,
      default: false,
    },
    queryGeolocation: {
      type: Function,
      default: null,
    },
    isGeolocationQueryScheduled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: '',
      hasSuggestionDropdownOpen: false,
      isSelecting: false,
      selectedSuggestion: undefined,
    };
  },

  computed: {
    isShowingSuggestionList() {
      return this.suggestionList && this.hasSuggestionDropdownOpen;
    },
  },
  watch: {
    initialValue(newValue) {
      this.inputValue = newValue;
    },
    inputValue(newValue) {
      if (this.isSelecting) return;
      this.$emit('input-updated', {inputValue: newValue, data: {}, isSearching: false});
    },
  },
  created() {
    if (this.initialValue) {
      const initialSuggestion = {
        value: this.initialValue,
        data: {},
      };
      this.selectedSuggestion = initialSuggestion;
      this.selectSuggestion(this.selectedSuggestion);
    }
  },
  methods: {
    emitSearchEvent(suggestion) {
      this.$refs?.autocompleteInput?.blur();
      this.$emit('input-updated', {inputValue: suggestion.value, data: suggestion.data, isSearching: true});
    },
    emitEnterSearchEvent() {
      this.$refs?.autocompleteInput?.blur();
      this.$emit('input-updated', {inputValue: this.inputValue, data: {}, isSearching: true});
    },
    selectSuggestion(suggestion) {
      this.isSelecting = true;
      this.inputValue = suggestion.value;
      this.selectedSuggestion = {
        value: suggestion.value,
        data: suggestion.data,
      };
      this.emitSearchEvent(this.selectedSuggestion);
      this.isSelecting = false;
    },
    hideDropdown() {
      const DELAY_FOR_SUGGESTION_CLICK = 200;
      setTimeout(() => {
        this.hasSuggestionDropdownOpen = false;
        if (this.forceSuggestionSelection) {
          this.enforceSuggestionListSelection();
        }
      }, DELAY_FOR_SUGGESTION_CLICK);
    },

    setNearValue() {
      this.inputValue = this.nearLocationValue;
      this.queryGeolocation();
    },

    showDropdown() {
      this.hasSuggestionDropdownOpen = true;
    },

    async enforceSuggestionListSelection() {
      await this.$nextTick();
      const isIssueFromSuggestionList = this.suggestionList.findIndex((issue) => issue.value === this.inputValue) > -1;
      if (isIssueFromSuggestionList) {
        this.$emit('valid-selection', this.inputValue);
      } else {
        this.inputValue = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.suggestion-list {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 3;

  li {
    $border-style: 1px solid var(--gray-20);

    border-left: $border-style;
    border-right: $border-style;
    border-bottom: $border-style;
    background: var(--white);
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    cursor: pointer;

    &:first-child {
      border-top: $border-style;
    }

    &:hover {
      color: var(--white);
      background: var(--blue-100);
      border-color: var(--blue-100);
    }
  }
}
</style>

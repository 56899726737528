<template>
  <div v-if="listData.length > 0">
    <table class="b2b-grid b2b-grid--collapse is-compact" qaid="saved-list-sku-grid">
      <thead class="b2b-grid__header">
        <!-- Each Header -->
        <tr>
          <th class="b2b-grid__cell b2b-grid__head" qaid="notification-grid-header.checkbox">
            <label class="checkbox">
              <input
                id="notification-settings-select-all"
                v-model="selectAllCheckboxes"
                type="checkbox"
                @change="toggleSelectAll"
              />
              <span class="control-indicator"></span>
            </label>
          </th>
          <th class="b2b-grid__cell b2b-grid__head uppercase" qaid="notification-grid-header.sku">
            {{ $t('text.account.savedLists.sku') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head uppercase" qaid="notification-grid-header.item">
            {{ $t('productCompare.description') }}
          </th>
          <th class="b2b-grid__cell b2b-grid__head uppercase" qaid="notification-grid-header.upc">
            {{ $t('text.account.savedLists.upc') }}
          </th>

          <th
            v-for="(warehouse, warehouseKey) in warehouseList"
            :key="warehouseKey"
            class="b2b-grid__cell b2b-grid__head uppercase"
            data-type="warehouse"
            qaid="notification-grid-header.warehouse"
          >
            {{ warehouse }}
          </th>
          <th
            class="b2b-grid__cell b2b-grid__head uppercase whitespace-nowrap"
            data-type="stock-watch"
            qaid="notification-grid-header.stockwatch"
          >
            {{ $t('text.account.savedLists.stockWatch') }}
          </th>

          <th class="b2b-grid__cell b2b-grid__head uppercase" qaid="notification-grid-header.price">
            {{ $t('order.price') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(item, itemIndex) in paginatedData">
          <tr :key="item.id">
            <td class="b2b-grid__cell lg:table-cell">
              <label class="checkbox">
                <input :id="`item-${itemIndex}`" v-model="item.active" type="checkbox" />
                <span class="control-indicator"></span>
              </label>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="notification-grid.sku">
              <div class="b2b-grid__label uppercase">{{ $t('text.account.savedLists.sku') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.sku}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="notification-grid.itemname">
              <div class="b2b-grid__label uppercase">{{ $t('productCompare.description') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.itemName}"></grid-text>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="notification-grid.upc">
              <div class="b2b-grid__label uppercase">{{ $t('text.account.savedLists.upc') }}</div>
              <div class="b2b-grid__content">
                <grid-text :data="{value: item.upc}"></grid-text>
              </div>
            </td>
            <template v-if="btoLeadTime(item)">
              <td
                :colspan="warehouseList.length"
                class="b2b-grid__cell lg:table-cell"
                qaid="notification-grid.warehouses"
              >
                <div class="b2b-grid__label uppercase">
                  {{ item.warehouses && item.warehouses.length ? item.warehouses[0].id : '' }}
                </div>
                <div
                  class="b2b-grid__content text-center"
                  qaid="notification-grid.btoLeadTime"
                  v-html="btoLeadTime(item)"
                ></div>
              </td>
            </template>
            <template v-else>
              <td
                v-for="(warehouse, warehouseKey) in getWarehouses(item)"
                :key="warehouseKey"
                class="b2b-grid__cell lg:table-cell"
                qaid="notification-grid.warehouses"
              >
                <div class="b2b-grid__label uppercase">{{ warehouse.id }}</div>
                <div class="b2b-grid__content">
                  <grid-warehouse :warehouse="warehouse"></grid-warehouse>
                </div>
              </td>
            </template>
            <td class="b2b-grid__cell lg:table-cell" qaid="notification-grid.stockwatch">
              <div class="b2b-grid__label uppercase">{{ $t('text.account.savedLists.stockWatch') }}</div>
              <div class="b2b-grid__content">
                <grid-stock-watch
                  :is-for-notifications="true"
                  :warehouses="getWarehouseStockWatchOptions(item.stockWatch, paginatedData)"
                  :product-index="item.sku"
                  @update-stock-watch-state="updateStockWatch"
                ></grid-stock-watch>
              </div>
            </td>
            <td class="b2b-grid__cell lg:table-cell" qaid="notification-grid.price">
              <div class="b2b-grid__label uppercase">{{ $t('order.price') }}</div>
              <div class="b2b-grid__content">
                <grid-price :data="{dealerCost: item.price.dealerCost, discount: item.price.sale}"></grid-price>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <div class="md:flex md:justify-end">
      <div class="md:flex md:flex-grow text-center">
        <pagination-controls
          v-if="pageCount > 1"
          ref="pagination"
          qaid="notification-grid.pagination"
          :page-number="pageNumber"
          :page-count="pageCount"
          @page-change="pageChange"
          @prev-page="prevPage"
          @next-page="nextPage"
        ></pagination-controls>
      </div>

      <div class="buttons buttons--right">
        <trek-button
          primary
          qaid="notification-grid.removebutton"
          :disabled="selectedSkus.length === 0"
          @click="openDialog"
        >
          <span v-html="$t('notifications.B2B.removeWatch')"></span>
        </trek-button>
      </div>
    </div>

    <pdl-dialog
      :visible.sync="dialogVisible"
      :title="$t('notifications.B2B.delete.check')"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      :z-index="16000011"
      qaid="notification-remove-watch-dialog"
      @close="closeDialog"
    >
      <template slot="footer">
        <div class="buttons buttons--right">
          <trek-button alert qaid="notification-grid.delete.yes" @click="confirmDelete">
            <span v-html="$t('notifications.B2B.delete.yesDelete')"></span>
          </trek-button>
          <trek-button secondary qaid="notification-grid.delete.no" @click="closeDialog">
            <span v-html="$t('notifications.B2B.delete.noSave')"></span>
          </trek-button>
        </div>
      </template>
    </pdl-dialog>
  </div>

  <div v-else>
    <pdl-callout>
      <template #content>
        <span v-html="noItem" />
      </template>
    </pdl-callout>
  </div>
</template>

<script>
import get from 'lodash/get';
import find from 'lodash/find';
import pagination from '../../../mixins/pagination';
import warehouseMixin from '../../../mixins/warehouse';
import {PdlDialog} from '@pedal/pdl-dialog';
import storefrontInstance from '@/api/instances/storefront';
import {PdlCallout} from '@pedal/pdl-callout';
import GridText from '@/components/grid/GridText';
import GridWarehouse from '@/components/grid/GridWarehouse';
import GridStockWatch from '@/components/grid/GridStockWatch';
import GridPrice from '@/components/grid/GridPrice';
import PaginationControls from '@/components/PaginationControls';
import TrekButton from '@/components/TrekButton';
import {checkMetaFeedbackType, MetaFeedbackType} from '@/constants/meta-feedback-type';
import {PdlToastType} from '@/constants/pdl-toast-type';

export default {
  components: {
    PdlDialog,
    PdlCallout,
    GridText,
    GridWarehouse,
    GridStockWatch,
    GridPrice,
    PaginationControls,
    TrekButton,
  },
  mixins: [pagination, warehouseMixin],
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    size: {
      type: Number,
      required: false,
      default: 15,
    },
    noItem: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selectAllCheckboxes: false,
      dialogVisible: false,
    };
  },

  computed: {
    listData() {
      return this.items;
    },
    warehouseList() {
      return this.getUniqueWarehouseCodesFromAllProductVariants(this.items);
    },
    stockwatchList() {
      return get(this.items[0], 'stockWatch', []).map((x) => x.id);
    },
    selectedSkus() {
      return this.items.filter((item) => item.active === true);
    },
  },
  methods: {
    btoLeadTime(item) {
      let btoLeadTime = this.$t('skuGrid.B2B.leadTime');

      if (item.btoLeadTime && btoLeadTime && btoLeadTime.length) {
        return btoLeadTime.replace('{0}', item.btoLeadTime);
      }

      return false;
    },
    getWarehouses(product) {
      return this.fillWarehousesByCodes(this.warehouseList, product.warehouses);
    },
    toggleSelectAll(event) {
      this.items.map((item) => {
        item.active = event.target.checked;
        return item;
      });
    },
    updateStockWatch({item, productIndex}) {
      let warehouseToUpdate = find(this.paginatedData, (item) => item.sku === productIndex);
      warehouseToUpdate.stockWatch = item;
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    confirmDelete() {
      this.dialogVisible = false;
      let successCallCounter = 0,
        failureCallCounter = 0,
        totalCallsToMake = this.stockwatchList.length * this.selectedSkus.length;

      this.selectedSkus.map((mappeditem) => {
        let itemSku = mappeditem.sku;
        this.stockwatchList.map((stockwatchItem) => {
          storefrontInstance
            .put('/notification-settings/stock-watch', {
              stockWatchProduct: itemSku,
              stockWatchWarehouse: stockwatchItem,
              stockWatchActive: false,
            })
            .then((response) => {
              if (checkMetaFeedbackType(response.data, MetaFeedbackType.SUCCESS)) {
                successCallCounter++;
              } else {
                failureCallCounter++;
              }

              /* NOTE: Show success message when all stockwatches per SKU have been updated */
              if (successCallCounter === totalCallsToMake) {
                this.$emit('update-data-list');
                this.$notify({
                  type: PdlToastType.SUCCESS,
                  message: this.$t('notifications.B2B.delete.confirmation'),
                });
              } else if (successCallCounter + failureCallCounter === totalCallsToMake) {
                /* NOTE: Just update the table when at least one item failed to be updated */

                this.$emit('update-data-list');
              }
            })
            .catch((error) => console.log(error));
        });
      });
    },
    fillWarehouseColumns(itemWarehouses, currentWarehouse) {
      let filteredWarehouse = itemWarehouses.filter((item) => item.id === currentWarehouse);
      return filteredWarehouse[0] ? filteredWarehouse[0] : {};
    },
  },
};
</script>

<template>
  <label class="facet-item-color cursor-pointer flex flex-grow items-center" :for="itemId || item.id" :qaid="qaid">
    <pdl-swatch class="mr-2" :first-color="colors.firstColor" :second-color="colors.secondColor" />
    <em>{{ item.displayText }}</em>
  </label>
</template>
<script>
import FacetItemText from './FacetItemText';
import {PdlSwatch} from '@pedal/pdl-swatch';
import swatchMixin from '@/mixins/swatches.js';

export default {
  name: 'FacetItemColor',
  components: {PdlSwatch},
  extends: FacetItemText,
  mixins: [swatchMixin],
  computed: {
    colors() {
      const decodedColor = decodeURIComponent(this.item.value).split('|')[0];

      return {
        ...this.processColorCode(decodedColor),
      };
    },
  },
};
</script>

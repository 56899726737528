<template>
  <label :for="itemId || item.id" class="facet-item-text cursor-pointer flex-grow pl-1" :qaid="qaid">
    <em>{{ item.displayText }}</em>
  </label>
</template>
<script>
export default {
  name: 'FacetItemText',
  props: {
    itemId: {
      type: String,
      default: '',
    },
    qaid: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

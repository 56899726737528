<template>
  <nav class="pagination__wrapper" qaid="pagination">
    <ul class="pagination__controls flex" :class="containerClass">
      <li :class="[itemClasses, {disabled: pageNumber === 0}]">
        <button
          class="button--icon-only"
          :class="[buttonClasses, {disabled: pageNumber === 0}]"
          qaid="pagination-previous-button"
          :disabled="pageNumber === 0"
          type="button"
          @click="pageChange(pageNumber - 1)"
        >
          <pdl-icon :name="prevIcon" />
          <span class="show-for-sr">Previous Page</span>
          <slot name="previous"></slot>
        </button>
      </li>
      <li v-if="hasFirst && displayPages" class="pagination__first" :class="[itemClasses]">
        <a class="page-link" :class="[buttonClasses, {active: isActive(0)}]" @click="pageChange(0)">1</a>
      </li>
      <li v-if="hasFirstEllipsis" class="pagination__item--ellipsis" :class="[itemClasses]">
        <span class="pagination__ellipsis">&hellip;</span>
      </li>
      <template v-if="displayPages">
        <li v-for="page in pages" :key="page" :class="[itemClasses, {'hidden sm:flex': isPlusMinusOne(page)}]">
          <a
            class="pagination__link"
            :class="[
              buttonClasses,
              {
                active: isActive(page),
                disabled: page === '...',
              },
            ]"
            @click="pageChange(page - 1)"
            >{{ page }}</a
          >
        </li>
      </template>
      <li v-if="hasLastEllipsis && displayPages" class="pagination__item--ellipsis" :class="[itemClasses]">
        <span class="pagination__ellipsis">&hellip;</span>
      </li>
      <li v-if="hasLast && displayPages" class="pagination__last" :class="[itemClasses]">
        <a
          class="page-link"
          :class="[buttonClasses, {active: isActive(pageCount)}]"
          @click="pageChange(pageCount - 1)"
          >{{ pageCount }}</a
        >
      </li>
      <li :class="[itemClasses]">
        <button
          class="button--icon-only"
          :class="[buttonClasses, {disabled: pageNumber === pageCount - 1}]"
          qaid="pagination-next-button"
          :disabled="pageNumber === pageCount - 1"
          type="button"
          @click="pageChange(pageNumber + 1)"
        >
          <slot name="next"></slot>
          <pdl-icon :name="nextIcon" />
          <span class="show-for-sr">Next Page</span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {PdlIcon},
  props: {
    pageCount: {
      type: Number,
      default: 0,
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    containerClass: {
      type: String,
      default: '',
    },
    prevIcon: {
      type: String,
      default: 'arrow_back',
    },
    nextIcon: {
      type: String,
      default: 'arrow_forward',
    },
    ellipsisLimit: {
      type: Number,
      default: 6,
    },
    displayPages: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      itemClasses: 'pagination__item',
      buttonClasses: 'pager__button button button--primary button--clear button--skinny mb-0',
    };
  },

  computed: {
    hasFirst() {
      return true;
    },

    hasFirstEllipsis() {
      return this.pageNumber >= 3 && this.pageCount >= this.ellipsisLimit;
    },

    hasLastEllipsis() {
      return this.pageNumber <= this.pageCount - 3 && this.pageCount >= this.ellipsisLimit;
    },

    hasLast() {
      return this.pageNumber <= this.pageCount - 3 || this.pageCount <= this.ellipsisLimit;
    },

    pages() {
      return this.pageCount === undefined ? [] : this.pageLinks();
    },
  },

  methods: {
    isActive(page) {
      const currentPage = this.pageNumber || 0;
      return currentPage === Math.max(0, page - 1);
    },
    isPlusMinusOne(page) {
      const currentPage = this.pageNumber || 0;
      return currentPage === (page + 1 || page - 1);
    },
    prevPage() {
      this.$emit('prev-page');
    },
    nextPage() {
      this.$emit('next-page');
    },
    pageChange(num) {
      this.$emit('page-change', num);
    },
    pageLinks() {
      const pages = [];
      let left = 2;
      let right = this.pageCount - 1;

      if (this.pageCount >= this.ellipsisLimit) {
        left = Math.max(2, this.pageNumber);
        right = Math.min(this.pageNumber + 2, this.pageCount - 1);
      }

      for (let i = left; i <= right; i++) {
        pages.push(i);
      }

      return pages;
    },
  },
};
</script>

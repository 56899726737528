'use strict';
import '@/vendor/jquery.mask';
import {PdlToastType} from '@/constants/pdl-toast-type';
import {PaymentProvider} from '@/constants/payment-provider';

export default function () {
  $(document).delegate('#updateRetailerBtn', 'click', function () {
    var url = $('#prefferedRetailerId').val();
    if (url.indexOf('=') != -1) {
      var start = url.indexOf('=');
      var length = url.length;
      var store = url.substring(start + 1, length);
      ACC.track.trackPreferredRetailer(store);
    }

    $.ajax({
      url: url,
      type: 'POST',
      success: function () {
        $('#updateRetailerDiv').css('display', 'none');
      },
    });
  });
}

function disableOrderSubmitButton() {
  $('#orderSubmitButtonV2').addClass('disabled').prop('disabled', true);

  $('#page-loader').removeClass('hidden');
}

function enableOrderSubmitButton() {
  $('#orderSubmitButtonV2').removeClass('disabled').removeAttr('disabled');

  $('#page-loader').addClass('hidden');
}

//if cart has CPO/new bike combo during checkout process, redirect to /cart page
function checkForCPORedirectToCart(response) {
  if (response?.meta?.feedback?.link?.target) {
    const redirectUrl = `${window.ACC.config.encodedContextPath}${response.meta.feedback.link.target}`;
    window.location.href = redirectUrl;
  }
}

function isGr4vyPayment(data) {
  return data?.paymentProvider === PaymentProvider.GR4VY && data?.redirectUrl;
}

$(document).delegate('#placeOrderForm1', 'submit', function () {
  disableOrderSubmitButton();
});

function getDeviceCollectionIframeContents(jwt, url) {
  return `
         <!DOCTYPE html>
         <html>
           <head>
           </head>
           <body>
             <form id="collectionForm" name="devicedata" method="POST" action="${url}">
               <input type="hidden" name="JWT" value="${jwt}" />
             </form>
             <script>
               document.getElementById('collectionForm').submit();
             </script>
           </body>
         </html>
       `;
}

function payerAuthCompleteCheck(event) {
  // Check for payerAuthComplete event data message
  return (
    event.data &&
    event.data.messageType &&
    event.data.messageType === 'payerAuthComplete' &&
    event.origin === window.location.protocol + '//' + window.location.host
  );
}

/* This listens for the postMessage from the payerAuthComplete page which lets us
  know when to close the payerAuthIframe and submit the form to the validate endpoint */
window.addEventListener(
  'message',
  function (event) {
    if (payerAuthCompleteCheck(event)) {
      // close the modal & Iframe
      window.store.commit('dialogs/setPayerAuthDialog', false);
      // submit the form to the validate endpoint
      $('#placeOrderForm1').submit();
    }
  },
  false
);

function confirmationCompleteCheck(event) {
  // check for payerAuthComplete event data message
  return (
    event.data &&
    event.data.messageType &&
    event.data.messageType === 'confirmationValidationEvent' &&
    event.origin === window.location.protocol + '//' + window.location.host
  );
}

/* This listens for the postMessage from the confirmationValidationEvent page which lets us
  know when to close the payerAuthIframe and submit the form to the validate endpoint */
window.addEventListener(
  'message',
  function (event) {
    if (confirmationCompleteCheck(event)) {
      window.store.commit('dialogs/setPayerAuthDialog', false);
      disableOrderSubmitButton();

      // Allow for existing error handling on payer auth and cvv errors
      const errorRedirectUrl = event?.data?.errorRedirectUrl ?? '';
      if (errorRedirectUrl.length) {
        window.location.replace(window.ACC.config.encodedContextPath + errorRedirectUrl);
      }

      // Submit the form to the validate endpoint
      if (event.data.type !== 'false') {
        $.ajax({
          url: $('#placeOrderForm1').attr('action'),
          type: 'POST',
          data: $('#placeOrderForm1').serialize(),
          success: function (response) {
            if (response.data.htmlOfAutosubmitForm) {
              const wrapperSubmitForm = document.createElement('div');
              wrapperSubmitForm.innerHTML = response.data.htmlOfAutosubmitForm;
              const submitForm = wrapperSubmitForm.firstChild;
              document.body.append(submitForm);
              submitForm.submit();
            }
          },
          error: function (error) {
            window.store.commit('dialogs/setPayerAuthDialog', false);
            enableOrderSubmitButton();
            console.log(error);
          },
        });
      } else {
        enableOrderSubmitButton();
      }
    }
  },
  false
);

const init = () => {
  let cvvErrorToast = null;

  // Create payer auth device collection iframe
  const jwtHolder = $('#payerAuthDeviceAccessToken');
  const deviceCollectionUrl = $('#payerAuthDeviceCollectionURL');
  if (jwtHolder && jwtHolder.val()) {
    const deviceCollectionIframe = document.createElement('iframe');
    deviceCollectionIframe.width = 1;
    deviceCollectionIframe.height = 1;
    deviceCollectionIframe.style = 'display: none';
    document.body.append(deviceCollectionIframe);
    deviceCollectionIframe.contentDocument.write(
      getDeviceCollectionIframeContents(jwtHolder.val(), deviceCollectionUrl.val())
    );
  }

  function CheckoutCompleteOrderV2() {
    /**
     * Initialize form and process with next step.
     * @param htmlCode
     */
    this.init = async function (htmlCode) {
      const form = this.setupForm(htmlCode);
      if (!form) {
        throw new Error("Form doesn't exist");
      }

      if (form.classList.contains('iframe')) {
        await window.store.commit('dialogs/setPayerAuthDialog', true);
        this.initPayerAuthIframe(htmlCode);
      } else {
        form.submit();
      }
    };

    /**
     * Initialize payer authorization Iframe within dialog
     */
    this.initPayerAuthIframe = async function (htmlCode) {
      const $iframe = $('#payer-auth-iframe');
      await $iframe.contents().find('body').html(htmlCode);
      $iframe.contents().find('#step-up-form').attr('target', '').submit();
      enableOrderSubmitButton();
    };

    /**
     * Add form to DOM tree.
     * @param htmlCode
     * @return {ChildNode}
     */
    this.setupForm = function (htmlCode) {
      const wrapperSubmitForm = document.createElement('div');
      wrapperSubmitForm.innerHTML = htmlCode;

      const submitForm = wrapperSubmitForm.firstChild;
      document.body.append(submitForm);
      return submitForm;
    };
  }

  const placeOrderURL = window.ACC.config.encodedContextPath + '/checkout/multi/summary/v2/';
  const placeOrderSuccessResponseHandler = function (response) {
    const message = response?.meta?.feedback?.message ?? {};
    if (isGr4vyPayment(response.data)) {
      return location.assign(response.data.redirectUrl);
    }
    if (response?.data?.htmlOfAutosubmitForm) {
      const checkoutCompleteOrderV2 = new CheckoutCompleteOrderV2();
      try {
        checkoutCompleteOrderV2.init(response.data.htmlOfAutosubmitForm);
      } catch (err) {
        placeOrderErrorResponseHandler();
      }
    } else {
      checkForCPORedirectToCart(response);
      placeOrderValidationErrorResponseHandler({
        errors: [message],
      });
    }

    const errors = response?.data?.errorMessages ?? [];
    const hasCVVError = errors.includes('checkout.payment.cvv.error.message');
    const errorMessage = window.ACC?.orderConfirmation?.cvvError?.basePropertyValue ?? '';

    // Show toast & override error redirect specifically for cvv errors
    if (hasCVVError && errorMessage.length) {
      // Clear and populate the CVV field with empty value to trigger error styling
      clearCVV();
      populateCVV();

      // Only ever show one error at a time
      if (!$('[qaid="order-confirmation-cvv-error-toast"]').length) {
        // Preserve the toast instance to close it upon order submission
        cvvErrorToast = window.vm.toast({
          type: PdlToastType.ERROR,
          message: errorMessage,
          showClose: true,
          qaid: 'order-confirmation-cvv-error-toast',
          duration: 0,
        });
      }

      return;
    }

    // Handle enrollment check failure and redirect the user for anything else
    if (response?.data?.errorOccurred) {
      window.location.replace(
        window.ACC.config.encodedContextPath + response.data.htmlOfAutosubmitForm + response.data.errorMessages[0]
      );
    }
  };

  const populateCVV = () => {
    const errorClasses = 'form__item--error alert-icon-after';
    const cvv = $('#cvvNumber').val() ?? '';
    const hasCVV = !!cvv.length;

    if (hasCVV) {
      $('#placeOrderForm1 .cvv-number__input').val(cvv);
      $('.cvv_code--error').hide();

      $('#cvvNumber').parent().removeClass(errorClasses);
    } else {
      const errorMessage = window.ACC?.orderConfirmation?.formError;
      placeOrderValidationErrorResponseHandler({
        errors: [errorMessage],
      });
      $('.cvv_code--error').show();

      $('#cvvNumber').parent().addClass(errorClasses);
    }

    return hasCVV;
  };

  const clearCVV = () => {
    $('#cvvNumber').val('');
  };

  const placeOrderErrorResponseHandler = function () {
    enableOrderSubmitButton();
    clearCVV();
  };

  const placeOrderValidationErrorResponseHandler = function ({errors = []}) {
    const errorKeys = errors.map((e) => e.basePropertyKey);

    if (!errorKeys.includes('checkout.address.form.entry.invalid')) {
      // check cvv again
      const isCVVRequired = !!$('#cvvNumber').length;
      const hasCVV = isCVVRequired ? populateCVV() : true;

      if (!hasCVV) {
        errors.push({
          ...window.ACC.orderConfirmation.formError,
        });
      }
    }

    const errorMessages = errors.map((e) => e.basePropertyValue);
    const errorString = errorMessages.join('<br />');

    $('#termsErrorMessageGlobal')
      .html('<span>' + errorString + '</span>')
      .removeClass('hidden');

    enableOrderSubmitButton();
  };

  $('#orderSubmitButtonV2').click((e) => {
    e.preventDefault();

    const isCVVRequired = !!$('#cvvNumber').length;
    const hasCVV = isCVVRequired ? populateCVV() : true;

    if (hasCVV) {
      disableOrderSubmitButton();

      // Close the error toast if one is defined
      if (cvvErrorToast?.close) {
        cvvErrorToast.close();
      }

      $.ajax({
        url: placeOrderURL,
        type: 'POST',
        data: $('#placeOrderForm1').serialize(),
        success: placeOrderSuccessResponseHandler,
        error: placeOrderErrorResponseHandler,
      });
    }
  });
};

$(document).ready(function () {
  $('#cvvNumber').mask('0009', {
    clearIfNotMatch: true,
  });

  // Hide the existing error handling list specifically on a CVV rejection and display a toast instead
  const isSummaryPage = $('body').hasClass('page-multiStepCheckoutSummaryPage');
  const error = 'checkout.payment.cvv.error.message';

  if (window.location.href.indexOf(error) > -1) {
    const errorMessage = window.ACC?.orderConfirmation?.cvvError?.basePropertyValue ?? '';
    if (isSummaryPage && errorMessage.length) {
      $('.form__global-error').hide();

      window.vm.toast({
        type: PdlToastType.ERROR,
        message: errorMessage,
        showClose: true,
        qaid: 'order-confirmation-cvv-error-toast',
        duration: 0,
      });
    }
  }

  init();
});

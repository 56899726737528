<template>
  <div class="space-y-4">
    <div v-for="(count, i) of rows" :key="`placeholder-${i}`" class="loader rounded-sm bg-gray-10" />
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Number,
      default: () => 1,
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes pulse {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0.6;
  }
}

.loader {
  width: 100%;
  height: 3rem;
  animation: pulse 1.5s infinite;
}
</style>

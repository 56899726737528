<template>
  <div>
    <pdl-loading page :is-loading="loading" />
    <warranty-claim-line-product-details
      :claim-line="claimLine"
      :claim-index="claimIndex"
      @issue-types-update="issueTypesUpdate"
    />

    <hr class="rule my-4" />

    <warranty-claim-line-issue-details
      :claim-line="claimLine"
      :claim-index="claimIndex"
      :issue-details-enabled="issueDetailsEnabled"
      :warranty-issues="issueTypes"
      :display-warranty-shipment-options="displayWarrantyShipmentOptions"
    />

    <hr class="rule my-4" />

    <warranty-claim-line-attachments :claim-index="claimIndex" :claim-line="claimLine" />

    <div v-if="!isActionRequiredStatus" class="buttons">
      <trek-button secondary qaid="warranty-claim-line-save" @click="saveClaim">
        <span>{{ $t('text.button.save') }}</span>
      </trek-button>
      <trek-button tertiary qaid="warranty-claim-delete" @click="openDeleteDialog">
        <span>{{ $t('warrantyClaims.B2B.deleteClaimLine') }}</span>
      </trek-button>
    </div>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import {mapActions, mapGetters} from 'vuex';
import TrekButton from '@/components/TrekButton';
import {PdlLoading} from '@pedal/pdl-loading';
import WarrantyClaimLineAttachments from '@/components/containers/warranty-claim/WarrantyClaimLineAttachments';
import WarrantyClaimLineProductDetails from '@/components/containers/warranty-claim/WarrantyClaimLineProductDetails';
import WarrantyClaimLineIssueDetails from '@/components/containers/warranty-claim/WarrantyClaimLineIssueDetails';

export default {
  components: {
    PdlLoading,
    TrekButton,
    WarrantyClaimLineAttachments,
    WarrantyClaimLineProductDetails,
    WarrantyClaimLineIssueDetails,
  },
  props: {
    claimData: {
      type: Object,
      required: true,
    },
    claimIndex: {
      type: Number,
      required: true,
      default: 0,
    },
    claimLine: {
      type: Object,
      required: true,
    },
    displayWarrantyShipmentOptions: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      issueDetailsEnabled: false,
      issueTypes: [],
      loading: false,
    };
  },

  computed: {
    ...mapGetters('warrantyClaims', ['isActionRequiredStatus']),
  },

  mounted() {
    this.initializeIssueTypes();
  },

  methods: {
    ...mapActions('warrantyClaims', ['saveClaim']),

    issueTypesUpdate(warrantyIssues) {
      this.issueTypes = warrantyIssues || [];
      this.issueDetailsEnabled = true;
    },

    openDeleteDialog() {
      this.$emit('open-delete-dialog', this.claimLine.id);
    },

    initializeIssueTypes() {
      // Retrieve the warranty issue types depending on product type selected and if the serial or sku exist
      if (this.claimLine.productType === 'Bikes' && this.claimLine.serialNumber)
        this.retrieveIssueTypes('serial', this.claimLine.serialNumber);
      else if (this.claimLine.productType === 'PartsAndAccessories' && this.claimLine.sku)
        this.retrieveIssueTypes('sku', this.claimLine.sku);

      if (this.claimLine && this.claimLine.issueTypeNameSelected && this.claimLine.issueTypeNameSelected.length)
        this.issueDetailsEnabled = true;
    },

    retrieveIssueTypes(type, serialOrSku) {
      this.loading = true;
      storefrontInstance
        .get(`/claims/${type}/${serialOrSku}/`, {toast: false})
        .then((response) => {
          if (Object.keys(response.data.data).length !== 0) this.issueTypesUpdate(response.data.data.warrantyIssues);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<template>
  <li
    :id="`cart-item-${entry.entryNumber}`"
    class="py-3 border-gray-10 border-t"
    :class="{'cart-item-container grid gap-4 lg:grid-cols-2': isCartPage}"
  >
    <pdl-media-object :qaid="`cart-item-${entry.product.code}`">
      <a slot="media" qaid="cart-product-image" :href="entry.product.url">
        <image-component
          :alt-text="thumbnailAsset.altText"
          :title="thumbnailAsset.altText"
          aspect-ratio="4x3"
          sizes="auto"
          :asset-id="productImage"
          img-format="pjpg"
          transformation="pad"
          :is-responsive="true"
        />
      </a>
      <template #content>
        <div class="space-y-3">
          <div qaid="cart-product-summary">
            <p qaid="cart-product-name" class="font-bold text-md">{{ entry.product.name }}</p>
            <pdl-label-group v-if="labelList.length" qaid="cart-product-labels">
              <pdl-label v-for="(label, i) of labelList" :key="`product-label-${i}`" :kind="label.kind">
                {{ label.content }}
              </pdl-label>
            </pdl-label-group>
            <ul qaid="cart-product-details" class="text-gray-60 my-1 text-sm">
              <li
                v-for="(category, i) of entry.product.variantMatrix"
                :key="`category-${i}`"
                :qaid="`category-${getCategoryQaid(category)}`"
              >
                {{ `${category.parentVariantCategory.name}: ${category.variantValueCategory.name}` }}
              </li>
              <p v-if="!isQuantityControlEnabled">{{ `${$t('order.quantity')}: ${entry.quantity}` }}</p>
            </ul>
            <cart-item-pricing :entry="entry" :is-promotion-applied="isPromotionApplied" />
          </div>

          <div v-if="pickupInStoreDeliveryMode">
            <pdl-icon
              v-tippy="retailerPricingTippy"
              name="info"
              size="18"
              class="text-black ml-25 pt-px cursor-pointer"
            />
            <span class="text-sm" qaid="retailer-price-tooltip-text">{{
              $t('checkout.multi.pickupInStore.retailerPricing')
            }}</span>
            <div id="retailer-price-tooltip" v-tippy-html qaid="retailer-price-tooltip-msg" class="text-left">
              {{ $t('checkout.multi.pickupInStore.retailerPricing.desc') }}
            </div>
          </div>

          <cart-quantity-controls
            v-if="isQuantityControlEnabled"
            :is-update-enabled="isUpdateEnabled"
            :initial-quantity="entry.quantity"
            :entry-number="entry.entryNumber"
            :product-code="entry.product.code"
            :item-data="itemData"
          />
        </div>
      </template>
    </pdl-media-object>
    <cart-fulfillment-options-container
      v-if="isCartPage"
      :fulfillment-options-data="fulfillmentOptionsData"
      :entry="entry"
      :shipping-speed="cartFulfillmentSpeed"
      @pick-up-in-store-selected="pickUpInStoreSelected"
    />
  </li>
</template>

<script>
import ImageComponent from '@/components/ImageComponent.vue';
import CartQuantityControls from '@/components/cart/b2c/CartQuantityControls.vue';
import CartItemPricing from '@/components/cart/b2c/CartItemPricing.vue';
import CartFulfillmentOptionsContainer from '@/components/cart/b2c/CartFulfillmentOptionsContainer.vue';
import {PdlLabel, PdlLabelGroup} from '@pedal/pdl-label';
import {PdlMediaObject} from '@pedal/pdl-media-object';
import {mapState} from 'vuex';
import {useFunctionalityToggle} from '@/composables/functionality-toggle.js';
import {FunctionalityToggle} from '@/constants/functionality-toggle.js';
import {PdlToastType} from '@/constants/pdl-toast-type.js';
import {PdlIcon} from '@pedal/pdl-icon';

const getVariantCategories = (product) => {
  return product.variantMatrix?.reduce((object, element, i) => {
    if (i === 0) {
      object.productColor = element.variantValueCategory?.name;
      return object;
    }
    object.productSize = element.variantValueCategory?.name;
    return object;
  }, {});
};

export default {
  components: {
    ImageComponent,
    PdlLabel,
    PdlMediaObject,
    PdlLabelGroup,
    CartQuantityControls,
    CartItemPricing,
    CartFulfillmentOptionsContainer,
    PdlIcon,
  },
  props: {
    entry: {
      type: Object,
      required: true,
    },
    isECommAvailable: {
      type: Boolean,
      default: false,
    },
    isQuantityControlEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {isToggleActive: isConsumerChoiceEnabled} = useFunctionalityToggle(FunctionalityToggle.CONSUMER_CHOICE);
    return {isConsumerChoiceEnabled};
  },
  data() {
    return {
      pickupInStoreDeliveryMode: false,
      retailerPricingTippy: {
        html: '#retailer-price-tooltip',
        theme: 'light',
        trigger: 'mouseenter',
        maxWidth: '30rem',
        reactive: true,
      },
    };
  },
  computed: {
    ...mapState('backend', ['currencyIso', 'azureCdnImagesUrl']),
    ...mapState('cart', ['cartFulfillmentOptions', 'cartFulfillmentSpeed']),

    fulfillmentOptionsData() {
      return this.cartFulfillmentOptions?.find((item) => item.orderEntryId === this.entry.entryNumber);
    },
    itemData() {
      return {
        productCode: this.entry.product.code,
        productPrice: this.entry.basePrice.value,
        productCategory: this.entry.product.defaultCategory,
        productName: this.entry.product.name,
        productBrand: this.entry.product.brandNameFull,
        productColor: getVariantCategories(this.entry.product).productColor,
        productSize: getVariantCategories(this.entry.product).productSize,
        isoCode: this.currencyIso,
      };
    },
    isUpdateEnabled() {
      return !this.entry.product.retailerOnly && this.isECommAvailable && this.entry.updateable;
    },
    thumbnailAsset() {
      return this.entry?.product?.images[0];
    },
    productImage() {
      const missingProductImage = `${this.azureCdnImagesUrl}/missing-product-300x300.jpg`;
      const url = this.thumbnailAsset?.url ?? '';
      return url.length > 0 ? url : missingProductImage;
    },
    labelList() {
      const convertLabelList = (labels) => {
        if (!labels) return;
        return labels.map(({kind, messageKey}) => ({kind, content: this.$t(messageKey)}));
      };

      const labelList = convertLabelList(this.entry.product?.labels) ?? [];
      if (this.isPromotionApplied) {
        labelList.push({kind: 'positive', content: this.$t('cart.page.promo.applied')});
      }
      return labelList;
    },
    isPromotionApplied() {
      return Boolean(this.entry.isLineLevelPromotionPresent);
    },
    isCartPage() {
      return window.location.href.includes('/cart');
    },
    isSummaryPage() {
      return window.location.href.includes('/summary');
    },
  },
  methods: {
    getCategoryQaid(category) {
      return category.parentVariantCategory.code ?? category.parentVariantCategory.name;
    },
    displayRetailerPriceToast() {
      this.$notify({
        type: PdlToastType.INFO,
        message: this.$t('checkout.multi.pickupInStore.retailerPricing.applied'),
        position: 'top-right',
        duration: 3000,
      });
    },
    pickUpInStoreSelected(value) {
      this.pickupInStoreDeliveryMode = value;
      if (value) {
        this.displayRetailerPriceToast();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-item-container {
  &:last-of-type {
    border-bottom-width: 1px;
  }
}

::v-deep .pdl-media-object__media {
  width: 10rem;
}
</style>

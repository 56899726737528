<template>
  <section
    v-if="productReferences.length"
    :id="uid"
    class="product-set grid-container mt-6 mb-6"
    :data-ga-carousel-title="title"
  >
    <h3 class="product-set__header header--lg mb-1">{{ title }}</h3>
    <div id="product-reference-carousel" class="owl-carousel" data-owl-slider="three-up">
      <div v-for="product in productReferences" :key="product.sku" data-slide>
        <product-card-item
          :product="product"
          :is-carousel="true"
          :is-compare-button-enabled="isCompareButtonEnabled"
          :is-product-references="true"
        />
      </div>
    </div>
  </section>
</template>
<script>
import ProductCardItem from '@/components/containers/plp/ProductCardItem.vue';
import storefrontInstance from '@/api/instances/storefront';
import slider from '@/modules/slider';
import {mapState} from 'vuex';
import {trackEvent} from '@/utils/tracking.js';

export default {
  name: 'ProductReferencesContainer',
  components: {
    ProductCardItem,
  },
  props: {
    uid: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    productCode: {
      type: String,
      required: true,
    },
    isCompareButtonEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      productReferences: [],
    };
  },
  computed: {
    ...mapState('backend', ['currencyIso']),
  },
  mounted() {
    this.getProductReferences();
  },
  methods: {
    async getProductReferences() {
      this.productReferences = await storefrontInstance
        .get(`/v1/api/product/${this.productCode}/product-reference?referenceTypeEnums=CROSSELLING,ACCESSORIES`)
        .then(({data}) => data.data);
      this.$nextTick(() => {
        window.sliderRefs?.push(slider($('#product-reference-carousel')));
      });
      this.sendImpressions();
    },
    sendImpressions() {
      const impressions = {
        ecommerce: {
          currency: this.currencyIso,
          impressions: this.productReferences.map((product, index) => {
            const productData = {
              id: product.code,
              name: product.name,
              price: product.prices.consumerPrice.price.low.formatted,
              brand: product.brandNameFull,
              category: product.defaultCategory,
              list: this.title,
              position: index,
            };
            if (product.categorizationHierarchyValues.length) {
              productData.variant = product.categorizationHierarchyValues[0].options?.displayName;
            }

            return productData;
          }),
        },
      };
      trackEvent(impressions);
    },
  },
};
</script>

<template>
  <div id="bike-garage-app">
    <router-view></router-view>
  </div>
</template>

<script>
import BikeGarageApi from '@/bikeGarage/api/bike-garage-api.js';
import {mapMutations, mapState} from 'vuex';
import {PdlToastType} from '@/constants/pdl-toast-type';

export default {
  computed: {
    ...mapState('bikeGarage', ['bikes']),
    hasBikes() {
      return this.bikes.length > 0;
    },
  },
  created() {
    this.fetchBikeCategories();
    if (!this.hasBikes) {
      this.fetchBikes();
    }
  },
  methods: {
    ...mapMutations('bikeGarage', ['setBikeCategories', 'setBikes', 'setIsLoading']),
    async fetchBikes() {
      this.setIsLoading(true);
      try {
        const response = await BikeGarageApi.getBikes();
        this.setBikes(response);
      } catch (e) {
        this.$notify({
          type: PdlToastType.ERROR,
          message: this.$t(e.cause?.data?.errors[0]?.message),
        });
      } finally {
        this.setIsLoading(false);
      }
    },
    async fetchBikeCategories() {
      try {
        const response = await BikeGarageApi.getBikeCategories();
        this.setBikeCategories(response);
      } catch (e) {
        this.$notify({
          type: PdlToastType.ERROR,
          message: this.$t(e.cause?.data?.errors[0]?.message),
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.button--primary) {
  @include breakpoint(medium) {
    flex-grow: 2.5;
  }
}

:deep(.button--secondary) {
  @include breakpoint(medium) {
    flex-grow: 1;
  }
}
</style>

<template>
  <div class="grid-x grid-margin-x">
    <div class="cell large-2">
      <label class="form-label" qaid="contact-us-form-topic">{{ $t('productArchive.selectYear') }}</label>
      <div class="select">
        <select id="model-year-select" v-model="modelYear" class="form-control" @change="getProducts">
          <option v-for="year in years" :key="year.id" :value="year.modelYear">
            {{ year.modelYear }}
          </option>
        </select>
      </div>
    </div>

    <div class="cell large-10">
      <div class="text-base my-4 min-h-xs lg:mt-0">
        <pdl-loading top :is-loading="loading">
          <div>
            <p class="mb-2">
              <span class="font-medium">{{ products.length }} {{ $t('text.results') }}</span>
            </p>
            <ul class="archive-list mb-2 text-base" :class="colClass">
              <li v-for="product in products" :key="product.id" class="archive-list__item block">
                <a
                  :href="getProductUrl(product.productUrl)"
                  class="block underline py-25"
                  v-html="product.displayName"
                ></a>
              </li>
            </ul>
          </div>
        </pdl-loading>
      </div>
    </div>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import {mapState} from 'vuex';
import orderBy from 'lodash/orderBy';
import {PdlLoading} from '@pedal/pdl-loading';

export default {
  components: {
    PdlLoading,
  },
  props: {},
  data() {
    return {
      loading: true,
      modelYear: '',
      products: [],
      years: [],
      contextPath: ACC.config.encodedContextPath || '',
    };
  },

  computed: {
    ...mapState('backend', ['encodedContextPath']),

    colClass() {
      if (this.products.length === 1) {
        return '';
      }
      if (this.products.length === 2) {
        return 'lg:col-count-2';
      }
      return 'lg:col-count-3 md:col-count-2';
    },

    filterItems() {
      return this.years.map((year) => {
        return {
          id: year.id,
          text: year.modelYear,
          active: year.isSelected,
        };
      });
    },
  },

  mounted() {
    this.getProducts();
  },

  methods: {
    getProducts() {
      this.loading = true;
      storefrontInstance
        .get('/product/archived', {params: {modelYear: this.modelYear, type: 'Bikes'}})
        .then(({data}) => {
          if (data && data.data && data.data.results.length) {
            data.data.results.forEach((a) => (a.displayName = a.displayName === undefined ? '' : a.displayName));
            this.products = data.data.results.sort((a, b) => a.displayName.localeCompare(b.displayName));
            this.years = orderBy(data.data.years, 'modelYear', 'desc');
            // set modelYear only on initial page load
            this.modelYear =
              !this.modelYear && this.years && this.years.length > 0 ? this.years[0].modelYear : this.modelYear;
          } else {
            this.products = [];
          }
        })
        .finally(() => (this.loading = false));
    },

    getProductUrl(productUrl) {
      return this.contextPath + productUrl;
    },
  },
};
</script>

<template>
  <div class="grid-container pb-5">
    <pdl-section-header size="xl" :is-dividing="true" class="mt-3">
      <template slot="content">
        <pdl-heading :level="1" qaid="parstechtext-header">{{
          $t('techProdSupport.B2B.partsAccessories')
        }}</pdl-heading>
      </template>
    </pdl-section-header>

    <div class="grid-x grid-margin-x mb-3">
      <div class="cell medium-6 large-4">
        <pdl-section-header size="md">
          <template slot="content">
            <pdl-heading :level="2" qaid="tech-select-product-header">{{ $t('text.selectAProduct') }}</pdl-heading>
          </template>
        </pdl-section-header>

        <fieldset qaid="techtext-fieldset">
          <!-- Category Dropdown -->
          <div class="form-group">
            <label class="form-label">
              {{ $t('text.Category') }}
            </label>
            <div class="select">
              <select
                id="select-category"
                v-model="selectedCategory"
                qaid="parts-tech-category-dropdown"
                :disabled="!categoryData.length"
                class="form-control"
                @change="retrieveBrandOptions"
              >
                <option :value="null" :disabled="true">{{ $t('form.select.empty') }}</option>
                <option v-for="(category, index) in categoryData" :key="index" :value="category.code">
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>

          <!-- Brand Dropdown -->
          <div v-show="brandData.length" class="form-group">
            <label class="form-label">
              {{ $t('text.brand') }}
            </label>
            <div class="select">
              <select
                id="select-brand"
                v-model="selectedBrand"
                qaid="parts-tech-brand-dropdown"
                class="form-control"
                @change="retrieveModelOptions"
              >
                <option :value="null" :disabled="true">{{ $t('form.select.empty') }}</option>
                <option v-for="(brand, index) in brandData" :key="index" :value="brand">{{ brand }}</option>
              </select>
            </div>
          </div>

          <!-- Model dropdown (tippy) -->
          <div v-show="modelData[0] && modelData[0].id != '-1'" class="form-group">
            <label class="form-label">
              {{ $t('garage.bike.type') }}
            </label>
            <tech-model-tippy-container
              :options-list="modelData"
              :please-select-model="$t('form.select.empty')"
              :popup-close-tooltip="$t('popup.close')"
              @selection-made="retrieveMatrixOptions"
            ></tech-model-tippy-container>
          </div>

          <!-- this component will render a dropdown for each category from the matrix call -->
          <tech-info-dropdown
            v-for="(item, name, index) in matrixData"
            :key="name"
            :current-index="index"
            :dropdown-data="item"
            @matrix-option-selected="matrixOptionSelected"
          >
          </tech-info-dropdown>

          <div class="buttons my-4">
            <trek-button
              primary
              :disabled="readyToSubmit === false"
              qaid="tech-view-product-btn"
              @click="setSkuVuexAndRelocate"
            >
              {{ $t('text.viewProduct') }}
            </trek-button>
          </div>
        </fieldset>
      </div>

      <div class="cell medium-6 large-8 hide-for-small-only" qaid="tech-static-image">
        <img :src="staticImageUrl" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import {mapMutations, mapState} from 'vuex';
import TrekButton from '@/components/TrekButton';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {TrekButton, PdlSectionHeader, PdlHeading},
  props: {
    staticImageUrl: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loading: true,
      categoryData: [],
      brandData: [],
      modelData: [],
      matrixData: {},
      selectedCategory: null,
      selectedBrand: null,
      selectedModel: null,
      readyToSubmit: false,
      selectedSku: '0',
    };
  },

  computed: {
    ...mapState('backend', ['encodedContextPath']),
  },

  created() {
    this.retrieveCategoryOptions();
    this.resetModelData();
    this.resetBrandData();
  },

  methods: {
    resetBrandData() {
      this.brandData = [];
      this.selectedBrand = null;
    },

    resetModelData() {
      this.modelData = [{name: this.$t('form.select.empty'), id: '-1'}];
      this.selectedModel = null;
    },

    resetMatrixData() {
      this.matrixData = [];
    },

    setFormReadyStatus(val) {
      this.readyToSubmit = val;
    },

    retrieveCategoryOptions() {
      this.loading = true;
      this.resetBrandData();
      this.resetModelData();
      this.resetMatrixData();
      storefrontInstance
        .get('/technical-specification/selector/categories/')
        .then(({data}) => {
          this.categoryData = data.data;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },

    retrieveBrandOptions() {
      this.loading = true;
      this.resetBrandData();
      this.resetModelData();
      this.resetMatrixData();
      this.setFormReadyStatus(false);
      const encodedSelectedCategory = encodeURIComponent(this.selectedCategory);
      storefrontInstance
        .get(`/technical-specification/selector/brands/${encodedSelectedCategory}`)
        .then(({data}) => {
          this.brandData = data.data;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },

    retrieveModelOptions() {
      this.loading = true;
      this.resetModelData();
      this.resetMatrixData();
      this.setFormReadyStatus(false);
      const encodedSelectedCategory = encodeURIComponent(this.selectedCategory);
      const encodedSelectedBrand = encodeURIComponent(this.selectedBrand);
      storefrontInstance
        .get(`/technical-specification/selector/models/${encodedSelectedCategory}/${encodedSelectedBrand}`)
        .then(({data}) => {
          this.modelData = data.data;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },

    populateMatrix(matrix, matrixData, populateValues) {
      matrix.forEach((i) => {
        if (matrixData[i.parentVariantCategory.name]) {
          matrixData[i.parentVariantCategory.name].values = [{name: this.$t('form.select.empty'), id: '-1'}];
        }
      });

      matrix.forEach((i) => {
        if (matrixData[i.parentVariantCategory.name] == null) {
          matrixData[i.parentVariantCategory.name] = {
            name: i.parentVariantCategory.name,
            code: i.code,
            values: [{name: this.$t('form.select.empty'), id: '-1'}],
          };
        }

        if (populateValues) {
          matrixData[i.parentVariantCategory.name].values.push({
            name: i.variantValueCategory.name,
            id: i.variantOption.code,
            matrix: i,
          });
        }

        if (i.elements) {
          this.populateMatrix(i.elements, matrixData, false);
        }
      });
    },

    retrieveMatrixOptions(selected) {
      this.loading = true;
      this.selectedModel = selected.id;
      this.resetMatrixData();
      this.setFormReadyStatus(false);

      storefrontInstance
        .get(`/technical-specification/selector/matrix/raw/${this.selectedModel}`)
        .then(({data}) => {
          let matrixData = {};
          this.populateMatrix(data.data, matrixData, true);
          this.matrixData = matrixData;
          if (Object.keys(matrixData).length === 0) {
            this.setSkuAndEnableSubmit(this.selectedModel);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },

    matrixOptionSelected(selected) {
      if (selected.matrix.isLeaf) {
        this.setSkuAndEnableSubmit(selected.matrix.variantOption.code);
      } else {
        this.populateMatrix(selected.matrix.elements, this.matrixData, true);
        this.setFormReadyStatus(false);
      }
    },

    setSkuAndEnableSubmit(selectedSku) {
      this.setFormReadyStatus(true);
      this.selectedSku = selectedSku;
    },

    setSkuVuexAndRelocate() {
      this.setBikeTechSKU(this.selectedSku);
      window.location = `${this.encodedContextPath}/tech-details/${this.selectedSku}`;
    },

    ...mapMutations('storage', ['setBikeTechSKU']),
  },
};
</script>

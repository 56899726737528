<template>
  <section class="find-retailers">
    <div class="search-retailers">
      <label for="search" class="font-bold">{{ $t('projectOne.order.location') }}<span class="text-red">*</span></label>
      <p :class="['form-feedback is-invalid', {'is-visible': errorSearch}]">
        {{ $t('form.enterZip') }}
      </p>
      <div class="flex items-center mb-4">
        <mapbox-address-suggestions-input
          v-model="searchQuery"
          class="mr-1"
          :input-id="searchNameAttr"
          :name="searchNameAttr"
          :is-mapbox-enabled="true"
          @input-updated="updateSearch($event)"
        />
        <pdl-button qaid="find-retailers-search" :disabled="!isSearchEnabled" @click="updateRetailers(searchQuery)">{{
          $t('text.search')
        }}</pdl-button>
      </div>
    </div>

    <template v-if="isDealersLoading">
      <pdl-loading is-loading />
    </template>
    <list-component v-else-if="hasRetailers" :is-overflow="true">
      <p :class="['form-feedback is-invalid mb-2', {'is-visible': errorRadio}]">
        {{ $t('form.field.required') }}<span class="text-red">*</span>
      </p>
      <list-item-component v-for="(item, key) in processedRetailerData" :key="`retailer-item-${key}`">
        <template #pre-content>
          <label class="radio">
            <input
              v-model="selectedStore"
              type="radio"
              :name="radioNameAttr"
              :value="item.name"
              @change="updateStoreSelection(item.name)"
            />
            <span class="control-indicator"></span>
          </label>
        </template>
        <template #content>
          <p class="font-bold">
            {{ item.displayName }}
            <span class="font-normal italic text-small">
              ({{ item.formattedDistance }}{{ distanceFormats[item.currentLocaleDistanceUnit] }})
            </span>
          </p>
          <p>{{ item.address }}</p>
        </template>
        <template #high-light-text>
          <p v-for="(message, i) in item.userAdditionalMessages" :key="`${message}-${i}`">{{ $t(message) }}</p>
        </template>
      </list-item-component>
    </list-component>
    <pdl-callout v-else kind="error">
      <template #content>{{ $t('storeFinder.v2.noRestults') }}</template>
    </pdl-callout>
  </section>
</template>

<script>
import {mapMutations, mapState, mapActions} from 'vuex';
import {DistanceFormats} from '@/constants/dealer-list.js';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlButton} from '@pedal/pdl-button';
import ListItemComponent from '@/components/ListItemComponent.vue';
import ListComponent from '@/components/ListComponent.vue';
import {PdlCallout} from '@pedal/pdl-callout';
import MapboxAddressSuggestionsInput from '@/components/mapbox/components/MapboxAddressSuggestionsInput.vue';
import {useVuelidate} from '@vuelidate/core';
import {required, minLength} from '@vuelidate/validators';

export default {
  name: 'FindRetailers',
  components: {
    PdlButton,
    PdlCallout,
    PdlLoading,
    ListItemComponent,
    ListComponent,
    MapboxAddressSuggestionsInput,
  },

  setup() {
    return {v$: useVuelidate()};
  },

  validations() {
    return {
      searchQuery: {
        minLength: minLength(5),
      },
      selectedStore: {required, $lazy: true},
    };
  },

  data() {
    return {
      distanceFormats: DistanceFormats,
      searchQuery: '',
      searchNameAttr: 'retailerSearch',
      radioNameAttr: 'retailerRadio',
    };
  },

  computed: {
    ...mapState('backend', ['geolocation']),
    ...mapState('checkout', ['selectedStore', 'stores', 'isDealersLoading', 'isCheckoutDialogVisible']),

    hasRetailers() {
      return !!this.stores?.length;
    },
    errorSearch() {
      return this.v$.searchQuery.$invalid;
    },
    errorRadio() {
      return this.v$.selectedStore.$invalid;
    },
    isSearchEnabled() {
      return this.searchQuery.length > 4 && !this.errorSearch;
    },
    processedRetailerData() {
      return this.stores.map((store) => {
        return {
          ...store,
          address: this.formatAddress(store.address),
        };
      });
    },
  },

  watch: {
    isCheckoutDialogVisible: {
      handler(value) {
        if (!value) return;
        this.updateRetailers(this.searchQuery);
      },
    },
  },

  created() {
    this.searchQuery = this.geolocation.postalCode;
  },

  mounted() {
    this.updateRetailers(this.searchQuery);
  },

  methods: {
    ...mapActions('checkout', ['getDealers']),
    ...mapMutations('checkout', ['setIsDealersLoading', 'setSelectedStore', 'setDealerPostcode']),

    async updateRetailers(queryData) {
      this.setSelectedStore(null);
      this.setDealerPostcode(queryData);
      this.getDealers();
    },
    formatAddress(address) {
      const line1 = address?.line1 ?? '';
      const line2 = address?.line2 ?? '';
      const town = address?.town ?? '';
      const region = address?.region?.name ?? '';
      const postalCode = address?.postalCode ?? '';

      if (line2) {
        return `${line1}, ${line2}, ${town}, ${region} ${postalCode}`;
      }
      return `${line1}, ${town}, ${region} ${postalCode}`;
    },
    updateSearch({inputValue = ''}) {
      this.searchQuery = inputValue;
    },
    updateStoreSelection(selectedStore) {
      this.setSelectedStore(selectedStore);
      this.v$.selectedStore.$touch();
    },
  },
};
</script>

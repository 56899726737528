const PhoneMaskConfig = Object.freeze({
  us: '(###) ###-####',
  au: '## #### ####',
  ca: '#'.repeat(10),
  jp: '#'.repeat(11),
  nl: '#'.repeat(10),
  fallback: '#'.repeat(15),
});

const getPhoneMaskPattern = (locale) => {
  return PhoneMaskConfig[locale] ?? PhoneMaskConfig.fallback;
};

const getFormattedPhoneNumber = (number, locale) => {
  let i = 0;
  const unformatPhoneValue = ('' + number).replace(/\D/g, '');
  return getPhoneMaskPattern(locale).replace(/#/g, () => {
    if (unformatPhoneValue[i] === undefined) return '';
    return unformatPhoneValue[i++];
  });
};

export {getPhoneMaskPattern, getFormattedPhoneNumber};

<template>
  <div class="form-group">
    <label class="form-label">{{ dropdownData.name ? dropdownData.name : '' }}</label>
    <div class="select">
      <select
        v-model="selectedItem"
        :disabled="dropdownData.values.length <= 1"
        :qaid="`tech__variant-${currentIndex}-dropdown`"
        class="form-control"
      >
        <option v-for="(item, index) in dropdownData.values" :key="index" :disabled="index === 0" :value="item.id">
          {{ item.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import find from 'lodash/find';

export default {
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    dropdownData: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      selectedItem: '-1',
    };
  },

  watch: {
    selectedItem() {
      const selectedOption = find(this.dropdownData.values, (option) => option.id === this.selectedItem);
      this.$emit('matrix-option-selected', selectedOption);
    },
  },
};
</script>

<template>
  <div v-if="attributes">
    <div v-for="attribute in attributes" :key="attribute.code" class="form-group">
      <label class="form-label">{{ attribute.displayName }}</label>
      <div class="select" :qaid="`tech__variant-${attribute.code}-dropdown`">
        <select
          :id="`tech-info-${attribute.code}`"
          v-model="selectedAttributes[attribute.code]"
          class="form-control"
          required
        >
          <option v-for="(option, index) in attribute.options" :key="index" :value="option.value">
            {{ option.displayName }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  name: 'TechInfoVariant',
  props: {
    product: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      selectedAttributes: {},
    };
  },

  computed: {
    ...mapState('pdp', ['initAttributeSelection']),
    attributes() {
      return this.product?.variantAttributesDirectory ?? [];
    },
  },

  watch: {
    product: {
      handler() {
        if (Object.keys(this.selectedAttributes).length) {
          return this.selectVariant(this.selectedAttributes);
        }
        this.selectedAttributes = this.initAttributeSelection;
      },
      deep: true,
    },
    initAttributeSelection: {
      handler(selections) {
        this.selectedAttributes = {
          ...selections,
        };
      },
      deep: true,
    },

    selectedAttributes: {
      handler(selections) {
        this.selectVariant(selections);
      },
      deep: true,
    },
  },
  methods: {
    selectVariant(selections) {
      if (!this.product?.variants) return;

      const variant = this.product.variants.find((item) => {
        return Object.entries(selections).every(([key, value]) => {
          return item?.variantAttributes[key] === value;
        });
      });

      if (variant?.code) {
        this.$emit('sku-selected', variant.code);
      }
    },
  },
};
</script>

<template>
  <tr>
    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid.checkbox">
      <div class="b2b-grid__label"></div>
      <div class="b2b-grid__content">
        <label class="checkbox">
          <input :id="'sku-' + row.sku" v-model="rowChecked" type="checkbox" :disabled="disableInteractions" />
          <span class="control-indicator"></span>
        </label>
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid.note">
      <grid-note
        :data="{
          value: 'note_add',
          editable: true,
          content: row.lineNote,
          hasNotification: row.lineNote != null && row.lineNote.length,
        }"
        :item-id="row.sku"
        :max-length="120"
        @note-added="handleAddNote"
      />
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid.sku">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.sku') }}</div>
      <div class="b2b-grid__content flex">
        <grid-text :data="{value: row.sku, tooltip: getTooltipText(row, false)}"></grid-text>
        <div v-if="row.upc" qaid="sku-grid.upc" class="hidden lg:flex">
          <span class="text-gray-70">{{ row.upc }}</span>
        </div>
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid.description">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.description') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: row.itemName}"></grid-text>
      </div>
    </td>
    <td class="b2b-grid__cell lg:hidden" qaid="sku-grid.upc">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.upc') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: row.upc}"></grid-text>
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell" qaid="sku-grid.date-added">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.dateAdded') }}</div>
      <div class="b2b-grid__content">
        <grid-text :data="{value: row.dateAdded}"></grid-text>
      </div>
    </td>
    <!-- Variable product warehouses -->
    <template v-if="btoLeadTime">
      <td
        v-if="hasWarehouses(row.warehouses)"
        :colspan="row.warehouses.length"
        class="b2b-grid__cell lg:table-cell"
        qaid="sku-grid.warehouse"
      >
        <div class="b2b-grid__label">{{ row.warehouses && row.warehouses.length ? row.warehouses[0].id : '' }}</div>
        <div class="b2b-grid__content text-center" qaid="sku-grid.btoLeadTime" v-html="btoLeadTime"></div>
      </td>
    </template>
    <template v-else>
      <template v-for="(warehouse, warehouseKey) in getWarehouses(row)">
        <td
          v-if="hasWarehouses(row.warehouses)"
          :key="warehouseKey"
          class="b2b-grid__cell lg:table-cell"
          qaid="sku-grid.warehouse"
        >
          <div class="b2b-grid__label">{{ warehouse.id }}</div>
          <div class="b2b-grid__content">
            <grid-warehouse :warehouse="warehouse"></grid-warehouse>
          </div>
        </td>
      </template>
    </template>
    <td v-if="hasWarehouses(row.warehouses)" class="b2b-grid__cell lg:table-cell" qaid="sku-grid.stock-watch">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.stockWatch') }}</div>
      <div class="b2b-grid__content">
        <grid-stock-watch
          :warehouses="getWarehouseStockWatchOptions(row.stockWatch, [row])"
          :product-index="row.sku"
          @update-stock-watch-state="updateStockWatch"
        ></grid-stock-watch>
      </div>
    </td>
    <td v-if="!isConsumerFriendlyMode" class="b2b-grid__cell lg:table-cell" qaid="sku-grid.order-price">
      <div class="b2b-grid__label">{{ $t('order.price') }}</div>
      <div class="b2b-grid__content">
        <grid-price
          :data="{
            dealerCost: get(row, 'price[0].dealerCost', 0),
            discount: get(row, 'price[0].sale', 0),
          }"
        ></grid-price>
      </div>
    </td>
    <td class="b2b-grid__cell lg:table-cell mb-4 md:mb-0 w-16" qaid="sku-grid.qty">
      <div class="b2b-grid__label">{{ $t('text.account.savedLists.qty') }}</div>
      <div class="b2b-grid__content">
        <input
          v-if="minQtyError"
          v-tippy="{
            html: `#qty-tooltip-${rowIndex}`,
            theme: 'light',
            maxWidth: '20rem',
            placement: 'top',
          }"
          :value="row.orderedQty"
          :disabled="disableInteractions"
          :class="{'has-warning': minQtyError}"
          class="form-control"
          type="number"
          name="qty"
          max="9999"
          @change="onUpdateQty"
        />
        <input
          v-else
          :value="row.orderedQty"
          :disabled="disableInteractions"
          :class="{'has-warning': minQtyError}"
          class="form-control"
          type="number"
          name="qty"
          max="9999"
          @change="onUpdateQty"
        />
      </div>
    </td>
    <td v-if="!isConsumerFriendlyMode" class="b2b-grid__cell lg:table-cell" qaid="sku-grid.sku">
      <div class="b2b-grid__label">{{ $t('text.order.subtotal') }}</div>
      <div class="b2b-grid__content">
        <grid-price
          :data="{
            dealerCost: get(row, 'subtotalPrice[0].dealerCost', 0),
            discount: get(row, 'subtotalPrice[0].sale', 0),
          }"
        ></grid-price>
      </div>
    </td>

    <div v-show="false" :id="`qty-tooltip-${rowIndex}`" v-tippy-html class="hidden">
      <span v-html="minQtyUpdateErrorMessage || minQtyMoveErrorMsg"></span>
    </div>
  </tr>
</template>
<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import get from 'lodash/get';
import GridText from '@/components/grid/GridText';
import GridNote from '@/components/grid/GridNote';
import GridPrice from '@/components/grid/GridPrice';
import GridWarehouse from '@/components/grid/GridWarehouse';
import GridStockWatch from '@/components/grid/GridStockWatch';
import warehouseMixin from '@/mixins/warehouse';

export default {
  components: {GridText, GridNote, GridPrice, GridWarehouse, GridStockWatch},
  mixins: [warehouseMixin],
  props: {
    row: {
      type: Object,
      default: () => null,
    },
    disableInteractions: {
      type: Boolean,
      default: false,
    },
    entriesName: {
      type: String,
      default: '',
    },
    minQtyMoveError: {
      type: Boolean,
      default: false,
    },
    minQtyMoveErrorMsg: {
      type: String,
      default: '',
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    warehouseCodes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      qty: this.row.orderedQty,
      minQtyItemUpdateError: false,
      minQtyUpdateErrorMessage: '',
    };
  },

  computed: {
    ...mapState('backend', ['isConsumerFriendlyMode']),
    ...mapState('savedLists', ['selectedList']),

    btoLeadTime() {
      let btoLeadTime = this.$t('skuGrid.B2B.leadTime');

      if (this.row.btoLeadTime && btoLeadTime && btoLeadTime.length) {
        return btoLeadTime.replace('{0}', this.row.btoLeadTime);
      }

      return false;
    },

    minQtyError() {
      // minQty errors in this component may be rooted in an update attempt, or a move-to-cart attempt.
      // One comes from the parent as a prop, and the other one is managed here, therefore
      // we need to have separate boolean values for each (mutating props is not allowed).
      return this.minQtyItemUpdateError || this.minQtyMoveError;
    },

    rowChecked: {
      get() {
        return this.row.checked;
      },
      set(value) {
        this.setRowChecked({
          rowIndex: this.selectedList[this.entriesName].findIndex((item) => item.sku === this.row.sku),
          entriesType: this.entriesName,
          value,
        });
      },
    },
  },

  watch: {
    minQtyError(val) {
      if (val) {
        this.$emit('min-qty-error-status-emitter', true);
      } else {
        this.$emit('min-qty-error-status-emitter', false);
      }
    },
  },

  methods: {
    ...mapActions('savedLists', ['updateQty']),
    ...mapMutations('savedLists', ['setStockWatch', 'setRowChecked', 'setOrderedQty']),

    getTooltipText(product, onlyMFR) {
      const replacementProduct = product.replacementProduct ?? null;
      const mfr = product.mfr ?? null;
      const replacementProductText = this.$t('productArchive.viewCurrent');
      let toolTipData = {};
      if (mfr !== null) {
        toolTipData['MFR'] = mfr;
      }
      if (replacementProduct && !onlyMFR) {
        toolTipData['replacementProductText'] = replacementProductText;
        toolTipData['replacementProductUrl'] = replacementProduct.url;
      }
      return toolTipData;
    },

    get(row, path, defaultValue = '') {
      return get(row, path, defaultValue);
    },

    handleAddNote({id, content}) {
      this.$emit('send-list-update', {
        sku: id,
        qty: this.row.orderedQty,
        checked: this.row.checked,
        lineNote: content,
      });
    },

    onUpdateQty(event) {
      const qtyNum = parseInt(event.target.value, 10);
      this.setOrderedQty({rowIndex: this.rowIndex, entriesType: this.entriesName, value: event.target.value});
      if (qtyNum && qtyNum > 0) {
        this.updateQty({sku: this.row.sku, qty: qtyNum, checked: this.row.checked}).then((data) => {
          if (get(data, 'data.failedSKUs')) {
            this.minQtyItemUpdateError = true;
            this.minQtyUpdateErrorMessage = (get(data, 'data.failedSKUs') || []).map(
              (item) => item.message.basePropertyValue
            )[0];
          } else {
            this.minQtyItemUpdateError = false;
            this.minQtyUpdateErrorMessage = null;
          }
          this.$emit('refresh');
        });
      } else if (qtyNum === 0) {
        this.$parent.$emit('row:delete', this.row.sku);
        this.$emit('refresh');
      }
    },

    updateStockWatch({item}) {
      const entriesName = this.entriesName;
      this.setStockWatch({...item, entriesName});
    },

    getWarehouses(product) {
      return this.fillWarehousesByCodes(this.warehouseCodes, product.warehouses);
    },
  },
};
</script>

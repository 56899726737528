<template>
  <div
    id="modelTippyToggle"
    ref="tippy_modelTooltipId"
    v-tippy="{
      html: '#model-selector-popover',
      interactive: true,
      reactive: true,
      trigger: 'click',
      placement: 'bottom',
      flip: false,
      sticky: true,
    }"
    class="select mb-2"
    qaid="tech-model-dropdown"
    :disabled="!optionsList[0].id || optionsList[0].id === '-1'"
  >
    <!-- Dropdown toggle button -->
    <select class="form-control" style="pointer-events: none">
      <option>
        {{ displayName(optionSelected) }}
      </option>
    </select>
    <!-- Dropdown content -->
    <div v-cloak id="model-selector-popover" class="text-left p-2">
      <div>
        <pdl-section-header size="sm">
          <template slot="content">
            <pdl-heading>{{ pleaseSelectModel }}</pdl-heading>
          </template>
        </pdl-section-header>

        <div v-if="optionsList.length >= 8">
          <div class="form-group">
            <input id="option-search" v-model="search" type="text" class="form-control" />
          </div>
        </div>

        <div v-if="filteredList.length" class="b2b-scroll-list-container min-w-xs">
          <div class="b2b-scroll-list">
            <input
              id="option-selected-input"
              v-model="optionSelected.id"
              class="hide"
              type="text"
              name="option-selected-input"
            />

            <div
              v-for="option in filteredList"
              :key="option.id"
              class="b2b-scroll-list__item text-md"
              @click="selectOption(option)"
            >
              {{ displayName(option) }}
            </div>
          </div>
        </div>
      </div>

      <trek-button
        text
        icon-only
        icon="clear"
        class="tooltip__close"
        :aria-label="popupCloseTooltip"
        @click="closeTooltip"
      >
      </trek-button>
    </div>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';
import TrekButton from '@/components/TrekButton';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';

export default {
  components: {TrekButton, PdlSectionHeader, PdlHeading},
  props: {
    optionsList: {
      type: Array,
      default: () => [],
    },
    popupCloseTooltip: {
      type: String,
      default: 'Close',
    },
    pleaseSelectModel: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      search: '',
      optionSelected: {
        id: '',
        name: '',
      },
    };
  },

  computed: {
    filteredList() {
      if (this.optionsList && this.optionsList[0].id === '-1') {
        // id of -1 is used for the placeholder
        // its presence shows that the parent dropdown has been reselected
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.optionSelected = {name: this.$t('form.select.empty'), id: '-1'};
      }
      const result = this.optionsList.filter((option) => this.matchesSearch(option, this.search));
      return sortBy(result, [(option) => this.displayName(option).toLowerCase()]);
    },
  },

  methods: {
    displayName(option) {
      return option.name === null ? option.id : option.name;
    },

    matchesSearch(option, search) {
      return this.displayName(option).toLowerCase().includes(search.toLowerCase());
    },

    selectOption(option) {
      this.optionSelected = option;
      this.closeTooltip();
      this.$emit('selection-made', this.optionSelected);
    },

    closeTooltip() {
      let el = this.$refs['tippy_modelTooltipId'];

      if (el && el._tippy) {
        el._tippy.hide();
      }
    },
  },
};
</script>

<template>
  <div v-if="items.length > 0">
    <div class="w-full show-for-large mb-2 flex justify-between">
      <pdl-subheading size="sm" class="font-medium" qaid="selectedText">{{
        $t('filters.selected.label')
      }}</pdl-subheading>
      <pdl-button v-show="items.length > 0" button small text class="my-0" qaid="clearAllLink" @click="clearFilters">
        {{ $t('search.nav.clearSelections') }}
      </pdl-button>
    </div>
    <ul class="facet-group__list">
      <li v-for="(filter, i) in items" :key="`filter-${i}`" class="facet-group__list-item mb-25 is-active">
        <form class="flex justify-between label-selected">
          <label class="flex-grow pl-1">
            <em v-html="getLabelText(filter)"></em>
          </label>
          <button type="button" class="flex items-center pr-1 cursor-pointer" @click.prevent="clearFilter(filter, i)">
            <pdl-icon size="18" :qaid="`clearFilter${i}`" name="cancel" :label="$t('text.clearFilters')" />
          </button>
        </form>
      </li>
    </ul>
    <pdl-button secondary block class="hide-for-large mt-2" qaid="clearAllLink" @click="clearFilters">
      {{ $t('search.nav.clearSelections') }}
    </pdl-button>
  </div>
</template>
<script>
import get from 'lodash/get';
import {PdlButton} from '@pedal/pdl-button';
import {PdlSubheading} from '@pedal/pdl-section-header';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'SelectedFilters',
  components: {PdlButton, PdlSubheading, PdlIcon},
  props: {
    filters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler(value) {
        this.items = value;
      },
    },
  },
  mounted() {
    this.items = this.filters;
  },
  methods: {
    getLabelText(filter) {
      return get(filter, 'facetOptionsValueDataList[0].displayText', '');
    },
    clearFilters() {
      this.items.forEach((item) => {
        item.selected = true;
      });
      this.$emit('state-changed', this.items);
      this.items = [];
    },
    clearFilter(filter, index) {
      filter.selected = true;
      this.items.splice(index, 1);
      this.$emit('state-changed', filter);
    },
  },
};
</script>

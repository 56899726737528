<template>
  <pdl-collapse-item
    :name="productGroupIndex"
    :qaid="`bulkProductGrid${productGroupIndex}`"
    :is-enable-transition="false"
    @state-changed="stateChanged"
  >
    <template slot="title">
      <label v-if="group.meta.type === 'swatch'" class="flex flex-grow items-center" qaid="sku-grid.color">
        <pdl-swatch
          :name="groupDisplayColor"
          :first-color="getColorFromCode(groupDisplayColor, 0)"
          :second-color="getColorFromCode(groupDisplayColor, 1)"
          :is-small="true"
          class="mr-1"
        />
        <span class="cursor-pointer text-gray-70">{{ groupDisplayName }}</span>
      </label>
      <div v-else class="text-secondary">
        {{ groupDisplayName }}
      </div>
    </template>

    <div class="md:flex">
      <div class="b2b-card product-card md:w-24" :class="{'lg:hidden xxl:flex': isPlp}">
        <div class="b2b-card__img w-24 md:w-auto">
          <img :src="groupDisplayImage" class="w-20 h-auto lazyload" qaid="sku-grid.image" alt="" />
        </div>
        <div class="b2b-card__body p-0 pt-1">
          <p
            v-if="isBike && productGroup.modelYearCurrent"
            class="text-md text-gray-80 font-medium m-0"
            qaid="sku-grid.model-year"
          >
            {{ productGroup.modelYearCurrent }}
          </p>
          <p
            v-else-if="isBike && productGroup.marketingModelYear"
            class="text-md text-gray-80 font-medium m-0"
            qaid="sku-grid.model-year"
          >
            {{ productGroup.marketingModelYear }}
          </p>
          <p class="font-medium text-lg mb-0 mt-25 leading-tight" qaid="sku-grid.item-name">
            {{ productGroup.name }}
          </p>
          <!-- Variants -->
          <label
            v-if="group.meta.type === 'swatch'"
            class="flex flex-grow md:mb-1 mt-25 items-center md:block"
            qaid="sku-grid.color"
          >
            <pdl-swatch
              :name="groupDisplayColor"
              :first-color="getColorFromCode(groupDisplayColor, 0)"
              :second-color="getColorFromCode(groupDisplayColor, 1)"
              :is-small="true"
            />
            <span class="text-gray-70 leading-tight">{{ groupDisplayName }}</span>
          </label>

          <div v-else class="text-secondary">
            {{ groupDisplayName }}
          </div>
        </div>
      </div>

      <!-- Tabs (SKU and Apple Labels) -->
      <div class="md:flex-1 md:ml-2" :class="{'lg:ml-0 xxl:ml-2': isPlp}">
        <bulk-product-grid-tabs
          :hide-unavailable="hideUnavailable"
          :product-group="productGroup"
          :base-product="baseProduct"
          :product-group-index="productGroupIndex"
          :product-type="productType"
          :is-plp="isPlp"
          :is-pdp="isPdp"
          :has-sku-footer-button="hasSkuFooterButton"
          :has-link="hasLink"
          :column-config="columnConfig"
          :is-custom-product="isCustomProduct"
          :product-groups="productGroups"
          :products="products"
          @add-to-cart="$emit('add-to-cart')"
          @update-stock-watch-state="(payload) => $emit('update-stock-watch-state', payload)"
          @update-cart-or-list-column="emitUpdateColumns"
        />
      </div>
    </div>
  </pdl-collapse-item>
</template>

<script>
import get from 'lodash/get';
import BulkProductGridTabs from './BulkProductGridTabs';
import {mapMutations} from 'vuex';
import {PdlCollapseItem} from '@pedal/pdl-collapse';
import {PdlSwatch} from '@pedal/pdl-swatch';
import swatchMixin from '@/mixins/swatches.js';
import {ProductImages} from '@/components/containers/pdp/utils/product-images';

export default {
  components: {BulkProductGridTabs, PdlCollapseItem, PdlSwatch},
  mixins: [swatchMixin],
  props: {
    hideUnavailable: Boolean,
    productGroup: {
      type: Object,
      default: () => null,
    },
    productGroupIndex: {
      type: Number,
      default: 0,
    },
    baseProduct: {
      type: String,
      default: null,
    },
    imageSize: {
      type: Object,
      default: () => {
        return {width: 160, height: 120};
      },
    },
    productType: {
      type: String,
      validator: function (value) {
        // 'am' stands for 'after market' and represents parts/accessories
        return ['bike', 'am'].indexOf(value) !== -1;
      },
      default: null,
    },
    isPdp: {
      type: Boolean,
      default: false,
    },

    isPlp: {
      type: Boolean,
      default: false,
    },
    isCustomProduct: {
      type: Boolean,
      default: false,
    },
    hasSkuFooterButton: {
      type: Boolean,
      default: true,
    },
    hasLink: {
      type: Boolean,
      default: true,
    },
    columnConfig: {
      type: String,
      default: 'ALL',
    },
    productGroups: {
      type: Array,
      default: () => [],
    },
    products: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      collapseStates: {},
    };
  },

  computed: {
    group() {
      return this.productGroup.group;
    },

    isBike() {
      return this.productType === 'bike';
    },

    groupDisplayImage: function () {
      let productImage = '';

      if (this.group.image === '' || this.group.image === null || this.group.image === undefined) {
        productImage = 'default-no-image';
      } else {
        productImage = this.group.image;
      }

      return ProductImages.getAssetUrl(productImage, {
        w: this.imageSize.width,
        h: this.imageSize.height,
        c: 'pad',
      });
    },

    groupDisplayColor() {
      return get(this.group, 'value.options.colorSwatchName');
    },

    groupDisplayName() {
      return get(this.group, 'value.options.displayName');
    },
  },

  methods: {
    stateChanged(value) {
      let skuList = this.productGroup.products.map((obj) => obj.sku);

      for (const sku of skuList) {
        this.collapseStates[sku] = value;
      }
      this.updateCollapseState(this.collapseStates);
    },

    emitUpdateColumns(resData) {
      this.$emit('update-cart-or-list-column', resData);
    },

    ...mapMutations('skuGrid', ['updateCollapseState']),
  },
};
</script>

<style scoped>
.product-card {
  min-width: 6rem;
}
</style>

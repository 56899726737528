<template>
  <div class="border-b border-gray-10 pb-2">
    <div class="grid-container">
      <pdl-section-header size="lg" class="rcp-details-header">
        <template slot="content">
          <pdl-heading :level="1" qaid="rcp-details-name" class="break-all">{{ detailsPageData.name }}</pdl-heading>
          <pdl-subheading qaid="rcp-details-location">{{ detailsPageAddress }}</pdl-subheading>
        </template>
        <template slot="actions">
          <div class="buttons buttons--right-for-md">
            <template v-if="showEditButton">
              <pdl-button v-if="isRideDetails" small primary qaid="rcp-details-edit-link" @click="openModifyRideDialog">
                {{ $t('text.edit') }}
              </pdl-button>
              <pdl-link v-else button small primary qaid="rcp-details-edit-link" :href="editRoutePath">
                {{ $t('text.edit') }}
              </pdl-link>
            </template>
            <pdl-button
              v-if="!isRideDetails"
              small
              :primary="!isSecondaryButton"
              :secondary="isSecondaryButton"
              @click="openDuplicateRouteDialog"
            >
              {{ $t('text.saveACopy') }}
            </pdl-button>
            <pdl-button
              v-if="showEditButton"
              small
              tertiary
              qaid="rcp-details-delete-button"
              @click="openDeleteRouteDialog"
            >
              {{ $t('text.delete') }}
            </pdl-button>
          </div>
        </template>
      </pdl-section-header>
      <div class="mt-4 flex gap-4 sm:flex-col" :class="publisherNameClasses">
        <div v-if="detailsPageData.publisherName" qaid="rcp-details-creator-name" class="flex-1">
          {{ detailsPageData.publisherName }}
        </div>
        <div>
          <dl qaid="rcp-details-desc-list" class="grid sm:gap-2 lg:gap-16" :class="editableClasses">
            <div>
              <dt qaid="rcp-details-distance-heading" class="text-gray-60 text-xs">{{ $t('rcp.route.distance') }}</dt>
              <dd qaid="rcp-details-distance">{{ formattedDistance }} {{ userRouteUnits.distanceUnit }}</dd>
            </div>
            <div>
              <dt qaid="rcp-details-elevation-heading" class="text-gray-60 text-xs">{{ $t('rcp.route.elevation') }}</dt>
              <dd qaid="rcp-details-elevation-gain">{{ formattedElevationGain }} {{ userRouteUnits.elevationUnit }}</dd>
            </div>
            <div v-if="isRideDetails">
              <dt qaid="rcp-details-category-heading" class="text-gray-60 text-xs">
                {{ $t('rcp.routeBuilder.cueSheet.hrMin') }}
              </dt>
              <dd qaid="rcp-details-category">
                {{ formattedRideTime }}
              </dd>
            </div>
            <div v-if="detailsPageData.editable">
              <dt qaid="rcp-details-visibility-heading" class="text-gray-60 text-xs">
                {{ $t('rcp.route.visibility') }}
              </dt>
              <dd qaid="rcp-details-visibility">
                <template v-if="detailsPageData.publicRide || detailsPageData.publicRoute">
                  {{ $t('rcp.routeBuilder.route.visibility.public') }}
                </template>
                <template v-else>{{ $t('rcp.routeBuilder.route.visibility.private') }}</template>
              </dd>
            </div>
            <div v-if="!isRideDetails">
              <dt qaid="rcp-details-category" class="text-gray-60 text-xs">
                {{ $t('text.Category') }}
              </dt>
              <dd qaid="rcp-details-category">
                <template v-if="detailsPageData.category === 'UNPAVED'">{{
                  $t('rcp.routeBuilder.route.category.unpaved')
                }}</template>
                <template v-else>{{ $t('rcp.routeBuilder.route.category.paved') }}</template>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {PdlSectionHeader, PdlHeading, PdlSubheading} from '@pedal/pdl-section-header';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLink} from '@pedal/pdl-link';
import {mapGetters, mapMutations, mapState} from 'vuex';
import {
  convertMetersToDistanceUnit,
  convertMetersToElevationUnit,
  convertSecondsToHourMinutes,
} from '@/utils/unit-converter';

export default {
  components: {PdlSectionHeader, PdlHeading, PdlSubheading, PdlButton, PdlLink},
  props: {
    isEditable: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapState('backend', ['preferredUnit']),
    ...mapGetters('viewSize', ['viewBreakpointMoreThan']),
    ...mapGetters('rcpBuilder', ['userRouteUnits']),
    ...mapState('backend', ['encodedContextPath', 'isUserLoggedIn']),
    ...mapState('rcpRoutes', ['detailsPageData']),

    detailsPageAddress() {
      const {city, region, country} = this.detailsPageData;
      const displayAddress = [city, region, country].filter(Boolean);
      return displayAddress.join(', ');
    },
    formattedDistance() {
      return this.detailsPageData?.rideMetrics?.totalDistance
        ? convertMetersToDistanceUnit(this.detailsPageData?.rideMetrics?.totalDistance, this.preferredUnit).toFixed(1)
        : convertMetersToDistanceUnit(this.detailsPageData?.distance, this.preferredUnit).toFixed(1);
    },
    formattedElevationGain() {
      const totalElevationGain = this.detailsPageData?.rideMetrics?.totalElevationGain
        ? this.detailsPageData?.rideMetrics?.totalElevationGain
        : this.detailsPageData?.elevationData?.totalElevationGain;

      return totalElevationGain ? Math.round(convertMetersToElevationUnit(totalElevationGain, this.preferredUnit)) : 0;
    },
    b2bUnits() {
      return this.detailsPageData?.b2bUnits?.join(', ');
    },
    editRoutePath() {
      return `${this.encodedContextPath}/ride-club/route/builder/#/${this.detailsPageData.id}/`;
    },
    formattedRideTime() {
      const rideTime = this.detailsPageData?.rideMetrics?.rideTime;
      return convertSecondsToHourMinutes(rideTime);
    },
    showEditButton() {
      const commonEditCondition = this.detailsPageData.editable && this.isEditable;
      return this.isRideDetails ? commonEditCondition : this.viewBreakpointMoreThan('medium') && commonEditCondition;
    },
    editableClasses() {
      if (this.isEditable && !this.isRideDetails) {
        return this.detailsPageData.editable ? 'grid-cols-4' : 'grid-cols-3';
      }
      return 'grid-cols-4';
    },
    publisherNameClasses() {
      return !this.detailsPageData.publisherName ? 'lg:flex-row-reverse' : 'lg:flex-row';
    },
    isRideDetails() {
      return this.detailsPageData?.rideMetrics?.rideTime;
    },
    isSecondaryButton() {
      return this.isUserLoggedIn && this.showEditButton;
    },
  },
  methods: {
    ...mapMutations('rcpBuilder', ['setDeleteRouteDialogVisibility', 'setSaveMapDialogVisibility']),
    ...mapMutations('rcpRoutes', ['setModifyRideDailogVisibility']),
    openModifyRideDialog() {
      this.setModifyRideDailogVisibility(true);
    },
    openDeleteRouteDialog() {
      this.setDeleteRouteDialogVisibility(true);
    },
    openDuplicateRouteDialog() {
      if (!this.isUserLoggedIn) {
        window.location.href = '/login/';
      } else {
        this.setSaveMapDialogVisibility(true);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.rcp-details-header {
  &.pdl-section-header {
    @include breakpoint(small down) {
      display: flex;
      align-items: flex-start;
      flex-direction: column-reverse;
    }

    &:not(.pdl-section-header--non-stacking) > :last-child:not(:only-child) {
      @include breakpoint(small down) {
        margin-bottom: 1.5rem;
        margin-top: 0;
      }
    }
  }
}
</style>

<template>
  <div class="pdp-product-image-panel">
    <pdp-product-image-zoom
      v-if="isZoomActive"
      :images="items"
      :current-image-index="selectedItemIndex"
      :product-type="productType"
      @close="closeZoom"
    />
    <div id="heroCarousel" :class="{'has-accessories-strip': hasAccessoriesStrip}">
      <swiper-container ref="slider" init="false" @swiperslidechange="onSlideChange">
        <swiper-slide v-for="(item, i) in items" :key="`${item.data.assetId}-${i}`" lazy="true">
          <template v-if="item.type !== 'video'">
            <img
              :srcset="getSrcSet(item.data.assetId, {c: 'pad'})"
              :src="getAssetUrl(item.data.assetId, {w: 800, h: 600, c: 'pad'})"
              :alt="getAltText(item.data.assetId)"
              class="swiper-lazy"
              loading="lazy"
              @click="slideClicked"
            />
            <div v-if="i > 0" class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
            <div v-if="item.data.isAccessorized" class="accessories-disclaimer">
              <span v-if="productType === 'bike'">
                {{ $t('product.detail.image.accessorized.caption') }}
              </span>
              <span v-else>{{ $t('product.detail.image.accessorizedAftermarket.caption') }}</span>
            </div>
          </template>
        </swiper-slide>
        <div v-if="items.length === 0">
          <img
            src="https://media.trekbikes.com/image/upload/w_800,h_600,f_auto,q_auto/default-no-image"
            :alt="$t('skuGrid.B2B.noImage')"
          />
        </div>
      </swiper-container>
    </div>

    <div v-if="items.length > 1" id="heroCarousel--thumbnails">
      <div
        v-for="(item, index) in items"
        :key="`${item.data.assetId}-${index}`"
        class="small-2 thumbnail-item"
        :class="{synched: selectedItemIndex === index}"
        @click.prevent="switchTo(index)"
      >
        <div v-if="item.type === 'image'" class="img-wrapper">
          <div class="img-wrapper-inner">
            <img :src="getThumbnailUrl(item.data.assetId, {c: 'pad'})" :alt="getAltText(item.data.assetId)" />
          </div>
        </div>
        <div v-else-if="item.type === 'video'" class="vid-wrapper">
          <a
            :id="`video-${item.data.id}`"
            :href="item.data.url"
            class="pdp-thumbnails__video-link trek_video play-icon-after gallery-vid"
            :data-video="item.data.id"
            :data-ga-action="item.data.caption"
            data-ga-event="video-engagement"
            data-ga-category="Video Engagement"
            data-ga-label="Play"
          >
            <img
              :alt="getAltText(item.data.assetId)"
              :src="getThumbnailUrl(item.data.assetId || item.data.stillAssetId, {c: 'pad'})"
            />
            <pdl-icon size="48" name="play_circle_outline" class="text-white" :label="$t('text.watch.video')" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import get from 'lodash/get';
import {ProductImages} from '@/components/containers/pdp/utils/product-images';
import PdpProductImageZoom from '@/components/containers/pdp/PdpProductImageZoom';
import {PdlIcon} from '@pedal/pdl-icon';
import {register} from 'swiper/element/bundle';
import {PdpPanelSwiperStyles, PdpImageCommonSwiperStyles} from '@/constants/pdp-panel-and-zoom.js';

// register Swiper custom elements
register();

export default {
  name: 'PdpProductImagePanel',
  components: {
    PdpProductImageZoom,
    PdlIcon,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    maxThumbnails: {
      type: Number,
      default: 12,
    },
    color: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedItemIndex: 0,
      isZoomActive: false,
    };
  },
  computed: {
    hasAccessoriesStrip() {
      if (!this.items) {
        return false;
      }
      return this.items.filter((item) => item.data.isAccessorized).length > 0;
    },
    selectedVariantCode() {
      return this.$store.state.savedLists.buyingZoneEntries.length
        ? this.$store.state.savedLists.buyingZoneEntries[0].sku
        : undefined;
    },
    productType() {
      return this.product.productType;
    },
    productName() {
      return this.product?.name;
    },
    items() {
      if (!this.product.images || !Object.keys(this.product.images).length) {
        return [];
      }

      const result = [];
      const images = this.getImagesForCurrentVariant();
      const videos = get(this.product, `videos`);

      if (images && images.length > 0) {
        images.sort((a, b) => {
          return a.weight > b.weight ? 1 : a.weight < b.weight ? -1 : 0;
        });
      }

      const push = (item, type) => {
        if (result.length >= this.maxThumbnails) {
          return false;
        }

        result.push({type, data: item});
        return true;
      };

      if (images && images.length > 0) {
        for (let item of images) {
          if (!push(item, 'image')) {
            break;
          }
        }
      }

      if (videos && videos.length > 0 && result.length < this.maxThumbnails) {
        for (let item of videos) {
          if (!push(item, 'video')) {
            break;
          }
        }
      }

      return result;
    },
    swiper() {
      return this.$refs.slider.swiper;
    },
  },
  watch: {
    color(value, oldValue) {
      if (value !== oldValue) {
        this.switchTo(0);
      }
    },

    selectedVariantCode(value, oldValue) {
      if (value !== oldValue) {
        this.switchTo(0);
      }
    },

    isZoomActive(isActive) {
      if (isActive) {
        this.stopListeningKeyboard();
      } else {
        this.startListeningKeyboard();
      }
    },
  },
  mounted() {
    this.startListeningKeyboard();
    this.initialiseSwiper();
  },
  beforeDestroy() {
    this.stopListeningKeyboard();
  },
  methods: {
    getImagesForCurrentVariant() {
      const color = this.color || Object.keys(this.product.images)[0];

      let images = this.selectedVariantCode
        ? this.product.images[color]?.filter((item) => item.variantList.includes(this.selectedVariantCode))
        : this.product.images[color];

      if (!images?.length) {
        if (!this.color) {
          Object.keys(this.product.images).forEach((groupKey) => {
            const group = this.product.images[groupKey];
            group.forEach((item) => {
              if (item.variantList.includes(this.selectedVariantCode)) {
                images.push(item);
              }
            });
          });
        } else {
          images = this.product.images[color];
        }
      }
      return this.filterVariantImages(images);
    },
    filterVariantImages(images) {
      const variantImages = [];

      if (!images || !images.length) {
        return images;
      }

      if (this.product.variants && this.product.variants.length > 0) {
        this.product.variants.forEach((variant) => {
          if (this.product.isArchived === variant.isArchived) {
            variantImages.push(variant.code);
          }
        });
      }

      return images.filter(
        (image) => image.variantList && image.variantList.some((item) => variantImages.includes(item))
      );
    },
    slideClicked() {
      this.isZoomActive = true;
    },
    closeZoom() {
      this.isZoomActive = false;
    },
    startListeningKeyboard() {
      this.stopListeningKeyboard();
      document.addEventListener('keydown', this.keyPressed);
    },
    stopListeningKeyboard() {
      document.removeEventListener('keydown', this.keyPressed);
    },
    keyPressed(ev) {
      if (ev.keyCode === 37) {
        this.goToPreviousItem();
      } else if (ev.keyCode === 39) {
        this.goToNextItem();
      }
    },
    getThumbnailUrl: (assetId, options = {}) => ProductImages.getThumbnailUrl(assetId, options),
    getSrcSet: (assetId, options = {}) => ProductImages.getSrcSet(assetId, options),
    getAssetUrl: (assetId, options = {}) => ProductImages.getAssetUrl(assetId, options),
    getAltText(assetId) {
      const altTextTranslation = ProductImages.getAltTextTranslation(assetId);

      if (this.$te(`altText.${altTextTranslation}`)) {
        return this.$t(`altText.${altTextTranslation}`);
      }

      return this.productName ? this.productName : '';
    },
    triggerVideoItem() {
      this.$nextTick(() => {
        if (this.items[this.selectedItemIndex].type === 'video') {
          const videoEl = this.$el.querySelector('.synched .pdp-thumbnails__video-link');
          if (videoEl) {
            const clickEvent = new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            });

            videoEl.dispatchEvent(clickEvent);
          }
        }
      });
    },
    goToPreviousItem() {
      let newItemIndex = this.selectedItemIndex - 1;
      if (newItemIndex < 0) {
        newItemIndex = this.items.length - 1;
      }

      this.switchTo(newItemIndex);
      this.triggerVideoItem();
    },
    goToNextItem() {
      this.switchTo(this.selectedItemIndex + 1);
      this.triggerVideoItem();
    },
    switchTo(itemIndex) {
      if (itemIndex < 0 || itemIndex >= this.items.length) {
        itemIndex = 0;
      }

      this.selectedItemIndex = itemIndex;
      this.swiper.slideTo(itemIndex);
    },
    onSlideChange() {
      this.selectedItemIndex = this.swiper.activeIndex;
    },
    initialiseSwiper() {
      const swiperEl = document.querySelector('swiper-container');
      if (!swiperEl) return;
      const params = {
        pagination: {
          clickable: true,
        },
        navigation: true,
        injectStyles: [...PdpImageCommonSwiperStyles, ...PdpPanelSwiperStyles],
      };

      Object.assign(swiperEl, params);

      swiperEl.initialize();
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"pagination__wrapper",attrs:{"qaid":"pagination"}},[_c('ul',{staticClass:"pagination__controls flex",class:_vm.containerClass},[_c('li',{class:[_vm.itemClasses, {disabled: _vm.pageNumber === 0}]},[_c('button',{staticClass:"button--icon-only",class:[_vm.buttonClasses, {disabled: _vm.pageNumber === 0}],attrs:{"qaid":"pagination-previous-button","disabled":_vm.pageNumber === 0,"type":"button"},on:{"click":function($event){return _vm.pageChange(_vm.pageNumber - 1)}}},[_c('pdl-icon',{attrs:{"name":_vm.prevIcon}}),_vm._v(" "),_c('span',{staticClass:"show-for-sr"},[_vm._v("Previous Page")]),_vm._v(" "),_vm._t("previous")],2)]),_vm._v(" "),(_vm.hasFirst && _vm.displayPages)?_c('li',{staticClass:"pagination__first",class:[_vm.itemClasses]},[_c('a',{staticClass:"page-link",class:[_vm.buttonClasses, {active: _vm.isActive(0)}],on:{"click":function($event){return _vm.pageChange(0)}}},[_vm._v("1")])]):_vm._e(),_vm._v(" "),(_vm.hasFirstEllipsis)?_c('li',{staticClass:"pagination__item--ellipsis",class:[_vm.itemClasses]},[_c('span',{staticClass:"pagination__ellipsis"},[_vm._v("…")])]):_vm._e(),_vm._v(" "),(_vm.displayPages)?_vm._l((_vm.pages),function(page){return _c('li',{key:page,class:[_vm.itemClasses, {'hidden sm:flex': _vm.isPlusMinusOne(page)}]},[_c('a',{staticClass:"pagination__link",class:[
            _vm.buttonClasses,
            {
              active: _vm.isActive(page),
              disabled: page === '...',
            },
          ],on:{"click":function($event){return _vm.pageChange(page - 1)}}},[_vm._v(_vm._s(page))])])}):_vm._e(),_vm._v(" "),(_vm.hasLastEllipsis && _vm.displayPages)?_c('li',{staticClass:"pagination__item--ellipsis",class:[_vm.itemClasses]},[_c('span',{staticClass:"pagination__ellipsis"},[_vm._v("…")])]):_vm._e(),_vm._v(" "),(_vm.hasLast && _vm.displayPages)?_c('li',{staticClass:"pagination__last",class:[_vm.itemClasses]},[_c('a',{staticClass:"page-link",class:[_vm.buttonClasses, {active: _vm.isActive(_vm.pageCount)}],on:{"click":function($event){return _vm.pageChange(_vm.pageCount - 1)}}},[_vm._v(_vm._s(_vm.pageCount))])]):_vm._e(),_vm._v(" "),_c('li',{class:[_vm.itemClasses]},[_c('button',{staticClass:"button--icon-only",class:[_vm.buttonClasses, {disabled: _vm.pageNumber === _vm.pageCount - 1}],attrs:{"qaid":"pagination-next-button","disabled":_vm.pageNumber === _vm.pageCount - 1,"type":"button"},on:{"click":function($event){return _vm.pageChange(_vm.pageNumber + 1)}}},[_vm._t("next"),_vm._v(" "),_c('pdl-icon',{attrs:{"name":_vm.nextIcon}}),_vm._v(" "),_c('span',{staticClass:"show-for-sr"},[_vm._v("Next Page")])],2)])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-if="store" class="locator__quick-view flex flex-col w-full" qaid="locator-quickview">
    <div class="b2b-scroll-list flex-auto m-0">
      <div class="b2b-card b2b-card--padded border-none mb-0">
        <div class="b2b-card__img relative">
          <pdl-button
            text
            inverted
            icon="arrow_back"
            qaid="locator-quickview__close"
            class="z-10 absolute mt-3 ml-3"
            @click="closeQuickView"
          >
            <span v-html="$t('storeFinder.v2.backResults')"></span>
          </pdl-button>
          <div class="locator-quickview-img">
            <img :src="imgUrl" alt="" @error="usePlaceholder" />
          </div>
        </div>

        <div class="b2b-card__body border-solid border-b border-gray-10">
          <div class="b2b-card__title flex justify-between mb-2">
            <h3 class="header--md mb-0" qaid="locator-quickview__store-name" v-html="store.name"></h3>
            <span
              class="flex-none text-sm mt-25 ml-2"
              qaid="locator-quickview__store-distance"
              v-html="store.distance"
            ></span>
          </div>

          <div v-if="store.badges.bestSelection || store.badges.sale" class="mb-1" qaid="locator-quickview__badges">
            <pdl-label-group>
              <pdl-label v-if="store.badges.bestSelection" kind="positive">
                <span v-html="$t('storelocator.bestSelection')" />
              </pdl-label>
              <pdl-label v-if="store.badges.sale" kind="prominent">
                <span v-html="$t('text.buyingZone.sale')" />
              </pdl-label>
            </pdl-label-group>
          </div>

          <pdl-button
            v-if="isUserLoggedIn"
            text
            small
            :icon="store.isMyStore ? 'favorite' : 'favorite_border'"
            qaid="locator-quickview__preferred-button"
            class="font-medium"
            @click.prevent="setPreferred"
          >
            <span v-html="store.isMyStore ? $t('storelocator.myStore') : $t('storeFinder.v2.setMyStore')"></span>
          </pdl-button>
        </div>

        <div class="b2b-card__body border-solid border-b border-gray-10">
          <div
            class="flex items-start mb-2"
            qaid="locator-quickview__schedule"
            @click="toggleFullList = !toggleFullList"
          >
            <div class="flex items-center">
              <pdl-icon class="mr-1" name="access_time" />
              <span class="text-sm font-bold mr-1" v-html="storeStatus(store.statusText)"></span>
            </div>
            <span class="text-sm text-right mt-25">
              <span v-for="(range, index) in store.statusHours" :key="index" class="block">{{
                hoursRange(range)
              }}</span>
            </span>
            <span v-cloak class="b2b-buying-zone__price-info" qaid="buying-zone.price-info">
              <i class="md-24 text-gray-80">{{ toggleFullList ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</i>
            </span>
          </div>

          <div id="schedule-full-list" :class="{hidden: !toggleFullList}" class="mb-2">
            <dl class="store-hours-list ml-4">
              <template v-for="(ranges, key, index) in store.hours">
                <dt :key="`${key}-day`">
                  <span :class="{'font-bold': currentDay == index}" v-html="dayAndColon(key)"></span>
                </dt>
                <dd :key="`${key}-range-${index}`" class="text-right" :class="{'font-bold': currentDay == index}">
                  <span v-for="(range, rangeIndex) in ranges" :key="rangeIndex" class="block">
                    {{ hoursRange(range) }}
                  </span>
                </dd>
              </template>
            </dl>
          </div>

          <template v-if="store.addressLine1 || store.addressLine2">
            <a :href="directionsUrl" target="_blank" qaid="locator-quickview__address">
              <div class="mb-2">
                <div class="flex justify-between">
                  <div class="flex">
                    <pdl-icon class="mr-1" name="directions" />
                    <div class="flex flex-col text-sm leading-normal">
                      <span v-html="store.addressLine1"></span>
                      <span v-if="store.addressLine2" v-html="store.addressLine2"></span>
                      <span v-html="`${store.city}, ${store.state}  ${store.postal}`"></span>
                      <span v-html="store.country"></span>
                    </div>
                  </div>
                  <span class="underline hover:no-underline">{{ $t('storeFinder.product.getdirection') }}</span>
                </div>
              </div>
            </a>
          </template>

          <template v-if="store.phone">
            <a :href="`tel: ${store.phone}`" qaid="locator-quickview__phone">
              <div class="flex justify-between mb-2">
                <div class="flex items-center">
                  <pdl-icon class="mr-1" name="phone" />
                  <span class="text-sm" v-html="store.phone"></span>
                </div>
                <div>
                  <span class="underline hover:no-underline">{{ $t('storeFinder.v2.call') }}</span>
                </div>
              </div>
            </a>
          </template>

          <template v-if="store.website">
            <a :href="storeUrl" target="_blank" qaid="locator-quickview__url">
              <div class="flex justify-between">
                <div class="flex items-center overflow-hidden">
                  <pdl-icon class="mr-1" name="language" />
                  <span class="text-sm text-truncate" v-html="store.website"></span>
                </div>
                <div>
                  <span class="underline hover:no-underline whitespace-nowrap">{{ $t('storeFinder.v2.visit') }} </span>
                </div>
              </div>
            </a>
          </template>
        </div>

        <div class="b2b-card__body">
          <div
            v-if="store.brandsAvailable && store.brandsAvailable.length > 0"
            :class="{'mb-3': store.storeServiceList && store.storeServiceList.length > 0}"
            qaid="locator-quickview__brands"
          >
            <p class="text-sm font-bold mb-1">{{ $t('storeFinder.v2.available') }}</p>
            <div class="b2b-badges">
              <tk-badge
                v-if="isTrekLogo"
                :is-dismissible="false"
                :has-logo="true"
                class="b2b-badge--secondary-light b2b-badge--toggle"
                :is-toggled="false"
              >
                <template #logo>
                  <div v-html="trekLogo"></div>
                </template>
              </tk-badge>

              <tk-badge
                v-if="isElectraLogo"
                :is-dismissible="false"
                :has-logo="true"
                class="b2b-badge--secondary-light b2b-badge--toggle"
                :is-toggled="false"
              >
                <template #logo>
                  <div v-html="electraLogo"></div>
                </template>
              </tk-badge>
            </div>
          </div>

          <div v-if="store.storeServiceList && store.storeServiceList.length > 0" qaid="locator-quickview__services">
            <p class="text-sm font-bold mb-1">{{ $t('storeFinder.v2.storeServices') }}</p>
            <ul>
              <li v-for="(serviceTitle, index) in store.storeServiceList" :key="index" v-html="serviceTitle"></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div>
      <pdl-link
        button
        primary
        block
        icon-right
        icon="arrow_forward"
        qaid="locator-quickview__view-store"
        @click.prevent="$emit('go-to-store-page', store.id)"
      >
        <span v-html="$t('storeFinder.table.view.store')" />
      </pdl-link>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import storefrontInstance from '@/api/instances/storefront';
import {trekLogo, electraLogo} from '@/components/locator/assets/logos';
import {Constants} from '@/components/locator/mixins/constants';
import {PdlLink} from '@pedal/pdl-link';
import TkBadge from '@/components/TkBadge';
import {PdlLabel, PdlLabelGroup} from '@pedal/pdl-label';
import {PdlIcon} from '@pedal/pdl-icon';
import {PdlButton} from '@pedal/pdl-button';

export default {
  name: 'LocatorQuickView',

  components: {PdlButton, PdlLink, TkBadge, PdlLabel, PdlLabelGroup, PdlIcon},
  props: {
    store: {
      type: Object,
      default: () => null,
    },
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      trekLogo,
      electraLogo,
      currentDay: new Date().getDay(),
      toggleFullList: false,
      contextPath: this.encodedContextPath || '',
    };
  },

  computed: {
    ...mapState('backend', ['encodedContextPath', 'isUserLoggedIn']),
    storeUrl() {
      return this.store.website.startsWith('http') ? this.store.website : `http://${this.store.website.toString()}`;
    },
    assetId() {
      let assetId = this.store.imageURL.split('/');
      return assetId[assetId.length - 1];
    },
    imgUrl() {
      return this.store.imageURL
        ? `//media.trekbikes.com/image/upload/w_375,h_135,c_fill/${this.assetId}`
        : this.usePlaceholder();
    },
    directionsUrl() {
      return `https://maps.google.com/?q=${this.store.address} ${this.store.country}`;
    },
    isTrekLogo() {
      const trekLogos = ['TREK', 'TREKELECTRA', 'TREKELECTRADIAMANT', 'TREKDIAMANT'];
      return this.store.brandsAvailable.some((store) => trekLogos.includes(store.toUpperCase()));
    },
    isElectraLogo() {
      const electraLogos = ['ELECTRA', 'TREKELECTRA', 'TREKELECTRADIAMANT', 'ELECTRADIAMANT'];
      return this.store.brandsAvailable.some((store) => electraLogos.includes(store.toUpperCase()));
    },
  },

  methods: {
    usePlaceholder(e) {
      if (e && e.target) {
        e.target.src = Constants.storePlaceholder(375, 135);
      } else {
        return Constants.storePlaceholder(375, 135);
      }
    },

    dayAndColon(key) {
      return this.$t(`storeDetails.openingSchedule.weekday.${key}`) + ':';
    },

    storeStatus(status) {
      if (!status) return null;

      if (status.toLowerCase() == 'closed') {
        return this.$t('storeDetails.table.opening.closed');
      } else {
        return this.$t(`storeDetails.openingSchedule.${status}`);
      }
    },

    hoursRange(range) {
      if (range.toLowerCase() == 'closed') {
        return this.$t('storeDetails.table.opening.closed');
      } else {
        return range;
      }
    },

    closeQuickView() {
      this.$emit('close-quick-view');
    },

    setPreferred() {
      if (!this.store.isMyStore) {
        storefrontInstance
          .post(`/store-finder/save-preferred-retailer/json/?storeName=${this.store.id}`)
          .then((data) => {
            if (data && data.status == 200) {
              this.$emit('set-my-store');
              this.$emit('reload-data');
            }
          })
          .catch((error) => console.error(error));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.store-hours-list {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.25rem 1rem;
  width: fit-content;

  dt {
    margin-bottom: 0;
    font-weight: 400;
  }
}

.locator__quick-view {
  z-index: 100000;
}

.locator__quick-view .b2b-card__body {
  padding: 1rem;
}
</style>

<template>
  <div>
    <pdl-section-header size="sm">
      <template slot="content">
        <pdl-heading :level="4" qaid="product-feedback-details__sub-header">
          {{ $t('warrantyClaims.B2B.feedback.details') }}
        </pdl-heading>
      </template>
    </pdl-section-header>
    <form-group
      v-model="selectedFeedbackCategory"
      type="select"
      name="productCategory"
      :multi-items="categoryList"
      :label="$t('text.Category')"
      class="max-w-xs"
    />

    <form-group
      v-model="feedbackNote"
      show-error-messages
      force-display-error
      class="max-w-lg"
      type="textArea"
      name="feedbackNote"
      qaid="product-feedback-details__notes"
      :help-text="`${$t('text.charactersRemaining')} ${noteCharactersRemaining}`"
      :label="$t('text.feedback')"
      :maxlength="maxNoteCharacters"
      :rows="15"
      :value="feedbackData.notes"
      :error="getErrorMessage('feedbackNote')"
    />

    <div class="grid-x grid-margin-x mb-5">
      <div
        v-for="(n, index) in totalAttachments"
        :id="`attachment-${index}-${getAttachmentByIndex(index).name}`"
        :key="`${index}-${getAttachmentByIndex(index).name}`"
        class="cell large-4"
      >
        <label class="form-label" :qaid="`product-feedback__optional-attachments-image-${index}-label`">
          {{ $t('warrantyClaims.B2B.feedback.photos.supporting') }}
        </label>
        <pdl-file-upload
          :click-to-upload-msg="$t('bulkOrder.upload.cta')"
          :help="null"
          :has-error="showAttachmentImageWarning && index === 0"
          :is-filename-visible="false"
          :in-preset-image-file-url="getAttachmentByIndex(index)"
          :input-id="`${claimIndex}_feedbackOptionalAttachments_${index}`"
          :qaid="`product-feedback-image-${index + 1}`"
          :upload-on-input="true"
          :upload-button="false"
          :file-too-large-msg="$t('myCompany.imageUpload.fileTooLarge')"
          :wrong-file-type-msg="$t('orderUpload.B2B.fileTypeError')"
          :show-upload-toast="false"
          @file-uploaded="setImage({index: index}, $event)"
          @clear-file="clearImage(index)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex';
import FormGroup from '@/components/FormGroup';
import get from 'lodash/get';
import includes from 'lodash/includes';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlFileUpload} from '@pedal/pdl-file-upload';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  components: {FormGroup, PdlSectionHeader, PdlHeading, PdlFileUpload},
  mixins: [TrekValidationMixin],
  props: {
    minAttachments: {
      type: Number,
      default: 3,
    },
    claimIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      maxNoteCharacters: 2000,
      totalAttachments: 3,
    };
  },

  validations() {
    const validations = {
      feedbackNote: {
        maxLength: this.trekValidators.maxLength('text.feedback', this.maxNoteCharacters),
      },
    };
    return validations;
  },

  computed: {
    claimLine() {
      return this.feedbackData.claimLines[0];
    },
    showAttachmentImageWarning() {
      return this.feedbackData.claimLines[0] ? includes(this.feedbackData.claimLines[0].waitingCodeList, 'I') : false;
    },
    categoryList() {
      //this will be replaced with backend api call returning exact similar hash structure
      const categoryList = this.feedbackData.feedbackCategories.map((category) => {
        return {value: category.code.toLowerCase(), displayName: category.name};
      });
      categoryList.push({value: null, displayName: this.$t('text.other')});
      return categoryList;
    },
    noteCharactersRemaining() {
      if (this.feedbackNote) {
        return this.maxNoteCharacters - this.feedbackNote.length;
      }
      return this.maxNoteCharacters;
    },
    selectedFeedbackCategory: {
      get() {
        return this.feedbackData.claimLines[0].selectedFeedbackCategory || null;
      },
      set(value) {
        this.updateFeedbackProperty({propName: 'selectedFeedbackCategory', value});
      },
    },
    feedbackNote: {
      get() {
        return this.feedbackData?.notes || '';
      },
      set(value) {
        this.updateUserData({propName: 'notes', value});
      },
    },
    ...mapState('productFeedback', ['feedbackData']),
  },

  mounted() {
    const currentAttachmentsCount = this.feedbackData.claimLines[0].warrantyOptionalAttachments?.length || 0;
    this.feedbackNote = this.feedbackData?.notes || '';
    this.totalAttachments = Math.max(currentAttachmentsCount, this.minAttachments);
  },

  methods: {
    ...mapMutations('productFeedback', ['updateFeedbackProperty', 'updateUserData', 'updateFlag']),

    getAttachmentByIndex(index) {
      const attachment = get(this.claimLine, `warrantyOptionalAttachments[${index}]`) || {};
      return attachment.url || attachment.encodedFile || '';
    },

    setImage({index = 0}, {name, encodedFile}) {
      const attachments = [...this.claimLine.warrantyOptionalAttachments];
      attachments[index] = {
        encodedFile,
        name,
        url: null,
      };
      this.updateFeedbackProperty({
        propName: 'warrantyOptionalAttachments',
        value: attachments,
      });
    },

    clearImage(index) {
      this.claimLine.warrantyOptionalAttachments[index] = {
        encodedImage: null,
        name: null,
        url: null,
      };

      this.$nextTick(() => {
        this.v$.$validate();
        this.reArrangeAttachmentCells();
      });
    },

    reArrangeAttachmentCells() {
      let result = [];
      for (let cell = 0; cell < this.totalAttachments; cell++) {
        const attachment = this.claimLine.warrantyOptionalAttachments[cell];
        if (!attachment || !Object.keys(attachment).length || !attachment.url) {
          continue;
        }
        result.push(attachment);
      }
      this.updateFeedbackProperty({propName: 'warrantyOptionalAttachments', value: result});
    },
  },
};
</script>

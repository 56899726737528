<template>
  <div>
    <ul v-if="!product.isArchived" class="quantityOptions">
      <li class="form__item">
        <div class="form-group">
          <label for="qty" class="form-label">{{ $t('basket.page.quantity') }}</label>
          <numerical-input
            id="qty"
            class="w-20"
            :initial-value="isPurchasable ? (minQuantity ? minQuantity : 1) : null"
            :min="0"
            :max="9999"
            :should-clear="true"
            :is-buying-zone="true"
            :disabled="!isPurchasable || !hasUserAccessToBuyingZone"
            @updated="quantityChanged"
          />
          <div v-if="maxQuantityText" qaid="max-quantity-help-text">
            <span class="form-help" v-text="maxQuantityText" />
          </div>
        </div>
      </li>
    </ul>
    <div class="buttons">
      <!-- Add to cart button -->
      <trek-button
        v-if="canAddToCart && hasUserAccessToBuyingZone"
        id="addToCartButton"
        primary
        block
        icon="shopping_cart"
        type="submit"
        :disabled="!cartToggle || !isPurchasable || (isDistributor && !hasAvailability) || isAddingToCart"
        @click="addToCart"
      >
        {{ addToCartButtonCaption }}
      </trek-button>

      <!-- Saved Lists -->
      <trek-button
        v-show="hasUserAccessToBuyingZone"
        id="sl-buying-zone-btn"
        ref="tippy_2"
        v-tippy="{
          html: '#saved-lists-buying-zone',
          interactive: true,
          reactive: true,
          trigger: 'click',
          flip: true,
          sticky: true,
        }"
        secondary
        block
        icon="list"
        :disabled="isDistributor && !hasAvailability"
        qaid="saved-lists-button.move-to-list.buying-zone"
      >
        <span>{{ $t('myCart.B2B.moveToList') }}</span>
      </trek-button>
      <saved-lists-panel
        id="saved-lists-buying-zone"
        :token="options.token"
        :text-max-chars="$t('text.buyingZone.B2B.max40Char')"
        :text-my-lists="$t('text.account.savedLists.myLists')"
        :text-other-lists="$t('text.account.savedLists.otherLists')"
        :text-undo="$t('text.undo')"
        qaid-panel="saved-lists-panel.buying-zone"
        qaid-title="saved-lists-panel.title.buying-zone"
        qaid-list="saved-lists-panel.list.buying-zone"
        tippy-id="6"
        :is-buying-zone="true"
        :show-min-qty-toasts="true"
        btn-id="sl-buying-zone-btn"
      />

      <!-- Compare Button -->
      <product-compare-button
        v-if="options.productCompareEnabledOnSite"
        tertiary
        block
        qaid="compare-product-button"
        :product="product"
      />
    </div>
    <pdp-add-to-cart-status :message="successMessage" :is-error="false" :options="options" />
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex';
import ProductCompareButton from '@/components/compare/ProductCompareButton';
import PdpAddToCartStatus from '@/components/containers/pdp/PdpAddToCartStatus';
import NumericalInput from '@/components/NumericalInput';
import SavedListsPanel from '@/components/saved-lists/SavedListsPanel';
import TrekButton from '@/components/TrekButton';

export default {
  name: 'PdpAddToCart',
  components: {PdpAddToCartStatus, ProductCompareButton, NumericalInput, SavedListsPanel, TrekButton},
  props: {
    product: {
      type: Object,
      required: true,
    },
    variantCode: {
      type: String,
      default: '',
      required: false,
    },
    variantMaxOrderQuantity: {
      type: String,
      default: '',
      required: false,
    },
    isPurchasable: {
      type: Boolean,
      default: false,
      required: false,
    },
    isAddingToCart: {
      type: Boolean,
      default: false,
      required: false,
    },
    addToCartButtonCaption: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      required: true,
    },
    hasAvailability: {
      type: Boolean,
      default: false,
      required: false,
    },
    successMessage: {
      type: String,
      default: '',
    },
    hasUserAccessToBuyingZone: {
      type: Boolean,
      default: true,
      required: false,
    },
    minQuantity: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      quantity: null,
    };
  },
  computed: {
    ...mapState('backend', ['isDistributor', 'isB2BPurchasable', 'cartToggle']),
    canAddToCart() {
      return this.isB2BPurchasable && !this.product.isArchived;
    },
    maxQuantityText() {
      return this.variantMaxOrderQuantity && `${this.$t('text.maxQty')} ${this.variantMaxOrderQuantity}`;
    },
  },
  methods: {
    ...mapMutations('savedLists', ['setBuyingZoneEntries']),
    ...mapMutations('productBundle', ['setBuyingZoneQuantity']),

    quantityChanged(quantity) {
      this.quantity = quantity;
      this.setBuyingZoneQuantity(quantity);
      this.setBuyingZoneEntries([{sku: this.variantCode, qty: quantity}]);
    },

    addToCart() {
      this.$emit('add-to-cart', this.quantity);
    },
  },
};
</script>

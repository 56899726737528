<template>
  <div>
    <!-- Product Details -->
    <section>
      <dl class="description-list grid-x grid-margin-x max-w-sm">
        <dt class="cell small-5" qaid="warranty-claim-line-type-label">{{ $t('text.productType') }}</dt>
        <dd v-if="isBike" class="cell small-7" qaid="warranty-claim-line-type">{{ $t('text.bikes') }}</dd>
        <dd v-else-if="isPart" class="cell small-7" qaid="warranty-claim-line-type">
          {{ $t('text.partsAccessories') }}
        </dd>
        <dd v-else class="cell small-7"></dd>
        <dt v-if="isBike" class="cell small-5" qaid="warranty-claim-line-serial-label">
          {{ $t('warrantyClaims.B2B.serialNumber') }}
        </dt>
        <dd v-if="isBike" class="cell small-7" qaid="warranty-claim-line-serial" v-html="claimLine.serialNumber" />
        <dt v-if="isBike" class="cell small-5" qaid="warranty-claim-line-serial-image-label">
          {{ $t('warrantyClaims.B2B.serialNumber.uploadImage') }}
        </dt>
        <dd v-if="isBike" class="cell small-7">
          <div
            v-if="claimLine.serialNumberImage && claimLine.serialNumberImage.url"
            class="file-upload-container has-aspect-ratio"
            style="--aspect-ratio: 1"
          >
            <span class="file-uploads file-uploads-html5">
              <img
                qaid="warranty-claim-serial-image"
                :src="claimLine.serialNumberImage ? claimLine.serialNumberImage.url : null"
                alt=""
              />
            </span>
          </div>
          <div class="md:flex flex-wrap mt-25 flex-shrink-0 items-center md:justify-between">
            <div
              class="flex-shrink-0 text-sm text-gray-60"
              qaid="warranty-claim-line-serial-image-name"
              v-html="imageFileName"
            />
          </div>
        </dd>
        <dt class="cell small-5" qaid="warranty-claim-line-qty-label">{{ $t('text.quantity') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-line-qty" v-html="claimLine.qty" />
        <dt class="cell small-5" qaid="warranty-claim-line-sku-label">{{ $t('product.variants.sku') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-line-sku" v-html="claimLine.sku" />
        <dt class="cell small-5" qaid="warranty-claim-line-model-label">{{ $t('garage.bike.type') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-line-model" v-html="claimLine.modelName" />
        <dt v-if="isBike" class="cell small-5" qaid="warranty-claim-line-year-label">{{ $t('contactUs.year') }}</dt>
        <dd v-if="isBike" class="cell small-7" qaid="warranty-claim-line-year" v-html="claimLine.modelYear" />
        <dt class="cell small-5" qaid="warranty-claim-line-color-label">{{ $t('product.variants.colour') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-line-color" v-html="claimLine.color" />
        <dt class="cell small-5" qaid="warranty-claim-line-size-label">{{ $t('product.variants.size') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-line-size" v-html="claimLine.size" />
        <dt class="cell small-5" qaid="warranty-claim-line-purchase-label">{{ $t('contactUS.purchaseDate') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-line-purchase" v-html="claimLine.purchaseDateDisplay" />
      </dl>
    </section>

    <hr class="rule my-4" />

    <!-- Issue Details -->
    <section>
      <pdl-section-header size="sm" class="mb-3">
        <template slot="content">
          <pdl-heading :level="4" qaid="warranty-claim-issue-details">{{
            $t('warrantyClaims.B2B.issue.details')
          }}</pdl-heading>
        </template>
      </pdl-section-header>
      <dl class="description-list grid-x grid-margin-x max-w-sm">
        <dt class="cell small-5" qaid="warranty-claim-issue-type-label">{{ $t('contactUs.issueType') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-issue-type" v-html="claimLine.issueTypeNameSelected" />
        <dt class="cell small-5" qaid="warranty-claim-issue-when-label">{{ $t('warrantyClaims.B2B.issue.when') }}</dt>
        <dd class="cell small-7" qaid="warranty-claim-issue-when" v-html="damagedOcurredDisplay" />
        <dt class="cell small-5" qaid="warranty-claim-action-label">
          {{ $t('warrantyClaims.B2B.issue.requestedAction') }}
        </dt>
        <dd class="cell small-7" qaid="warranty-claim-action" v-html="requestedActionDisplay" />
        <dt class="cell small-5" qaid="warranty-claim-shipment-label">
          {{ $t('warrantyClaims.B2B.shipmentOptions') }}
        </dt>
        <dd class="cell small-7" qaid="warranty-claim-shipment-option" v-html="selectedShipmentOption" />
      </dl>
    </section>

    <!-- Attachments -->
    <section v-if="hasAttachments" class="my-6">
      <dl class="description-list grid-x grid-margin-x max-w-sm">
        <dt class="cell small-5 heading text-lg" qaid="warranty-claim-attachments">{{ $t('text.attachments') }}</dt>
        <dd class="cell small-7">
          <div v-for="(attachment, attachmentIndex) in attachments" :key="attachmentIndex" class="mb-2">
            <div v-if="attachment.src" class="file-upload-container has-aspect-ratio" style="--aspect-ratio: 1">
              <span class="file-uploads file-uploads-html5">
                <template v-if="isImage(attachment.fileName)">
                  <img :src="attachment.src" :qaid="`warranty-claim-attachment-image-${attachmentIndex}`" alt="" />
                </template>
                <span v-else>
                  <pdl-icon
                    size="48"
                    :qaid="`warranty-claim-attachment-image-${attachmentIndex}`"
                    name="insert_drive_file"
                  />
                </span>
              </span>
            </div>
            <div class="md:flex flex-wrap mt-25 flex-shrink-0 items-center md:justify-between">
              <div
                class="flex-shrink-0 text-sm text-gray-60"
                :qaid="`warranty-claim-attachment-image-name-${attachmentIndex}`"
                v-html="attachment.fileName"
              />
            </div>
          </div>
        </dd>
      </dl>
    </section>
  </div>
</template>

<script>
import find from 'lodash/find';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  components: {PdlSectionHeader, PdlHeading, PdlIcon},
  props: {
    claimLine: {
      type: Object,
      required: true,
    },
    claimData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isBike() {
      return this.claimLine.productType === 'Bikes';
    },

    isPart() {
      return this.claimLine.productType === 'PartsAndAccessories';
    },

    imageFileName() {
      if (this.claimLine.serialNumberImage) {
        return this.claimLine.serialNumberImage.name;
      }
      return undefined;
    },

    damagedOcurredDisplay() {
      const method = find(
        this.claimData.damageOccurredMethods,
        (method) => method.code === this.claimLine.selectedDamagedOccurred
      );

      if (method) {
        return method.name;
      }

      return undefined;
    },

    requestedActionDisplay() {
      const method = find(
        this.claimData.requestedActionMethods,
        (method) => method.code === this.claimLine.selectedRequestedAction
      );

      if (method) {
        return method.name;
      }

      return undefined;
    },

    selectedShipmentOption() {
      if (this.claimLine.warrantyConsumerWaiting === null) {
        return '';
      } else if (this.claimLine.warrantyConsumerWaiting) {
        return this.$t('warrantyClaims.B2B.shipmentOptions.shipNow');
      } else {
        return this.$t('warrantyClaims.B2B.shipmentOptions.nextScheduled');
      }
    },

    hasAttachments() {
      return this.attachments?.length > 0;
    },

    attachments() {
      let attachments = [];

      if (this.claimLine.warrantyDamageImage?.url) {
        attachments.push({
          src: this.claimLine.warrantyDamageImage.url,
          fileName: this.getImageFileName(this.claimLine.warrantyDamageImage),
        });
      }

      if (this.claimLine.warrantyPurchaseProofImages?.length) {
        this.claimLine.warrantyPurchaseProofImages.forEach((attachment) => {
          attachments.push({
            src: attachment.url,
            fileName: this.getImageFileName(attachment),
          });
        });
      }

      if (this.claimLine.warrantyOptionalAttachments?.length) {
        this.claimLine.warrantyOptionalAttachments.forEach((attachment) => {
          attachments.push({
            src: attachment.url,
            fileName: this.getImageFileName(attachment),
          });
        });
      }

      return attachments;
    },
  },

  methods: {
    getImageFileName(imagePath) {
      if (imagePath) {
        return imagePath.name;
      }
    },

    isImage(fileName) {
      const imageExtensions = 'gif,jpg,jpeg,png';
      let extension = fileName ? fileName.split('.').pop() : null;
      if (extension) return imageExtensions.includes(extension);
    },
  },
};
</script>
